import { addDays, isSunday } from 'date-fns';
import { TFunction } from 'i18next';

import { config } from '../app/config';
import { ConversationTab } from '../types/conversationTab';
import {
  ClaimReason,
  ConversationCategory,
  MessageFilter,
  StoreRegion,
} from '../types/generated';
import { Claim } from '../types/order';

export const uniqueBy = <T = any>(arr: T[], fn: (...item: T[]) => boolean) =>
  arr.reduce<T[]>((acc, v) => {
    if (!acc.some((x: T) => fn(v, x))) acc.push(v);
    return acc;
  }, []);

export const getEstimatedDeliveryDates = (startDate: Date) => {
  let estimatedStartDate = startDate;
  if (isSunday(estimatedStartDate)) {
    estimatedStartDate = addDays(estimatedStartDate, 1);
  }
  let estimatedEndDate = addDays(estimatedStartDate, 3);
  if (isSunday(estimatedEndDate)) {
    estimatedEndDate = addDays(estimatedEndDate, 1);
  }
  return { startDate: estimatedStartDate, endDate: estimatedEndDate };
};

export const getRemainingTime = (startDate: Date, endDate: Date) => {
  const deltaDate = endDate.valueOf() - startDate.valueOf();

  const minutes = Math.max(0, Math.floor(deltaDate / (1000 * 60)));
  const seconds = Math.max(0, Math.floor((deltaDate / 1000) % 60));

  return (
    (minutes < 10 ? '0' : '') +
    minutes +
    ':' +
    (seconds < 10 ? '0' : '') +
    seconds
  );
};

let timeout2: NodeJS.Timeout | null = null;
export const debounce2 =
  (func: any, wait: number) =>
  (...args: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const next = () => func(...args);
    clearTimeout(timeout2 as NodeJS.Timeout);
    timeout2 = setTimeout(next, wait);
  };

export const getFilters = (
  conversationTab: ConversationTab,
  userId?: string
): MessageFilter => {
  let filters: MessageFilter = {};
  switch (conversationTab) {
    case ConversationTab.Pinned:
      filters = {
        filterIsPinned: true,
      };
      break;
    case ConversationTab.ClaimAuto:
      filters = {
        filterCategory: ConversationCategory.BotProcessing,
      };
      break;
    case ConversationTab.Read:
      filters = {
        filterIsRead: true,
      };
      break;
    case ConversationTab.Unassigned:
      filters = {
        filterStore: StoreRegion.Fr,
        filterIsAssigned: false,
        filterIsRead: false,
        filterIsPinned: false,
      };
      break;
    case ConversationTab.Usa:
      filters = {
        filterStore: StoreRegion.Us,
        filterIsRead: false,
      };
      break;
    case ConversationTab.AssignedToMe:
      filters = {
        filterStore: StoreRegion.Fr,
        filterIsAssignTo: userId,
        filterIsRead: false,
      };
      break;
    case ConversationTab.AssignedTo:
      filters = {
        filterStore: StoreRegion.Fr,
        filterIsAssignTo: userId,
        filterIsRead: false,
      };
      break;
    case ConversationTab.Delivery:
      filters = {
        filterCategory: ConversationCategory.Delivery,
        filterIsRead: false,
        filterStore: StoreRegion.Fr,
      };
      break;
    case ConversationTab.Order:
      filters = {
        filterCategory: ConversationCategory.Order,
        filterIsRead: false,
        filterStore: StoreRegion.Fr,
      };
      break;
    case ConversationTab.PostOperation:
      filters = {
        filterCategory: ConversationCategory.PostOperation,
        filterIsRead: false,
        filterStore: StoreRegion.Fr,
      };
      break;
    case ConversationTab.PreSales:
      filters = {
        filterCategory: ConversationCategory.PreSales,
        filterIsRead: false,
        filterStore: StoreRegion.Fr,
      };
      break;
    default:
      filters = {
        filterStore: StoreRegion.Fr,
        filterIsRead: false,
      };
      break;
  }
  return filters;
};

export const getConversationTab = (
  path: string,
  userId: string
): ConversationTab => {
  let conversationTab: ConversationTab;
  if (path.startsWith(`/${config.pathname.pinned}`)) {
    conversationTab = ConversationTab.Pinned;
  } else if (path.startsWith(`/${config.pathname.archives}`)) {
    conversationTab = ConversationTab.Read;
  } else if (path.startsWith(`/${config.pathname.claimAuto}`)) {
    conversationTab = ConversationTab.ClaimAuto;
  } else if (path.startsWith(`/${config.pathname.unassigned}`)) {
    conversationTab = ConversationTab.Unassigned;
  } else if (path.startsWith(`/${config.pathname.usa}`)) {
    conversationTab = ConversationTab.Usa;
  } else if (path.startsWith(`/${config.pathname.assignedTo}/${userId}`)) {
    conversationTab = ConversationTab.AssignedToMe;
  } else if (path.startsWith(`/${config.pathname.assignedTo}`)) {
    conversationTab = ConversationTab.AssignedTo;
  } else if (path.startsWith(`/${config.pathname.search}`)) {
    conversationTab = ConversationTab.Search;
  } else if (path.startsWith(`/${config.pathname.delivery}`)) {
    conversationTab = ConversationTab.Delivery;
  } else if (path.startsWith(`/${config.pathname.order}`)) {
    conversationTab = ConversationTab.Order;
  } else if (path.startsWith(`/${config.pathname.postOperation}`)) {
    conversationTab = ConversationTab.PostOperation;
  } else if (path.startsWith(`/${config.pathname.preSales}`)) {
    conversationTab = ConversationTab.PreSales;
  } else {
    conversationTab = ConversationTab.Unhandled;
  }
  return conversationTab;
};

export const getPathnameFromConversationTab = (
  conversationTab: ConversationTab,
  userId?: string
): string => {
  let pathname: string;
  switch (conversationTab) {
    case ConversationTab.Pinned:
      pathname = config.pathname.pinned;
      break;
    case ConversationTab.ClaimAuto:
      pathname = config.pathname.claimAuto;
      break;
    case ConversationTab.Read:
      pathname = config.pathname.archives;
      break;
    case ConversationTab.Unassigned:
      pathname = config.pathname.unassigned;
      break;
    case ConversationTab.Usa:
      pathname = config.pathname.usa;
      break;
    case ConversationTab.AssignedToMe:
      pathname = `${config.pathname.assignedTo}/${userId ?? ''}`;
      break;
    case ConversationTab.AssignedTo:
      pathname = `${config.pathname.assignedTo}/${userId ?? ''}`;
      break;
    case ConversationTab.Search:
      pathname = config.pathname.search;
      break;
    case ConversationTab.Delivery:
      pathname = config.pathname.delivery;
      break;
    case ConversationTab.Order:
      pathname = config.pathname.order;
      break;
    case ConversationTab.PostOperation:
      pathname = config.pathname.postOperation;
      break;
    case ConversationTab.PreSales:
      pathname = config.pathname.preSales;
      break;
    default:
      pathname = config.pathname.unhandled;
      break;
  }
  return pathname;
};

// Mapping of https://github.com/appchoose/backend/blob/79a43f17bb2bf0a48001a23bd762e479e3138f0d/servers/legacy/src/adapters/app/message.category.ts#L24-L39
export const getFlowReasonMessageFromFlow = (reason: string, t: TFunction) => {
  switch (reason) {
    case 'about_sale':
      return t('conversation.message_group.reason.about_sale');
    case 'about_checkout':
      return t('conversation.message_group.reason.about_checkout');
    case 'about_referral':
      return t('conversation.message_group.reason.about_referral');
    case 'about_after_sales_policy':
      return t('conversation.message_group.reason.about_after_sales_policy');
    case 'about_account':
      return t('conversation.message_group.reason.about_account');
    case 'about_bug':
      return t('conversation.message_group.reason.about_bug');
    case 'about_order/not_received':
      return t('conversation.message_group.reason.about_order_not_received');
    case 'about_order/damaged':
      return t('conversation.message_group.reason.about_order_damaged');
    case 'about_order/wrong_or_missing_items':
      return t(
        'conversation.message_group.reason.about_order_wrong_or_missing_items'
      );
    case 'about_order/wrong_items':
      return t('conversation.message_group.reason.about_order_wrong_items');
    case 'about_order/missing_items':
      return t('conversation.message_group.reason.about_order_missing_items');
    case 'about_order/delayed':
      return t('conversation.message_group.reason.about_order_delivery_delay');
    case 'about_order/wrong_order':
      return t('conversation.message_group.reason.about_order_wrong_order');
    case 'about_order/tracking':
      return t('conversation.message_group.reason.about_order_tracking');
    case 'about_order/voucher':
      return t('conversation.message_group.reason.about_order_voucher');
    case 'about_order/other':
      return t('conversation.message_group.reason.about_order_other');
    case 'other':
      return t('conversation.message_group.reason.other');
    default:
      return t('conversation.message_group.reason.unknown_motive');
  }
};

export const getClaimReasonFromFlow = (reason: string) => {
  switch (reason) {
    case 'about_order/not_received':
      return ClaimReason.NotDeliveredAndTrackingDelivered;
    case 'about_order/damaged':
      return ClaimReason.DeliveredButDamaged;
    case 'about_order/wrong_or_missing_items':
      return ClaimReason.DeliveredButWrong;
    case 'about_order/wrong_items':
      return ClaimReason.DeliveredButWrong;
    case 'about_order/missing_items':
      return ClaimReason.DeliveredButIncomplete;
    case 'about_order/wrong_order':
      return ClaimReason.DeliveredButWrong;
    case 'about_order/tracking':
      return ClaimReason.NotDeliveredAndTrackingBlocked;
    case 'about_order/delayed':
    case 'about_order/voucher':
    case 'about_order/other':
    case 'about_sale':
    case 'about_checkout':
    case 'about_referral':
    case 'about_after_sales_policy':
    case 'about_account':
    case 'about_bug':
    case 'other':
    default:
      return ClaimReason.Other;
  }
};

export const getClaimReason = (reason: ClaimReason, t: TFunction) => {
  switch (reason) {
    case ClaimReason.DeliveredButDamaged:
      return t('order.claims.reasons.delivered_but_damaged');
    case ClaimReason.DeliveredButIncomplete:
      return t('order.claims.reasons.delivered_but_incomplete');
    case ClaimReason.DeliveredButWrong:
      return t('order.claims.reasons.delivered_but_wrong');
    case ClaimReason.DigitalCouponCodeNotWorking:
      return t('order.claims.reasons.digital_coupon_code_not_working');
    case ClaimReason.NotDeliveredAndReturnedToSender:
      return t('order.claims.reasons.not_delivered_and_returned_to_sender');
    case ClaimReason.NotDeliveredAndTrackingBlocked:
      return t('order.claims.reasons.not_delivered_and_tracking_blocked');
    case ClaimReason.NotDeliveredAndTrackingDelivered:
      return t('order.claims.reasons.not_delivered_and_tracking_delivered');
    case ClaimReason.Other:
      return t('order.claims.reasons.other');
    default:
      return '';
  }
};

export const getClaimReasonOrOther = (
  reason: ClaimReason,
  otherReasonLabel: string | null | undefined,
  t: TFunction
) => {
  if (reason === ClaimReason.Other && otherReasonLabel) {
    return otherReasonLabel;
  }
  return getClaimReason(reason, t);
};

export const getDefaultClaimReasonTemplate = (
  reason: ClaimReason,
  language: string,
  t: TFunction
) => {
  let message = '';

  switch (reason) {
    case ClaimReason.NotDeliveredAndTrackingDelivered:
      message = t('complaint.reasons_message.shipped_not_received', {
        lng: language,
      });
      break;
    case ClaimReason.DeliveredButDamaged:
      message = t('complaint.reasons_message.order_damaged', {
        lng: language,
      });
      break;
    case ClaimReason.DeliveredButIncomplete:
      message = t('complaint.reasons_message.order_incomplete', {
        lng: language,
      });
      break;
    case ClaimReason.NotDeliveredAndReturnedToSender:
      message = t('complaint.reasons_message.returned_to_sender', {
        lng: language,
      });
      break;
    case ClaimReason.NotDeliveredAndTrackingBlocked:
      message = t('complaint.reasons_message.blocked_shipment_not_received', {
        lng: language,
      });
      break;
    case ClaimReason.Other:
      message = t('complaint.reasons_message.other', {
        lng: language,
      });
      break;
    case ClaimReason.DigitalCouponCodeNotWorking:
      message = t('complaint.reasons_message.digital_coupon_code_not_working', {
        lng: language,
      });
      break;
    case ClaimReason.DeliveredButWrong:
      message = t('complaint.reasons_message.delivered_but_wrong', {
        lng: language,
      });
      break;
  }
  return message;
};

export const isClaimPending = (claim: Claim | undefined): boolean => {
  if (!claim) return false;

  if (!claim.isAccepted && !claim.isDeclined) return true;

  return false;
};

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  FloatPercentage: { input: number; output: number };
  Json: { input: any; output: any };
  PositiveInteger: { input: number; output: number };
  Upload: { input: any; output: any };
  Void: { input: void; output: void };
};

/** **Shipping or Billing address** */
export type Address = {
  __typename?: 'Address';
  /** **City** */
  city: Scalars['String']['output'];
  /** **Country** */
  country: Scalars['String']['output'];
  /** **Country code** */
  countryCode: Scalars['String']['output'];
  /** **County** */
  county?: Maybe<Scalars['String']['output']>;
  /** **Firstname** */
  firstname: Scalars['String']['output'];
  /** **Lastname** */
  lastname: Scalars['String']['output'];
  /** **Phone number** */
  phoneNumber: Scalars['String']['output'];
  /** **Street** */
  street: Scalars['String']['output'];
  /** **Street additional** */
  streetAdditional?: Maybe<Scalars['String']['output']>;
  /** **Zip code** */
  zipCode: Scalars['String']['output'];
};

/** **Address V2 (Address is already taken)** */
export type AddressV2 = {
  __typename?: 'AddressV2';
  /** **City** */
  city: Scalars['String']['output'];
  /** **Country** */
  country: Scalars['String']['output'];
  /** **Country code** */
  countryCode: Scalars['String']['output'];
  /** **County** */
  county?: Maybe<Scalars['String']['output']>;
  /** **Letters indicating which state the address is from** */
  state?: Maybe<Scalars['String']['output']>;
  /** **Street** */
  street: Scalars['String']['output'];
  /** **Street additional** */
  streetAdditional?: Maybe<Scalars['String']['output']>;
  /** **Zip code** */
  zipCode: Scalars['String']['output'];
};

/** **Billing address input** */
export type BillingAddressInput = {
  /** **City** */
  city: Scalars['String']['input'];
  /** **Country code** */
  countryCode: Scalars['String']['input'];
  /** **County** */
  county?: InputMaybe<Scalars['String']['input']>;
  /** **Firstname** */
  firstname: Scalars['String']['input'];
  /** **Lastname** */
  lastname: Scalars['String']['input'];
  /** **Street** */
  street: Scalars['String']['input'];
  /** **Street additional** */
  streetAdditional?: InputMaybe<Scalars['String']['input']>;
  /** **Zip code** */
  zipCode: Scalars['String']['input'];
};

export type Brand = {
  __typename?: 'Brand';
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactPhone?: Maybe<Scalars['String']['output']>;
  hubspot_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isFrench?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  returnAddress?: Maybe<Scalars['Json']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

/** **The buyer is the person who has been billed for the order** */
export type Buyer = {
  __typename?: 'Buyer';
  /** **Address** */
  address: AddressV2;
  /** **First name** */
  firstName: Scalars['String']['output'];
  /** **Full name ("first_name last_name")** */
  fullName: Scalars['String']['output'];
  /** **Last name** */
  lastName: Scalars['String']['output'];
  /** **Previous buyer if it has been updated** */
  previous?: Maybe<Buyer>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

/** **Cancel order input** */
export type CancelOrderInput = {
  /** **Does the customer should be notified ?** */
  notifyCustomer: Scalars['Boolean']['input'];
  /** **Does the order items should be put back in stock ?** */
  putBackOrderItemsInStock: Scalars['Boolean']['input'];
};

/** **Cancel order items input** */
export type CancelOrderItemsInput = {
  /** **Does the customer should be notified about items out of stock ?** */
  notifyCustomerAboutItemsOutOfStock: Scalars['Boolean']['input'];
  /** **Credit compensation in cents to give to the customer if the order is cancelled** */
  orderCancelledCreditCompensationInCents?: InputMaybe<Scalars['Int']['input']>;
  /** **Does the order items should be put back in stock ?** */
  putBackOrderItemsInStock: Scalars['Boolean']['input'];
};

/** **Cashback status** */
export enum CashbackStatus {
  /**
   * **Cashback cancelled because of post purchase action**
   *
   * - Could not be transformed into credit
   */
  Cancelled = 'cancelled',
  /**
   * **Cashback transformed into credit**
   *
   * - Cannot be updated
   */
  Fulfilled = 'fulfilled',
  /**
   * **Cashback not yet transformed into credit**
   *
   * - Still can be updated or cancelled
   */
  Pending = 'pending',
}

export type CashbackUpdate = {
  __typename?: 'CashbackUpdate';
  currentAmount: Scalars['Int']['output'];
  postActionAmount: Scalars['Int']['output'];
};

export type CategoryData = {
  __typename?: 'CategoryData';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** **Claim** */
export type Claim = {
  __typename?: 'Claim';
  /** **Message written by the seller when it accept the claim** */
  acceptedMessage?: Maybe<Scalars['String']['output']>;
  /** **Action chose by the seller when it has accepted the claim** */
  actionFromSeller?: Maybe<ClaimSellerAction>;
  /** **Date of the creation** */
  createdAt: Scalars['DateTime']['output'];
  /** **Message written by the seller when it decline the claim** */
  declinedMessage?: Maybe<Scalars['String']['output']>;
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Is accepted ?** */
  isAccepted: Scalars['Boolean']['output'];
  /** **Is declined ?** */
  isDeclined: Scalars['Boolean']['output'];
  /** **Was created by automatic flow ?** */
  isOpenedByBot: Scalars['Boolean']['output'];
  /** **Items** */
  items: Array<Item>;
  /** **Message written by the support team to the seller** */
  messageFromSupport?: Maybe<Scalars['String']['output']>;
  /** **Other reason label written by the support team when the reason is "OTHER"** */
  otherReasonLabel?: Maybe<Scalars['String']['output']>;
  /** **Proof URLs** */
  proofUrls: Array<Scalars['String']['output']>;
  /** **Reason** */
  reason: ClaimReason;
  /** **Reship action error reasons if the action is not available** */
  reshipActionErrorReasons: Array<ClaimReshipActionErrorReason>;
  /** **Latest update date** */
  updatedAt: Scalars['DateTime']['output'];
};

/** **Claim item input** */
export type ClaimItemInput = {
  /** **Digital coupon code (only for `DIGITAL_COUPON_CODE_NOT_WORKING` reason)** */
  digitalCouponCode?: InputMaybe<Scalars['String']['input']>;
  /** **Product ID** */
  productId: Scalars['ID']['input'];
  /** **Product variant ID** */
  productVariantId: Scalars['ID']['input'];
};

/** **Claim Reason** */
export enum ClaimReason {
  /** **The customer has received the parcel but is damaged** */
  DeliveredButDamaged = 'DELIVERED_BUT_DAMAGED',
  /** **The customer has received the parcel but is incomplete** */
  DeliveredButIncomplete = 'DELIVERED_BUT_INCOMPLETE',
  /** **The customer has received the parcel but is wrong (wrong or missing items)** */
  DeliveredButWrong = 'DELIVERED_BUT_WRONG',
  /** **The customer has received a digital coupon code but is not working** */
  DigitalCouponCodeNotWorking = 'DIGITAL_COUPON_CODE_NOT_WORKING',
  /** **The customer has not received the parcel and has been returned to the sender** */
  NotDeliveredAndReturnedToSender = 'NOT_DELIVERED_AND_RETURNED_TO_SENDER',
  /** **The customer has not received the parcel and the tracking is blocked** */
  NotDeliveredAndTrackingBlocked = 'NOT_DELIVERED_AND_TRACKING_BLOCKED',
  /** **The customer has not received the parcel and the tracking is delivered** */
  NotDeliveredAndTrackingDelivered = 'NOT_DELIVERED_AND_TRACKING_DELIVERED',
  /** **Other** */
  Other = 'OTHER',
}

/** **Claim reship action error reason** */
export enum ClaimReshipActionErrorReason {
  /** **There is no items to reship** */
  NoItems = 'NO_ITEMS',
  /** **The order has already been reshipped** */
  OrderAlreadyReshipped = 'ORDER_ALREADY_RESHIPPED',
  /** **The order is cancelled** */
  OrderCancelled = 'ORDER_CANCELLED',
  /** **Some of the items to reship are cancelled** */
  SomeItemsCancelled = 'SOME_ITEMS_CANCELLED',
  /** **Some of the items to reship are digital** */
  SomeItemsDigital = 'SOME_ITEMS_DIGITAL',
}

/** **Claim seller action (action chose by the seller when it has accepted the claim)** */
export enum ClaimSellerAction {
  /** **The seller has directy handled the claim with the customer** */
  DirectlyHandledWithTheCustomer = 'DIRECTLY_HANDLED_WITH_THE_CUSTOMER',
  /** **The seller decided to resend a new digital coupon code for the items** */
  ItemsDigitalCouponCodesResent = 'ITEMS_DIGITAL_COUPON_CODES_RESENT',
  /** **The seller decided to refund the items** */
  ItemsRefunded = 'ITEMS_REFUNDED',
  /** **The seller decided to reship the items** */
  ItemsReshipped = 'ITEMS_RESHIPPED',
}

export enum ConversationCategory {
  BotProcessing = 'bot_processing',
  Delivery = 'delivery',
  Order = 'order',
  PostOperation = 'post_operation',
  PreSales = 'pre_sales',
}

export type ConversationEmail = {
  __typename?: 'ConversationEmail';
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fromChoose?: Maybe<Scalars['Boolean']['output']>;
  isFixed?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
};

export type ConversationMessage = {
  __typename?: 'ConversationMessage';
  body: Scalars['String']['output'];
  category?: Maybe<Scalars['String']['output']>;
  chooseReply?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  emailSubject?: Maybe<Scalars['String']['output']>;
  extraData?: Maybe<ExtraData>;
  file?: Maybe<Scalars['String']['output']>;
  fromEmail?: Maybe<Scalars['String']['output']>;
  fromPage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isBot?: Maybe<Scalars['Boolean']['output']>;
  isFixed?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  network?: Maybe<Scalars['Json']['output']>;
  operator?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  orderInfos?: Maybe<OrderInfos>;
  photo?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Scalars['Json']['output']>;
  readAt?: Maybe<Scalars['DateTime']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  topic?: Maybe<Scalars['Int']['output']>;
  userKey: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
  versionCode?: Maybe<Scalars['String']['output']>;
  versionCodePush?: Maybe<Scalars['Json']['output']>;
};

export type ConversationMessageGroup = {
  __typename?: 'ConversationMessageGroup';
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  files: Array<Scalars['String']['output']>;
  fromEmail?: Maybe<Scalars['String']['output']>;
  fromPage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isFromChoose: Scalars['Boolean']['output'];
  operator?: Maybe<Scalars['String']['output']>;
  order: ConversationMessageGroupOrder;
  photos: Array<Scalars['String']['output']>;
  readAt?: Maybe<Scalars['DateTime']['output']>;
  reason: Scalars['String']['output'];
  userKey: Scalars['String']['output'];
};

export type ConversationMessageGroupItemConcern = {
  __typename?: 'ConversationMessageGroupItemConcern';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  options: Array<Scalars['String']['output']>;
  productId: Scalars['String']['output'];
  variantId: Scalars['String']['output'];
};

export type ConversationMessageGroupOrder = {
  __typename?: 'ConversationMessageGroupOrder';
  brandName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  itemsConcern?: Maybe<Array<ConversationMessageGroupItemConcern>>;
  willBeOpenedByBotAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ConversationStatus = {
  __typename?: 'ConversationStatus';
  assignTo?: Maybe<Scalars['String']['output']>;
  category?: Maybe<ConversationCategory>;
  isFixed?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
};

export enum ConversationType {
  App = 'APP',
  Email = 'EMAIL',
}

export type ConversationUpdate = {
  isFixed?: InputMaybe<Scalars['Boolean']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  operator: Scalars['String']['input'];
  status?: InputMaybe<MessageStatus>;
  userKey: Scalars['String']['input'];
};

export type ConversationV2 = {
  __typename?: 'ConversationV2';
  messages: Array<MessageEntry>;
  status: ConversationStatus;
  user?: Maybe<User>;
};

export type CouponUpdate = {
  code?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['Int']['input'];
  start_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type Credit = {
  __typename?: 'Credit';
  currency: StoreCurrency;
  /** Value in cents */
  value: Scalars['Int']['output'];
};

export type CreditHistory = {
  __typename?: 'CreditHistory';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  credit: Credit;
  description?: Maybe<Scalars['String']['output']>;
  operator?: Maybe<Scalars['String']['output']>;
  userKey: Scalars['String']['output'];
};

export type CreditInput = {
  currency: StoreCurrency;
  /** amount to credit NOT in cents */
  value: Scalars['Int']['input'];
};

export type CreditUpdate = {
  /**
   * Amount to credit in given currency.
   * value NOT expressed in cents
   */
  credit: CreditInput;
  description?: InputMaybe<Scalars['String']['input']>;
  operator?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  userKey: Scalars['String']['input'];
};

/** **Currency ISO code** */
export enum CurrencyIso {
  /** **Euro** */
  Eur = 'eur',
  /** **US Dollar** */
  Usd = 'usd',
}

/** **Represents a range of date and time when the parcel is expected to arrive at the customer's location** */
export type DeliveryDateRange = {
  __typename?: 'DeliveryDateRange';
  /** **The creation date** */
  createdAt: Scalars['DateTime']['output'];
  /** **The latest delivery date and time when the parcel is expected to arrive at the customer's location** */
  end: Scalars['Date']['output'];
  /** **The earliest delivery date and time when the parcel is expected to arrive at the customer's location** */
  start: Scalars['Date']['output'];
};

/** **Delivery date range input** */
export type DeliveryDateRangeInput = {
  /** **The latest delivery date and time when the parcel is expected to arrive at the customer's location** */
  end: Scalars['Date']['input'];
  /** **The earliest delivery date and time when the parcel is expected to arrive at the customer's location** */
  start: Scalars['Date']['input'];
};

export enum DeliveryType {
  App = 'APP',
  Email = 'EMAIL',
}

export type Email = {
  __typename?: 'Email';
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fromChoose?: Maybe<Scalars['Boolean']['output']>;
};

export type ExtraData = {
  __typename?: 'ExtraData';
  brand_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type InboxDataV2 = {
  __typename?: 'InboxDataV2';
  emails: Array<Email>;
  messages: Array<Message>;
};

/** **Item** */
export type Item = {
  __typename?: 'Item';
  /** **Digital coupon code** */
  digitalCouponCode?: Maybe<Scalars['String']['output']>;
  /** **GTIN** */
  gtin?: Maybe<Scalars['String']['output']>;
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Image URL** */
  imageUrl: Scalars['String']['output'];
  /** **Is cancellable ?** */
  isCancellable: Scalars['Boolean']['output'];
  /** **Is cancelled ?** */
  isCancelled: Scalars['Boolean']['output'];
  /** **Is digital ?** */
  isDigital: Scalars['Boolean']['output'];
  /** **Is offered ?** */
  isOffered: Scalars['Boolean']['output'];
  /** **Is reshipped ?** */
  isReshipped: Scalars['Boolean']['output'];
  /** **A return has been generated for this item ?** */
  isReturnGenerated: Scalars['Boolean']['output'];
  /** **A return has been validated for this item ?** */
  isReturnValidated: Scalars['Boolean']['output'];
  /** **Is returnable ?** */
  isReturnable: Scalars['Boolean']['output'];
  /** **Name** */
  name: Scalars['String']['output'];
  /** **New digital coupon code** */
  newDigitalCouponCode?: Maybe<Scalars['String']['output']>;
  /** **Price bought by Choose** */
  priceBoughtByChoose: Price;
  /** **Price sold by Choose to the customer (in cents)** */
  priceSold: Price;
  /** **Product ID** */
  productId: Scalars['String']['output'];
  /** **Product variant ID** */
  productVariantId?: Maybe<Scalars['String']['output']>;
  /** **Refund summary** */
  refundSummary: OrderLineRefundSummary;
  /** **Return reason code** */
  returnReasonCode?: Maybe<ItemReturnReasonCode>;
  /** **Size** */
  size?: Maybe<Scalars['String']['output']>;
  /** **SKU** */
  sku?: Maybe<Scalars['String']['output']>;
};

/** **Item reference** */
export type ItemReference = {
  /** **Quantity** */
  quantity: Scalars['Int']['input'];
  /** **Reference (GTIN or SKU)** */
  reference: Scalars['String']['input'];
};

/** **Item return reason code** */
export enum ItemReturnReasonCode {
  /** **Better price** */
  BetterPrice = 'BETTER_PRICE',
  /** **Damaged product** */
  DamagedProduct = 'DAMAGED_PRODUCT',
  /** **Damaged shipping** */
  DamagedShipping = 'DAMAGED_SHIPPING',
  /** **Delay** */
  Delay = 'DELAY',
  /** **Description** */
  Description = 'DESCRIPTION',
  /** **Other** */
  Other = 'OTHER',
  /** **Picture** */
  Picture = 'PICTURE',
  /** **Quality** */
  Quality = 'QUALITY',
  /** **Size** */
  Size = 'SIZE',
  /** **Wrong article** */
  WrongArticle = 'WRONG_ARTICLE',
}

/** **Item return reason codes input** */
export type ItemReturnReasonCodesInput = {
  /** **Item Identifiant** */
  itemId: Scalars['ID']['input'];
  /** **Reason of return** */
  returnReasonCode?: InputMaybe<ItemReturnReasonCode>;
};

export type JoinReturnSlipInput = {
  /** **Items returned with reasons** */
  itemReturnReasonCodes: Array<ItemReturnReasonCodesInput>;
  /** **Slug (carrier) parcel** */
  trackingCarrierSlug: Scalars['String']['input'];
  /** **Tracking number of parcel** */
  trackingNumber: Scalars['String']['input'];
};

export type Message = {
  __typename?: 'Message';
  aiNeedResponse?: Maybe<Scalars['Boolean']['output']>;
  aiPriorityScore?: Maybe<Scalars['Float']['output']>;
  aiSentiment?: Maybe<Scalars['String']['output']>;
  assignTo?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  store: StoreRegion;
  total_orders?: Maybe<Scalars['Int']['output']>;
  userKey?: Maybe<Scalars['String']['output']>;
};

export type MessageAdded = {
  __typename?: 'MessageAdded';
  assignToOperatorId?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  category?: Maybe<ConversationCategory>;
  chooseReply?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  isAssign?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  total_orders?: Maybe<Scalars['Int']['output']>;
  userKey?: Maybe<Scalars['String']['output']>;
  userStore?: Maybe<StoreRegion>;
};

export type MessageChanged = {
  __typename?: 'MessageChanged';
  assignToOperatorId?: Maybe<Scalars['String']['output']>;
  category?: Maybe<ConversationCategory>;
  isAssign?: Maybe<Scalars['Boolean']['output']>;
  isFixed?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  userKey?: Maybe<Scalars['String']['output']>;
  userStore?: Maybe<StoreRegion>;
};

export type MessageEntry = ConversationMessage | ConversationMessageGroup;

export type MessageFilter = {
  filterCategory?: InputMaybe<ConversationCategory>;
  filterIsAssignTo?: InputMaybe<Scalars['String']['input']>;
  filterIsAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  filterIsPinned?: InputMaybe<Scalars['Boolean']['input']>;
  filterIsRead?: InputMaybe<Scalars['Boolean']['input']>;
  filterStore?: InputMaybe<StoreRegion>;
};

export enum MessageGroupCanal {
  Banner = 'banner',
  Message = 'message',
}

/** **Message group options input** */
export type MessageGroupOptions = {
  /**
   * **Credit amount to add to the customers**
   *
   * - Currency is computed from the sale's currency
   */
  creditAmount?: InputMaybe<Scalars['Int']['input']>;
  /** **The new delivery date range to update** */
  deliveryDateRange?: InputMaybe<DeliveryDateRangeInput>;
  /** **The postpone in days to apply to the current delivery date range** */
  deliveryDateRangePostponeInDays?: InputMaybe<
    Scalars['PositiveInteger']['input']
  >;
  /** **Order ids** */
  orderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** **Message group stats** */
export type MessageGroupStats = {
  __typename?: 'MessageGroupStats';
  /** **Customer count** */
  customerCount: Scalars['PositiveInteger']['output'];
  /** **Order ids** */
  orderIds: Array<Scalars['ID']['output']>;
};

export type MessageInput = {
  body: Scalars['String']['input'];
  deliveryType?: InputMaybe<DeliveryType>;
  email?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isFixed?: InputMaybe<Scalars['Boolean']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  operator: Scalars['String']['input'];
  status?: InputMaybe<MessageStatus>;
  subject?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<Scalars['Int']['input']>;
  userKey: Scalars['String']['input'];
};

export enum MessageStatus {
  Pinned = 'PINNED',
  Read = 'READ',
  Search = 'SEARCH',
  Unhandled = 'UNHANDLED',
}

export type Mutation = {
  __typename?: 'Mutation';
  assignConversation?: Maybe<Scalars['String']['output']>;
  /** **Cancels a claim using the order id and the claim id** */
  cancelClaim?: Maybe<Scalars['Void']['output']>;
  /** **Cancels the order using the order id and the input** */
  cancelOrder?: Maybe<Scalars['Void']['output']>;
  /** **Cancels the order items using the order id, item ids and the input** */
  cancelOrderItems?: Maybe<Scalars['Void']['output']>;
  /** **Cancels the order items by refs using the order id, item refs and the input** */
  cancelOrderItemsByRefs?: Maybe<Scalars['Void']['output']>;
  /** **Cancels the return using the order id** */
  cancelReturn?: Maybe<Scalars['Void']['output']>;
  changeReferral?: Maybe<Scalars['Json']['output']>;
  createCredit?: Maybe<Scalars['Boolean']['output']>;
  createPostPurchaseReferralCredit?: Maybe<Scalars['Boolean']['output']>;
  createReturn?: Maybe<Scalars['String']['output']>;
  createTemplateCategory?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `cancelReturn` instead */
  deleteReturn?: Maybe<Scalars['String']['output']>;
  deleteUser?: Maybe<Scalars['String']['output']>;
  /** **Generate order return slip by previously upload label using upload label** */
  joinReturnSlip?: Maybe<Scalars['Void']['output']>;
  /** **Mark order as delivered** */
  markOrderAsDelivered?: Maybe<Scalars['Void']['output']>;
  /** **Opens a claim using the order id and the claim input** */
  openClaim: Scalars['String']['output'];
  operatorReplying?: Maybe<Scalars['String']['output']>;
  pushMessage?: Maybe<Scalars['String']['output']>;
  /** **Refunds the items of an order partially using the input** */
  refundOrderItemsPartially: RefundOrderSummary;
  /** **Refunds the shipping of an order partially using the input** */
  refundOrderShippingPartially: RefundOrderSummary;
  sendMessageGroup?: Maybe<Scalars['String']['output']>;
  setConversationAsRead?: Maybe<Scalars['String']['output']>;
  setConversationCategory?: Maybe<Scalars['String']['output']>;
  setUserNote?: Maybe<Scalars['Boolean']['output']>;
  /** **Split the order in multiple orders** */
  splitOrder?: Maybe<Scalars['Void']['output']>;
  /** **Update the billing address of all billing orders** */
  updateBillingOrdersBillingAddress?: Maybe<Scalars['Void']['output']>;
  /** **Update the shipping address of all billing orders** */
  updateBillingOrdersShippingAddress?: Maybe<Scalars['Void']['output']>;
  /** **Update the shipping address of an order** */
  updateOrderShippingAddress?: Maybe<Scalars['Void']['output']>;
  updateTemplateMessage?: Maybe<Scalars['String']['output']>;
  /** **Validates the return of items of an order** */
  validateOrderItemsReturned?: Maybe<Scalars['Void']['output']>;
};

export type MutationAssignConversationArgs = {
  conversationId: Scalars['String']['input'];
  operatorId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCancelClaimArgs = {
  claimId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
  orderId: Scalars['ID']['input'];
};

export type MutationCancelOrderItemsArgs = {
  input: CancelOrderItemsInput;
  itemIds: Array<Scalars['ID']['input']>;
  orderId: Scalars['ID']['input'];
};

export type MutationCancelOrderItemsByRefsArgs = {
  input: CancelOrderItemsInput;
  itemRefs: Array<ItemReference>;
  orderId: Scalars['ID']['input'];
};

export type MutationCancelReturnArgs = {
  orderId: Scalars['ID']['input'];
};

export type MutationChangeReferralArgs = {
  referral: Scalars['String']['input'];
  userKey: Scalars['String']['input'];
};

export type MutationCreateCreditArgs = {
  data: CreditUpdate;
};

export type MutationCreatePostPurchaseReferralCreditArgs = {
  data: PostPurchaseReferralInput;
};

export type MutationCreateReturnArgs = {
  deliveryToChoose?: InputMaybe<Scalars['Boolean']['input']>;
  orderId: Scalars['String']['input'];
  products?: InputMaybe<Array<InputMaybe<Scalars['Json']['input']>>>;
  userKey: Scalars['String']['input'];
};

export type MutationCreateTemplateCategoryArgs = {
  name: Scalars['String']['input'];
};

export type MutationDeleteReturnArgs = {
  orderId: Scalars['String']['input'];
  userKey: Scalars['String']['input'];
};

export type MutationDeleteUserArgs = {
  userKey: Scalars['String']['input'];
};

export type MutationJoinReturnSlipArgs = {
  input: JoinReturnSlipInput;
  orderId: Scalars['ID']['input'];
};

export type MutationMarkOrderAsDeliveredArgs = {
  orderId: Scalars['ID']['input'];
};

export type MutationOpenClaimArgs = {
  input: OpenClaimInput;
  orderId: Scalars['ID']['input'];
};

export type MutationOperatorReplyingArgs = {
  data: OperatorReplyingUpdate;
};

export type MutationPushMessageArgs = {
  message?: InputMaybe<MessageInput>;
  type?: InputMaybe<ConversationType>;
};

export type MutationRefundOrderItemsPartiallyArgs = {
  input: RefundOrderItemsPartiallyInput;
  orderId: Scalars['ID']['input'];
};

export type MutationRefundOrderShippingPartiallyArgs = {
  input: RefundOrderShippingPartiallyInput;
  orderId: Scalars['ID']['input'];
};

export type MutationSendMessageGroupArgs = {
  canal: MessageGroupCanal;
  content: Scalars['String']['input'];
  options?: InputMaybe<MessageGroupOptions>;
  photos?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonId: Scalars['String']['input'];
  saleId: Scalars['String']['input'];
  segmentId: SegmentType;
};

export type MutationSetConversationAsReadArgs = {
  data?: InputMaybe<ConversationUpdate>;
  type?: InputMaybe<ConversationType>;
};

export type MutationSetConversationCategoryArgs = {
  category?: InputMaybe<ConversationCategory>;
  conversationId: Scalars['String']['input'];
};

export type MutationSetUserNoteArgs = {
  message: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationSplitOrderArgs = {
  newOrdersItemIds: Array<Array<Scalars['ID']['input']>>;
  orderId: Scalars['ID']['input'];
};

export type MutationUpdateBillingOrdersBillingAddressArgs = {
  billingId: Scalars['ID']['input'];
  input: BillingAddressInput;
};

export type MutationUpdateBillingOrdersShippingAddressArgs = {
  billingId: Scalars['ID']['input'];
  input: ShippingAddressInput;
};

export type MutationUpdateOrderShippingAddressArgs = {
  input: ShippingAddressInput;
  orderId: Scalars['ID']['input'];
};

export type MutationUpdateTemplateMessageArgs = {
  data: TemplateInput;
};

export type MutationValidateOrderItemsReturnedArgs = {
  orderId: Scalars['ID']['input'];
};

/** **Open claim input** */
export type OpenClaimInput = {
  /** **Items** */
  items?: InputMaybe<Array<ClaimItemInput>>;
  /** **Message written by the support team to the brand** */
  messageFromSupport?: InputMaybe<Scalars['String']['input']>;
  /** **Label written by the support team when the reason "other" is selected** */
  otherReasonLabel?: InputMaybe<Scalars['String']['input']>;
  /** **Proof URLs** */
  proofUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  /** **Reason** */
  reason: ClaimReason;
  /** **Reship shipping address** */
  reshipShippingAddress?: InputMaybe<ShippingAddressInput>;
};

/** **Operation type** */
export enum OperationType {
  /** **Choose with StockAZ clears its stock ** */
  Clearance = 'CLEARANCE',
  /** **The seller sell its products and ship on its own** */
  DropShipping = 'DROP_SHIPPING',
  /** **Choose has ordered the products before the operation and ship on its own** */
  FirmPurchase = 'FIRM_PURCHASE',
  /** **Choose order the products sold after the operation and ship it on its own** */
  PostOperationPurchase = 'POST_OPERATION_PURCHASE',
  /** **The reseller sell products from another seller => Not used anymore** */
  Reseller = 'RESELLER',
}

export type OperatorActive = {
  __typename?: 'OperatorActive';
  message?: Maybe<Scalars['String']['output']>;
  operator: Scalars['String']['output'];
  operatorId: Scalars['String']['output'];
  userKey: Scalars['String']['output'];
};

export type OperatorReplying = {
  __typename?: 'OperatorReplying';
  isCancelled?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  operator: Scalars['String']['output'];
  operatorId: Scalars['String']['output'];
  status?: Maybe<MessageStatus>;
  userKey?: Maybe<Scalars['String']['output']>;
};

export type OperatorReplyingUpdate = {
  isCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  operator: Scalars['String']['input'];
  operatorId: Scalars['String']['input'];
  status?: InputMaybe<MessageStatus>;
  userKey?: InputMaybe<Scalars['String']['input']>;
};

export type OperatorStats = {
  __typename?: 'OperatorStats';
  nbConversationAssigned: Scalars['Int']['output'];
  operatorId: Scalars['String']['output'];
};

export type OperatorUser = {
  __typename?: 'OperatorUser';
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  /** Freelance people do not have the auto-assignation feature */
  isFreelance: Scalars['Boolean']['output'];
  /** Tech people do not have an inbox */
  isTech: Scalars['Boolean']['output'];
  operatorId: Scalars['String']['output'];
};

export type OrderInfos = {
  __typename?: 'OrderInfos';
  brand_name?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Scalars['Json']['output']>;
};

/** **Order integration** */
export type OrderIntegration = {
  __typename?: 'OrderIntegration';
  /** **Error message from the integration (Shopify, StockAZ)** */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** **ID of the order from the integration (Shopify, StockAZ)** */
  orderId?: Maybe<Scalars['ID']['output']>;
  /** **Shopify integration** */
  shopify?: Maybe<ShopifyOrderIntegration>;
};

/** **Order item partial refund intent** */
export type OrderItemPartialRefundIntent = {
  /** **Item id to refund** */
  itemId: Scalars['ID']['input'];
  /**
   * **Percentage of the item price to refund**
   *
   * Should be between `0.00` and `100.00`
   */
  percentageToRefund: Scalars['FloatPercentage']['input'];
};

/** **Order line refund** */
export type OrderLineRefund = {
  __typename?: 'OrderLineRefund';
  /** **Amount in cents of the refund** */
  amountInCents: Scalars['PositiveInteger']['output'];
  /** **Cover party that has covered the refund** */
  coveredBy: OrderLineRefundCover;
  /** **Creation date of the refund** */
  createdAt: Scalars['DateTime']['output'];
  /** **Identifier of the refund** */
  id: Scalars['ID']['output'];
  /** **Internal note associated to the refund** */
  internalNote?: Maybe<Scalars['String']['output']>;
  /** **Identifier of the line (item id for "ITEM" and order id for "SHIPPING" and "LEGACY")** */
  lineId: Scalars['ID']['output'];
  /** **Line type ("ITEM" or "SHIPPING")** */
  lineType: OrderLineType;
  /** **Identifier of the plan (refunds made at the same time have the same plan id)** */
  planId: Scalars['ID']['output'];
  /** **Reason of the refund** */
  reason: OrderLineRefundReason;
};

/** **Order line refund cover** */
export enum OrderLineRefundCover {
  /** **Choose has covered the refund** */
  Choose = 'CHOOSE',
  /** **The supplier has covered the refund** */
  Supplier = 'SUPPLIER',
}

/** **Order line refund reason** */
export enum OrderLineRefundReason {
  /** **A claim has been accepted** */
  ClaimAccepted = 'CLAIM_ACCEPTED',
  /** **A refund has been done directly** */
  Direct = 'DIRECT',
  /** **A refund has been done directly because a claim has the deadline exceeded** */
  DirectClaimDeadlineExceeded = 'DIRECT_CLAIM_DEADLINE_EXCEEDED',
  /** **A refund has been done directly because a claim has been refused** */
  DirectClaimRefused = 'DIRECT_CLAIM_REFUSED',
  /** **A refund has been done directly to make a commercial gesture** */
  DirectCommercialGesture = 'DIRECT_COMMERCIAL_GESTURE',
  /** **A refund has been done directly because an item has a wrong image** */
  DirectItemWrongImage = 'DIRECT_ITEM_WRONG_IMAGE',
  /** **A refund has been done directly because an item has a wrong price** */
  DirectItemWrongPrice = 'DIRECT_ITEM_WRONG_PRICE',
  /** **A refund has been done directly because a return has the deadline exceeded** */
  DirectReturnDeadlineExceeded = 'DIRECT_RETURN_DEADLINE_EXCEEDED',
  /** **A refund has been done directly because a return has been refused** */
  DirectReturnRefused = 'DIRECT_RETURN_REFUSED',
  /** **An item has been canceled** */
  ItemCanceled = 'ITEM_CANCELED',
  /** **An item has been canceled because of out of stock** */
  ItemCanceledOutOfStock = 'ITEM_CANCELED_OUT_OF_STOCK',
  /** **An order has been canceled** */
  OrderCanceled = 'ORDER_CANCELED',
  /** **A return has been accepted** */
  ReturnAccepted = 'RETURN_ACCEPTED',
  /** **An unknown reason (legacy)** */
  Unknown = 'UNKNOWN',
}

/** **Order line refund summary** */
export type OrderLineRefundSummary = {
  __typename?: 'OrderLineRefundSummary';
  /**
   * **Is refundable ?**
   *
   * Is not offered and not removed and has a price greater than 0
   */
  isRefundable: Scalars['Boolean']['output'];
  /**
   * **Is totally refunded ?**
   *
   * Has at least one refund and the refunded amount is equal to the price
   */
  isTotallyRefunded: Scalars['Boolean']['output'];
  /** **Refunded amount of the price in cents** */
  refundedAmountInCents: Scalars['PositiveInteger']['output'];
  /**
   * **Refunded amount of the price in percentage**
   *
   * Is between `0` and `100`
   */
  refundedAmountInPercentage: Scalars['FloatPercentage']['output'];
  /**
   * **Refunded amount of the price in percentage covered by Choose**
   *
   * Is between `0` and `100`
   */
  refundedAmountInPercentageCoveredByChoose: Scalars['FloatPercentage']['output'];
  /**
   * **Refunded amount of the price in percentage covered by the supplier**
   *
   * Is between `0` and `100`
   */
  refundedAmountInPercentageCoveredBySupplier: Scalars['FloatPercentage']['output'];
  /** **Remaining to refund amount of the price in cents** */
  remainingToRefundAmountInCents: Scalars['PositiveInteger']['output'];
  /**
   * **Remaining to refund amount of the price in percentage**
   *
   * Is between `0` and `100`
   */
  remainingToRefundAmountInPercentage: Scalars['FloatPercentage']['output'];
};

/** **Order line type** */
export enum OrderLineType {
  /** **Item** */
  Item = 'ITEM',
  /** **Legacy (not associated to an item or a shipping)** */
  Legacy = 'LEGACY',
  /** **Shipping** */
  Shipping = 'SHIPPING',
}

/** **Order payment** */
export type OrderPayment = {
  __typename?: 'OrderPayment';
  /** **Processor used for the payment** */
  processor: PaymentProcessor;
  /** **Processor detailed (eg: "stripe (ios Apple Pay)")** */
  processorDetailed: Scalars['String']['output'];
  /** **Processor transaction id** */
  processorTransactionId: Scalars['String']['output'];
  /** **Url of the transaction in the processor dashboard** */
  processorTransactionUrl: Scalars['String']['output'];
  /** **Sub processor used for the payment** */
  subProcessor?: Maybe<PaymentSubProcessor>;
  /** **Currency used for the payment transaction** */
  transactionCurrency: CurrencyIso;
  /** **Transaction plan** */
  transactionPlan: OrderPaymentPlan;
};

/** **Order payment plan** */
export type OrderPaymentPlan = {
  __typename?: 'OrderPaymentPlan';
  /** **Number of transactions already paid** */
  paidTransactionsCount: Scalars['PositiveInteger']['output'];
  /** **List of transactions waiting for payment** */
  pendingTransactions: Array<PendingPaymentTransaction>;
  /** **Number of transactions waiting for payment** */
  pendingTransactionsCount: Scalars['PositiveInteger']['output'];
};

/** **Order rating** */
export type OrderRating = {
  __typename?: 'OrderRating';
  /** **Message** */
  message?: Maybe<Scalars['String']['output']>;
  /** **Rate (between 1 and 5)** */
  rate: Scalars['Int']['output'];
};

/** **Order shipping** */
export type OrderShipping = {
  __typename?: 'OrderShipping';
  /** **Delivery date range history (ordered by createdAt desc)** */
  deliveryDateRangeHistory: Array<DeliveryDateRange>;
  /** **Estimated time in days** */
  estimatedTime: Scalars['Int']['output'];
  /** **Fees (in cents)** */
  fees: Scalars['Int']['output'];
  /** **Fees sold by Choose to the customer (in cents)** */
  feesSold: Scalars['Int']['output'];
  /** **Pickup point** */
  pickupPoint?: Maybe<PickupPoint>;
  /** **Policy ID** */
  policyId?: Maybe<Scalars['ID']['output']>;
  /** **Refund summary** */
  refundSummary: OrderLineRefundSummary;
};

/** **Order shipping partial refund intent** */
export type OrderShippingPartialRefundIntent = {
  /**
   * **Percentage of the shipping price to refund**
   *
   * Should be between `0.00` and `100.00`
   */
  percentageToRefund: Scalars['FloatPercentage']['input'];
};

/** **Order split action error reason** */
export enum OrderSplitActionErrorReason {
  /** **The order has all the items digital** */
  AllItemsDigital = 'ALL_ITEMS_DIGITAL',
  /** **The order is cancelled** */
  OrderCancelled = 'ORDER_CANCELLED',
  /** **The order has been reshipped** */
  OrderReshipped = 'ORDER_RESHIPPED',
  /** **The order has some claims pending** */
  SomeClaimsPending = 'SOME_CLAIMS_PENDING',
  /** **The order has some refunds** */
  SomeRefunds = 'SOME_REFUNDS',
  /** **The order has some return parcels** */
  SomeReturnParcels = 'SOME_RETURN_PARCELS',
  /** **The order has some return slips** */
  SomeReturnSlips = 'SOME_RETURN_SLIPS',
}

/** **Order tag brand** */
export enum OrderTagBrand {
  /** **The order is available for the brand and requires an action** */
  ActionRequired = 'ACTION_REQUIRED',
  /** **The order is available for the brand and requires an action because claim** */
  ActionRequiredClaim = 'ACTION_REQUIRED_CLAIM',
  /** **The order is available for the brand and requires an action because return** */
  ActionRequiredReturn = 'ACTION_REQUIRED_RETURN',
  /** **The order is available for the brand and requires an action because shipment incident** */
  ActionRequiredShipmentIncident = 'ACTION_REQUIRED_SHIPMENT_INCIDENT',
  /** **The order is available for the brand and requires an action because unfulfilled** */
  ActionRequiredUnfulfilled = 'ACTION_REQUIRED_UNFULFILLED',
  /** **The order is available for the brand** */
  Available = 'AVAILABLE',
}

/** **Order tag claim** */
export enum OrderTagClaim {
  /** **The order has some closed claims** */
  Closed = 'CLOSED',
  /** **The order has no claim** */
  None = 'NONE',
  /** **The order has some opened claims** */
  Opened = 'OPENED',
}

/** **Order tag claim reason** */
export enum OrderTagClaimReason {
  /** **The order has some claims with delivered but damaged reason** */
  DeliveredButDamaged = 'DELIVERED_BUT_DAMAGED',
  /** **The order has some claims with delivered but incomplete reason** */
  DeliveredButIncomplete = 'DELIVERED_BUT_INCOMPLETE',
  /** **The order has some claims with delivered but wrong reason** */
  DeliveredButWrong = 'DELIVERED_BUT_WRONG',
  /** **The order has no claim** */
  None = 'NONE',
  /** **The order has some claims with not delivered and returned to sender reason** */
  NotDeliveredAndReturnedToSender = 'NOT_DELIVERED_AND_RETURNED_TO_SENDER',
  /** **The order has some claims with not delivered and tracking blocked reason** */
  NotDeliveredAndTrackingBlocked = 'NOT_DELIVERED_AND_TRACKING_BLOCKED',
  /** **The order has some claims with not delivered and tracking delivered reason** */
  NotDeliveredAndTrackingDelivered = 'NOT_DELIVERED_AND_TRACKING_DELIVERED',
  /** **The order has some claims with other reason** */
  Other = 'OTHER',
  /** **The order has some claims with voucher not working reason** */
  VoucherNotWorking = 'VOUCHER_NOT_WORKING',
}

/** **Order tag fulfillment** */
export enum OrderTagFulfillment {
  /** **The order has all the items that are eligible to shipment in a shipment** */
  Fulfilled = 'FULFILLED',
  /** **The order has none of the items that are eligible to shipment in a shipment** */
  Unfulfilled = 'UNFULFILLED',
}

/** **Order tag refund** */
export enum OrderTagRefund {
  /** **The order has no refund** */
  None = 'NONE',
  /** **The order has some refunds but not totally refunded** */
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  /** **The order has some refunds and totally refunded** */
  TotallyRefunded = 'TOTALLY_REFUNDED',
}

/** **Order tag reship** */
export enum OrderTagReship {
  /** **The order has no reship** */
  None = 'NONE',
  /** **The order has some reships** */
  Reshipped = 'RESHIPPED',
}

/** **Order tag return** */
export enum OrderTagReturn {
  /** **The order has some canceled returns** */
  Canceled = 'CANCELED',
  /** **The order has some delivered returns** */
  Delivered = 'DELIVERED',
  /** **The order has some in progress returns** */
  InProgress = 'IN_PROGRESS',
  /** **The order has no return** */
  None = 'NONE',
  /** **The order has some requested returns** */
  Requested = 'REQUESTED',
  /** **The order has some validated returns** */
  Validated = 'VALIDATED',
}

/** **Order tag shipment** */
export enum OrderTagShipment {
  /** **The order has some attempt fail shipments** */
  AttemptFail = 'ATTEMPT_FAIL',
  /** **The order has some available for pickup shipments** */
  AvailableForPickup = 'AVAILABLE_FOR_PICKUP',
  /** **The order has some delivered shipments** */
  Delivered = 'DELIVERED',
  /** **The order has some exception shipments** */
  Exception = 'EXCEPTION',
  /** **The order has some expired shipments** */
  Expired = 'EXPIRED',
  /** **The order has some info received shipments** */
  InfoReceived = 'INFO_RECEIVED',
  /** **The order has some in transit shipments** */
  InTransit = 'IN_TRANSIT',
  /** **The order has no shipment** */
  None = 'NONE',
  /** **The order has some out for delivery shipments** */
  OutForDelivery = 'OUT_FOR_DELIVERY',
  /** **The order has some pending shipments** */
  Pending = 'PENDING',
}

/** **Order tag shipping mode** */
export enum OrderTagShippingMode {
  /** **The shipments will be shipped at the recipient's home** */
  Home = 'HOME',
  /** **The order will not be shipped (digital items)** */
  None = 'NONE',
  /** **The shipments will be shipped at a pickup point** */
  PickupPoint = 'PICKUP_POINT',
}

/** **Order tag shopify** */
export enum OrderTagShopify {
  /** **The order has no shopify** */
  None = 'NONE',
  /** **The order has a shopify and is synchronized** */
  Synchronized = 'SYNCHRONIZED',
  /** **The order has a shopify and is not synchronized** */
  Unsynchronized = 'UNSYNCHRONIZED',
}

/** **Order tag status** */
export enum OrderTagStatus {
  /** **The order has been canceled by the customer or by a member of the support team** */
  Canceled = 'CANCELED',
  /** **The order has been placed less than 15 minutes ago** */
  PendingValidation = 'PENDING_VALIDATION',
  /** **The order has been placed more than 15 minutes ago** */
  Validated = 'VALIDATED',
}

/** **Order tag type** */
export enum OrderTagType {
  /** **The order has only digital items** */
  Digital = 'DIGITAL',
  /** **The order has only physical items** */
  Physical = 'PHYSICAL',
}

/** **Order tags** */
export type OrderTags = {
  __typename?: 'OrderTags';
  /** **Brand tags** */
  brand: Array<OrderTagBrand>;
  /** **Claim tags** */
  claim: Array<OrderTagClaim>;
  /** **Claim reason tags** */
  claimReason: Array<OrderTagClaimReason>;
  /** **Fulfillment tag** */
  fulfillment: OrderTagFulfillment;
  /** **Refund tag** */
  refund: OrderTagRefund;
  /** **Reship tag** */
  reship: OrderTagReship;
  /** **Return tags** */
  return: Array<OrderTagReturn>;
  /** **Shipment tags** */
  shipment: Array<OrderTagShipment>;
  /** **Shipping mode tag** */
  shippingMode: OrderTagShippingMode;
  /** **Shopify tag** */
  shopify: OrderTagShopify;
  /** **Status tag** */
  status: OrderTagStatus;
  /** **Type tag** */
  type: OrderTagType;
};

/** **Order updated** */
export type OrderUpdated = {
  __typename?: 'OrderUpdated';
  id: Scalars['ID']['output'];
};

/** **Order V2 (Order is already taken)** */
export type OrderV2 = {
  __typename?: 'OrderV2';
  /** **Billing ID** */
  billingId: Scalars['ID']['output'];
  /** **The list of the names of each sellers of the same billing as this order** */
  billingSellerNames?: Maybe<Array<Scalars['String']['output']>>;
  /** **The buyer is the person who has been billed for the order** */
  buyer: Buyer;
  /** **The user cashback for this order** */
  cashback?: Maybe<UserCashback>;
  /** **Claims** */
  claims: Array<Claim>;
  /** **Date of the creation** */
  createdAt: Scalars['DateTime']['output'];
  /** **Customer ID** */
  customerId: Scalars['ID']['output'];
  /** **Has been exported by the seller ?  (using a CSV from the dashboard in order to not process it twice)** */
  hasBeenExported: Scalars['Boolean']['output'];
  /** **Has seller error ?** */
  hasSellerError: Scalars['Boolean']['output'];
  /** **Has synchronization error ?** */
  hasSynchronizationError: Scalars['Boolean']['output'];
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Initial order if this is a reship order** */
  initialOrder?: Maybe<OrderV2>;
  /** **ID of the initial order if this is a reship order** */
  initialOrderId?: Maybe<Scalars['ID']['output']>;
  /** **Integration** */
  integration: OrderIntegration;
  /** **Is cancelled ?** */
  isCancelled: Scalars['Boolean']['output'];
  /** **Does the order include only digital items ?** */
  isFullDigital: Scalars['Boolean']['output'];
  /** **Is shipping estimated time exceeded ?** */
  isShippingEstimatedTimeExceeded: Scalars['Boolean']['output'];
  /** **Items ordered by the customer** */
  items: Array<Item>;
  /** **Lines refunds** */
  linesRefunds: Array<OrderLineRefund>;
  /** **Name of the choose B2B logistic manager** */
  logisticsManager: Scalars['String']['output'];
  /** **Operation type** */
  operationType: OperationType;
  /** **Parcels** */
  parcels: Array<Parcel>;
  /** **Payment infos** */
  payment: OrderPayment;
  /** **Rating** */
  rating?: Maybe<OrderRating>;
  /** **The recipient is the person who will receive the order** */
  recipient: Recipient;
  /** **Reship order if this is an initial order reshipped** */
  reshipOrder?: Maybe<OrderV2>;
  /** **ID of the reship order if this is an initial order reshipped** */
  reshipOrderId?: Maybe<Scalars['ID']['output']>;
  /** **Return parcels** */
  returnParcels: Array<ReturnParcel>;
  /** **Return slips** */
  returnSlips: Array<ReturnSlip>;
  /** **Sale ID** */
  saleId: Scalars['ID']['output'];
  /** **Seller who sold the product** */
  seller?: Maybe<Seller>;
  /** **Seller ID** */
  sellerId: Scalars['ID']['output'];
  /** **Shipping infos** */
  shipping: OrderShipping;
  /** **Split action error reasons if the action is not available** */
  splitActionErrorReasons: Array<OrderSplitActionErrorReason>;
  /** **Tags** */
  tags: OrderTags;
  /** **Total price sold (items) by Choose to the customer (in cents)** */
  totalPriceSold: Price;
};

/** **Parcel** */
export type Parcel = {
  __typename?: 'Parcel';
  /**
   * **Does the parcel has its tracking stucked or not ?**
   *
   * - only if more than 3 days in 'pending' for now
   */
  hasTrackingStucked: Scalars['Boolean']['output'];
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Items** */
  items: Array<Item>;
  /** **Recipient** */
  recipient: Recipient;
  /** **Tracking carrier slug** */
  trackingCarrierSlug: Scalars['String']['output'];
  /** **Date when the parcel was delivered** */
  trackingDeliveredAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Tracking number** */
  trackingNumber: Scalars['String']['output'];
  /** **Tracking status** */
  trackingStatus: TrackingStatus;
  /** **Tracking sub status** */
  trackingSubStatus?: Maybe<TrackingSubStatus>;
  /** **Date of the last tracking update** */
  trackingUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Tracking URL** */
  trackingUrl: Scalars['String']['output'];
};

/** **Payment processor** */
export enum PaymentProcessor {
  /** **Alma** */
  Alma = 'Alma',
  /** **Braintree** */
  Braintree = 'Braintree',
  /** **Choose** */
  Choose = 'Choose',
  /** **Primer** */
  Primer = 'Primer',
  /** **Stripe** */
  Stripe = 'Stripe',
}

/** **Payment Sub-processor - Stripe Specific ** */
export enum PaymentSubProcessor {
  /** **Bancontact** */
  Bancontact = 'Bancontact',
  /** **Klarna** */
  Klarna = 'Klarna',
  /** **PayPal** */
  PayPal = 'PayPal',
}

/** **Pending payment transaction** */
export type PendingPaymentTransaction = {
  __typename?: 'PendingPaymentTransaction';
  /** **Transaction amount in cents** */
  amount: Scalars['PositiveInteger']['output'];
  /** **Currency ISO code** */
  currency: CurrencyIso;
  /** **Date where transaction will be process** */
  dueDate: Scalars['DateTime']['output'];
};

/** **Pickup point address** */
export type PickupAddress = {
  __typename?: 'PickupAddress';
  /** **city** */
  city: Scalars['String']['output'];
  /** **Country code** */
  countryCode: Scalars['String']['output'];
  /** **name** */
  name: Scalars['String']['output'];
  /** **street** */
  street: Scalars['String']['output'];
  /** **streetAdditional** */
  streetAdditional?: Maybe<Scalars['String']['output']>;
  /** **zipCode** */
  zipCode: Scalars['String']['output'];
};

export type PickupOpeningDays = {
  __typename?: 'PickupOpeningDays';
  friday?: Maybe<Array<PickupOpeningHours>>;
  monday?: Maybe<Array<PickupOpeningHours>>;
  saturday?: Maybe<Array<PickupOpeningHours>>;
  sunday?: Maybe<Array<PickupOpeningHours>>;
  thursday?: Maybe<Array<PickupOpeningHours>>;
  tuesday?: Maybe<Array<PickupOpeningHours>>;
  wednesday?: Maybe<Array<PickupOpeningHours>>;
};

export type PickupOpeningHours = {
  __typename?: 'PickupOpeningHours';
  close: Scalars['String']['output'];
  open: Scalars['String']['output'];
};

/** **Pickup point** */
export type PickupPoint = {
  __typename?: 'PickupPoint';
  /** **Address** */
  address?: Maybe<PickupAddress>;
  /** **Country code** */
  countryCode: Scalars['String']['output'];
  /** **Id** */
  id: Scalars['ID']['output'];
  /** **Network** */
  network: PickupPointNetwork;
  /** **PickupOpeningDays** */
  openingDays: PickupOpeningDays;
};

/** **Pickup point network** */
export enum PickupPointNetwork {
  /** **Colissimo** */
  Colissimo = 'COLISSIMO',
  /** **Mondial relay** */
  MondialRelay = 'MONDIAL_RELAY',
  /** **Shop2Shop** */
  Shop2Shop = 'SHOP2SHOP',
}

export type PostPurchaseReferralInput = {
  code: Scalars['String']['input'];
  userKey: Scalars['String']['input'];
};

export type PostPurchaseReferralInputException = {
  __typename?: 'PostPurchaseReferralInputException';
  exceptionCode: PostPurchaseReferralInputExceptionCode;
};

export enum PostPurchaseReferralInputExceptionCode {
  CodeNotFound = 'code_not_found',
  UserAlreadyBenefitedReferral = 'user_already_benefited_referral',
}

export type PostPurchaseReferralPlan = {
  __typename?: 'PostPurchaseReferralPlan';
  code: Scalars['String']['output'];
  friend: ReferralCredit;
  me: ReferralCredit;
};

export type PostPurchaseReferralReturn =
  | PostPurchaseReferralInputException
  | PostPurchaseReferralPlan;

/** **Price** */
export type Price = {
  __typename?: 'Price';
  /** **Currency ISO code** */
  currency: CurrencyIso;
  /** **Value with VAT (in cents)** */
  valueWithVat: Scalars['Int']['output'];
  /** **Value without VAT (in cents)** */
  valueWithoutVat: Scalars['Int']['output'];
};

export type ProductInfo = {
  __typename?: 'ProductInfo';
  coupon_cgu?: Maybe<Array<Scalars['String']['output']>>;
  coupon_expire_at?: Maybe<Scalars['DateTime']['output']>;
  coupon_faq: Array<Maybe<Scalars['String']['output']>>;
  coupon_faq_after_order: Array<Maybe<Scalars['String']['output']>>;
  coupon_faq_before_order: Array<Maybe<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  is_coupon: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  not_returnable?: Maybe<Scalars['Boolean']['output']>;
  return_address?: Maybe<Scalars['Json']['output']>;
  /** @deprecated Only `return_address` used anymore */
  return_policy?: Maybe<Scalars['String']['output']>;
  /** @deprecated Only `return_address` used anymore */
  return_policy_address?: Maybe<Scalars['String']['output']>;
  sale_id?: Maybe<Scalars['String']['output']>;
};

export enum ProductType {
  Coupon = 'COUPON',
  Object = 'OBJECT',
}

export type Query = {
  __typename?: 'Query';
  /**
   * If a cashback is associated to the `orderId`, returns the current cashback
   * amount and the future amount if the order is cancelled.
   */
  cancelOrderIntent?: Maybe<CashbackUpdate>;
  conversationV2?: Maybe<ConversationV2>;
  /**
   * If a cashback is associated to the `orderId`, returns the current cashback
   * amount and the future amount if agent creates a claim associated to this
   * order.
   * If the claim is accepted, the order amount will decrease by `deltaAmount`.
   */
  createClaimIntent?: Maybe<CashbackUpdate>;
  createInvoice?: Maybe<Scalars['String']['output']>;
  /** **Get customer orders** */
  customerOrders: Array<OrderV2>;
  emails?: Maybe<Array<Maybe<ConversationEmail>>>;
  findUser: UsersElasticSearch;
  getCreditHistory: Array<CreditHistory>;
  /** **Gets the message group stats** */
  getMessageGroupStats: MessageGroupStats;
  getParrainData?: Maybe<Scalars['Json']['output']>;
  getPostPurchaseReferralPlan: PostPurchaseReferralReturn;
  getProductInfo?: Maybe<ProductInfo>;
  getReasonsToSendMessageToSegment: Array<Maybe<ReasonToSendMessageToSegment>>;
  getSegmentsInfos?: Maybe<Array<Maybe<Segment>>>;
  /** **Get url signed to upload return slip label** */
  getUploadReturnSlipLabelUrl: UploadInfo;
  inboxV2?: Maybe<InboxDataV2>;
  listOperators: Array<OperatorUser>;
  /** **Get order** */
  order: OrderV2;
  searchSalesES?: Maybe<SalesElasticSearch>;
  stats?: Maybe<Stats>;
  templates?: Maybe<TemplatesData>;
};

export type QueryCancelOrderIntentArgs = {
  orderId: Scalars['String']['input'];
};

export type QueryConversationV2Args = {
  key: Scalars['String']['input'];
};

export type QueryCreateClaimIntentArgs = {
  deltaAmount: Scalars['Int']['input'];
  orderId: Scalars['String']['input'];
};

export type QueryCreateInvoiceArgs = {
  billingId: Scalars['String']['input'];
  userKey: Scalars['String']['input'];
};

export type QueryCustomerOrdersArgs = {
  customerId: Scalars['ID']['input'];
};

export type QueryEmailsArgs = {
  email: Scalars['String']['input'];
};

export type QueryFindUserArgs = {
  search: Scalars['String']['input'];
};

export type QueryGetCreditHistoryArgs = {
  userKey: Scalars['String']['input'];
};

export type QueryGetMessageGroupStatsArgs = {
  orderIds: Array<Scalars['ID']['input']>;
  saleId: Scalars['ID']['input'];
};

export type QueryGetParrainDataArgs = {
  data: Scalars['Json']['input'];
};

export type QueryGetPostPurchaseReferralPlanArgs = {
  data: PostPurchaseReferralInput;
};

export type QueryGetProductInfoArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetUploadReturnSlipLabelUrlArgs = {
  orderId: Scalars['ID']['input'];
};

export type QueryInboxV2Args = {
  filter?: InputMaybe<MessageFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortType>;
};

export type QueryOrderArgs = {
  orderId: Scalars['ID']['input'];
};

export type QuerySearchSalesEsArgs = {
  name: Scalars['String']['input'];
};

export type ReasonToSendMessageToSegment = {
  __typename?: 'ReasonToSendMessageToSegment';
  id: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

/** **The recipient is the person who will receive the order** */
export type Recipient = {
  __typename?: 'Recipient';
  /** **Address** */
  address: AddressV2;
  /** **Email** */
  email: Scalars['String']['output'];
  /** **First name** */
  firstName: Scalars['String']['output'];
  /** **Full name ("first_name last_name")** */
  fullName: Scalars['String']['output'];
  /** **Identifier** */
  id: Scalars['ID']['output'];
  /** **Last name** */
  lastName: Scalars['String']['output'];
  /** **Phone number** */
  phoneNumber: Scalars['String']['output'];
  /** **Previous recipient if it has been updated** */
  previous?: Maybe<Recipient>;
};

export type Referral = {
  __typename?: 'Referral';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ReferralCredit = {
  __typename?: 'ReferralCredit';
  credit: Credit;
  name?: Maybe<Scalars['String']['output']>;
};

/** **Refund order cover input** */
export enum RefundOrderCoverInput {
  /** **Choose covers the refund** */
  Choose = 'CHOOSE',
  /** **The supplier covers the refund** */
  Supplier = 'SUPPLIER',
}

/** **Refund order info input** */
export type RefundOrderInfoInput = {
  /** **Party that covers the refund** */
  coveredBy?: InputMaybe<RefundOrderCoverInput>;
  /** **Internal note about the refund** */
  internalNote?: InputMaybe<Scalars['String']['input']>;
  /** **Reason of the refund** */
  reason?: InputMaybe<RefundOrderReasonInput>;
};

/** **Refund order items partially input** */
export type RefundOrderItemsPartiallyInput = {
  /** **Applies the refund intents if `true`** */
  apply: Scalars['Boolean']['input'];
  /** **Refund info** */
  refundInfo?: InputMaybe<RefundOrderInfoInput>;
  /** **Refund intents** */
  refundIntents: Array<OrderItemPartialRefundIntent>;
};

/** **Refund order reason input** */
export enum RefundOrderReasonInput {
  /** **A claim has the deadline exceeded** */
  ClaimDeadlineExceeded = 'CLAIM_DEADLINE_EXCEEDED',
  /** **A claim has been refused** */
  ClaimRefused = 'CLAIM_REFUSED',
  /** **Commercial gesture** */
  CommercialGesture = 'COMMERCIAL_GESTURE',
  /** **An item has a wrong image** */
  ItemWrongImage = 'ITEM_WRONG_IMAGE',
  /** **An item has a wrong price** */
  ItemWrongPrice = 'ITEM_WRONG_PRICE',
  /** **A return has the deadline exceeded** */
  ReturnDeadlineExceeded = 'RETURN_DEADLINE_EXCEEDED',
  /** **A return has been refused** */
  ReturnRefused = 'RETURN_REFUSED',
}

/** **Refund order shipping partially input** */
export type RefundOrderShippingPartiallyInput = {
  /** **Applies the refund intent if `true`** */
  apply: Scalars['Boolean']['input'];
  /** **Refund info** */
  refundInfo?: InputMaybe<RefundOrderInfoInput>;
  /** **Refund intent** */
  refundIntent: OrderShippingPartialRefundIntent;
};

/** **Refund order summary** */
export type RefundOrderSummary = {
  __typename?: 'RefundOrderSummary';
  /** **Amount of cash in cents simulated or applied** */
  cashAmountInCents: Scalars['PositiveInteger']['output'];
  /** **Amount of credit in cents simulated or applied** */
  creditAmountInCents: Scalars['PositiveInteger']['output'];
};

/** **ReturnParcel** */
export type ReturnParcel = {
  __typename?: 'ReturnParcel';
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Items** */
  items: Array<Item>;
  /** **Return slip id** */
  returnSlipId: Scalars['ID']['output'];
  /** **Tracking carrier slug** */
  trackingCarrierSlug: Scalars['String']['output'];
  /** **Date when the parcel was delivered** */
  trackingDeliveredAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Tracking number** */
  trackingNumber: Scalars['String']['output'];
  /** **Tracking status** */
  trackingStatus: TrackingStatus;
  /** **Date of the last tracking update** */
  trackingUpdatedAt: Scalars['DateTime']['output'];
  /** **Tracking URL** */
  trackingUrl: Scalars['String']['output'];
};

/** **Return slip** */
export type ReturnSlip = {
  __typename?: 'ReturnSlip';
  /** **Created at** */
  createdAt: Scalars['DateTime']['output'];
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Items** */
  items: Array<Item>;
  /** **Tracking carrier slug** */
  trackingCarrierSlug: Scalars['String']['output'];
  /** **Tracking number** */
  trackingNumber: Scalars['String']['output'];
  /** **Tracking URL** */
  trackingUrl: Scalars['String']['output'];
};

export type SalesElasticSearch = {
  __typename?: 'SalesElasticSearch';
  hits?: Maybe<Array<Maybe<SalesElasticSearchHit>>>;
  max_score?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SalesElasticSearchHit = {
  __typename?: 'SalesElasticSearchHit';
  _id?: Maybe<Scalars['String']['output']>;
  _score?: Maybe<Scalars['Float']['output']>;
  _source?: Maybe<Scalars['Json']['output']>;
  highlight?: Maybe<Scalars['Json']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Segment = {
  __typename?: 'Segment';
  group?: Maybe<Scalars['String']['output']>;
  id: SegmentType;
  label: Scalars['String']['output'];
  statsBySale?: Maybe<SegmentStatsForSale>;
};

export type SegmentStatsBySaleArgs = {
  saleId: Scalars['String']['input'];
};

export type SegmentStatsForSale = {
  __typename?: 'SegmentStatsForSale';
  nbCustomers: Scalars['Int']['output'];
};

export enum SegmentType {
  AllOrdersNotTracked = 'all_orders_not_tracked',
  AllOrdersOfSale = 'all_orders_of_sale',
  AllOrdersTrackedAndNotDelivered = 'all_orders_tracked_and_not_delivered',
  ImportCsv = 'import_csv',
}

/** **Seller** */
export type Seller = {
  __typename?: 'Seller';
  /** **Contact email** */
  contactEmail?: Maybe<Scalars['String']['output']>;
  /** **Contact name** */
  contactName?: Maybe<Scalars['String']['output']>;
  /** **Contact phone** */
  contactPhone?: Maybe<Scalars['String']['output']>;
  /** **Hubspot id** */
  hubspotId?: Maybe<Scalars['String']['output']>;
  /** **Id** */
  id: Scalars['ID']['output'];
  /** **Is french ?** */
  isFrench?: Maybe<Scalars['Boolean']['output']>;
  /** **Name** */
  name?: Maybe<Scalars['String']['output']>;
  /** **Return address** */
  returnAddress?: Maybe<SellerAddress>;
  /** **Website** */
  website?: Maybe<Scalars['String']['output']>;
};

/** **Seller address** */
export type SellerAddress = {
  __typename?: 'SellerAddress';
  /** **City** */
  city: Scalars['String']['output'];
  /** **Country** */
  country: Scalars['String']['output'];
  /** **Country code** */
  countryCode: Scalars['String']['output'];
  /** **County** */
  county?: Maybe<Scalars['String']['output']>;
  /** **Name** */
  name: Scalars['String']['output'];
  /** **Street** */
  street: Scalars['String']['output'];
  /** **Street additional** */
  streetAdditional?: Maybe<Scalars['String']['output']>;
  /** **Zip code** */
  zipCode: Scalars['String']['output'];
};

/** **Shipping address input** */
export type ShippingAddressInput = {
  /** **City** */
  city: Scalars['String']['input'];
  /** **Country code** */
  countryCode: Scalars['String']['input'];
  /** **County** */
  county?: InputMaybe<Scalars['String']['input']>;
  /** **Firstname** */
  firstname: Scalars['String']['input'];
  /** **Lastname** */
  lastname: Scalars['String']['input'];
  /** **Phone number** */
  phoneNumber: Scalars['String']['input'];
  /** **Street** */
  street: Scalars['String']['input'];
  /** **Street additional** */
  streetAdditional?: InputMaybe<Scalars['String']['input']>;
  /** **Zip code** */
  zipCode: Scalars['String']['input'];
};

export enum ShippingDeliveryType {
  All = 'ALL',
  NotDelivered = 'NOT_DELIVERED',
  Waiting = 'WAITING',
}

/** **Shopify order integration** */
export type ShopifyOrderIntegration = {
  __typename?: 'ShopifyOrderIntegration';
  /** **ID of the order from Shopify** */
  orderId: Scalars['ID']['output'];
  /** **URL of the order from Shopify** */
  orderUrl: Scalars['String']['output'];
};

export enum SortType {
  AiScoring = 'AI_SCORING',
  AiUrgency = 'AI_URGENCY',
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Stats = {
  __typename?: 'Stats';
  assignOperatorRepartition?: Maybe<Array<OperatorStats>>;
  categoryBotProcessing?: Maybe<Scalars['Int']['output']>;
  categoryDelivery?: Maybe<Scalars['Int']['output']>;
  categoryDeliveryDelayed?: Maybe<Scalars['Int']['output']>;
  categoryOrder?: Maybe<Scalars['Int']['output']>;
  categoryPostOperation?: Maybe<Scalars['Int']['output']>;
  categoryPreSales?: Maybe<Scalars['Int']['output']>;
  pinned?: Maybe<Scalars['Int']['output']>;
  unassigned?: Maybe<Scalars['Int']['output']>;
  unhandled?: Maybe<Scalars['Int']['output']>;
  usa?: Maybe<Scalars['Int']['output']>;
};

export enum StoreCurrency {
  Eur = 'eur',
  Usd = 'usd',
}

export enum StoreRegion {
  Fr = 'fr',
  Us = 'us',
}

export type Subscription = {
  __typename?: 'Subscription';
  creditChanged?: Maybe<Scalars['Int']['output']>;
  messageChanged?: Maybe<MessageChanged>;
  newMessage?: Maybe<MessageAdded>;
  newOperatorReplying: Array<Maybe<OperatorActive>>;
  operatorReplying?: Maybe<OperatorReplying>;
  /** **Order updated** */
  orderUpdated: OrderUpdated;
  refreshStats?: Maybe<Stats>;
  removedMessage?: Maybe<MessageChanged>;
};

export type SubscriptionCreditChangedArgs = {
  userKey: Scalars['String']['input'];
};

export type SubscriptionOperatorReplyingArgs = {
  status: MessageStatus;
};

export type SubscriptionOrderUpdatedArgs = {
  customerId: Scalars['ID']['input'];
};

export type TagHistory = {
  __typename?: 'TagHistory';
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  operator?: Maybe<Scalars['String']['output']>;
  userKey?: Maybe<Scalars['String']['output']>;
};

export type TemplateData = {
  __typename?: 'TemplateData';
  category?: Maybe<Scalars['Int']['output']>;
  category_name?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TemplateInput = {
  category: Scalars['Int']['input'];
  key: Scalars['String']['input'];
  message: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type TemplatesData = {
  __typename?: 'TemplatesData';
  categories?: Maybe<Array<Maybe<CategoryData>>>;
  templates?: Maybe<Array<Maybe<TemplateData>>>;
};

/** **Tracking status** */
export enum TrackingStatus {
  /** **Attempt fail** */
  AttemptFail = 'ATTEMPT_FAIL',
  /** **Available for pickup** */
  AvailableForPickup = 'AVAILABLE_FOR_PICKUP',
  /** **Delivered** */
  Delivered = 'DELIVERED',
  /** **Exception** */
  Exception = 'EXCEPTION',
  /** **Expired** */
  Expired = 'EXPIRED',
  /** **Info received** */
  InfoReceived = 'INFO_RECEIVED',
  /** **In transit** */
  InTransit = 'IN_TRANSIT',
  /** **Out for delivery** */
  OutForDelivery = 'OUT_FOR_DELIVERY',
  /** **Pending** */
  Pending = 'PENDING',
}

/** **Tracking sub status** */
export enum TrackingSubStatus {
  /** **Business is closed at the time of delivery (AttemptFail_003)** */
  AttemptFailBusinessClosed = 'ATTEMPT_FAIL_BUSINESS_CLOSED',
  /** **The delivery of the package failed due to some reason. Courier usually leaves a notice and will try to deliver again (AttemptFail_001)** */
  AttemptFailDueToSomeReason = 'ATTEMPT_FAIL_DUE_TO_SOME_REASON',
  /** **Recipient not available at the given address (AttemptFail_002)** */
  AttemptFailRecipientNotAvailable = 'ATTEMPT_FAIL_RECIPIENT_NOT_AVAILABLE',
  /** **The package arrived at a pickup point near you and is available for pickup (AvailableForPickup_001)** */
  AvailableForPickup = 'AVAILABLE_FOR_PICKUP',
  /** **Package delivered to the recipient and cash collected on delivery (Delivered_004)** */
  DeliveredAndCashCollected = 'DELIVERED_AND_CASH_COLLECTED',
  /** **Package delivered to and signed by the recipient (Delivered_003)** */
  DeliveredAndSignedByRecipient = 'DELIVERED_AND_SIGNED_BY_RECIPIENT',
  /** **CUSTOM: Marked delivered by a bot (Delivered_Mark_By_Bot)** */
  DeliveredMarkedByBot = 'DELIVERED_MARKED_BY_BOT',
  /** **CUSTOM: Marked delivered by the customer (Delivered_Mark_By_Customer)** */
  DeliveredMarkedByCustomer = 'DELIVERED_MARKED_BY_CUSTOMER',
  /** **CUSTOM: Marked delivered by a member of the support team (Delivered_Mark_By_Support)** */
  DeliveredMarkedBySupportTeam = 'DELIVERED_MARKED_BY_SUPPORT_TEAM',
  /** **Package picked up by the recipient (Delivered_002)** */
  DeliveredPickedUpByRecipient = 'DELIVERED_PICKED_UP_BY_RECIPIENT',
  /** **Shipment delivered successfully (Delivered_001)** */
  DeliveredSuccessfully = 'DELIVERED_SUCCESSFULLY',
  /** **The package is on its way back to the sender (Exception_010)** */
  ExceptionBackToSender = 'EXCEPTION_BACK_TO_SENDER',
  /** **The return package has been successfully received by the sender (Exception_011)** */
  ExceptionBackToSenderReceived = 'EXCEPTION_BACK_TO_SENDER_RECEIVED',
  /** **Shipment damaged (Exception_012)** */
  ExceptionDamaged = 'EXCEPTION_DAMAGED',
  /** **Package delayed due to some issues during the customs clearance (Exception_004)** */
  ExceptionDelayedCustomsClearance = 'EXCEPTION_DELAYED_CUSTOMS_CLEARANCE',
  /** **Package not delivered due to incorrect recipient address (Exception_007)** */
  ExceptionIncorrectRecipientAddress = 'EXCEPTION_INCORRECT_RECIPIENT_ADDRESS',
  /** **Delivery of the package failed as it got lost (Exception_013)** */
  ExceptionLost = 'EXCEPTION_LOST',
  /** **The package being held due to pending payment from the recipient's end (Exception_006)** */
  ExceptionPendingPayment = 'EXCEPTION_PENDING_PAYMENT',
  /** **Package available for the pickup but not collected by the recipient (Exception_008)** */
  ExceptionPickupNotCollectedByRecipient = 'EXCEPTION_PICKUP_NOT_COLLECTED_BY_RECIPIENT',
  /** **Delivery of the package failed as the recipient refused to take the package due to some reason (Exception_003)** */
  ExceptionRecipientRefused = 'EXCEPTION_RECIPIENT_REFUSED',
  /** **Delivery of the package failed as the recipient relocated (Exception_002)** */
  ExceptionRecipientRelocated = 'EXCEPTION_RECIPIENT_RELOCATED',
  /** **Package rejected by the carrier due to noncompliance with its guidelines (Exception_009)** */
  ExceptionRejectedByCarrier = 'EXCEPTION_REJECTED_BY_CARRIER',
  /** **Delivery of the package failed due to some shipping exception (Exception_001)** */
  ExceptionShippingException = 'EXCEPTION_SHIPPING_EXCEPTION',
  /** **Package delayed due to some unforeseen reasons (Exception_005)** */
  ExceptionUnforseenReason = 'EXCEPTION_UNFORSEEN_REASON',
  /** **No tracking information of the shipment, from the last 30 days (Expired_001)** */
  ExpiredNoTrackingInformation = 'EXPIRED_NO_TRACKING_INFORMATION',
  /** **The carrier received a request from the shipper and is about to pick up the shipment (InfoReceived_001)** */
  InfoReceivedAboutToPickup = 'INFO_RECEIVED_ABOUT_TO_PICKUP',
  /** **Shipment accepted by the carrier (InTransit_002)** */
  InTransitAcceptedByCarrier = 'IN_TRANSIT_ACCEPTED_BY_CARRIER',
  /** **Shipment arrived at a hub or sorting center (InTransit_003)** */
  InTransitArrivedAtAHubOrSortingCenter = 'IN_TRANSIT_ARRIVED_AT_A_HUB_OR_SORTING_CENTER',
  /** **International shipment arrived at the destination country / region (InTransit_004)** */
  InTransitArrivedAtDestinationCountryOrRegion = 'IN_TRANSIT_ARRIVED_AT_DESTINATION_COUNTRY_OR_REGION',
  /** **Customs clearance completed (InTransit_005)** */
  InTransitCustomsClearanceCompleted = 'IN_TRANSIT_CUSTOMS_CLEARANCE_COMPLETED',
  /** **Package departed from the facility (InTransit_007)** */
  InTransitDepartedFromFacility = 'IN_TRANSIT_DEPARTED_FROM_FACILITY',
  /** **Shipment forwarded to a different delivery address (InTransit_009)** */
  InTransitForwardedToADifferentDeliveryAddress = 'IN_TRANSIT_FORWARDED_TO_A_DIFFERENT_DELIVERY_ADDRESS',
  /** **Package handed over to customs for clearance (InTransit_006)** */
  InTransitHandedOverToCustomsForClearance = 'IN_TRANSIT_HANDED_OVER_TO_CUSTOMS_FOR_CLEARANCE',
  /** **Shipment on the way (InTransit_001)** */
  InTransitOnTheWay = 'IN_TRANSIT_ON_THE_WAY',
  /** **Problem resolved and shipment in transit (InTransit_008)** */
  InTransitProblemResolved = 'IN_TRANSIT_PROBLEM_RESOLVED',
  /** **The package is out for delivery (OutForDelivery_001)** */
  OutForDelivery = 'OUT_FOR_DELIVERY',
  /** **A delivery appointment is scheduled (OutForDelivery_004)** */
  OutForDeliveryAppointmentScheduled = 'OUT_FOR_DELIVERY_APPOINTMENT_SCHEDULED',
  /** **The recipient is contacted before the final delivery (OutForDelivery_003)** */
  OutForDeliveryRecipientContacted = 'OUT_FOR_DELIVERY_RECIPIENT_CONTACTED',
  /** **Can’t track this type of shipment as the carrier is unrecognized (Pending_006)** */
  PendingCarrierUnrecognized = 'PENDING_CARRIER_UNRECOGNIZED',
  /** **The order has been processed / packaged, but not scanned at a shipping location yet (Pending_003)** */
  PendingNotScanned = 'PENDING_NOT_SCANNED',
  /** **It represents the shipments are pending due to no connection with carrier accounts (Pending_002)** */
  PendingNoConnectionWithCarrier = 'PENDING_NO_CONNECTION_WITH_CARRIER',
  /** **No information available on the carrier website or the tracking number is yet to be tracked (Pending_001)** */
  PendingNoInformationAvailable = 'PENDING_NO_INFORMATION_AVAILABLE',
  /** **There have been no new tracking updates in the last 120 days (Pending_005)** */
  PendingNoUpdate = 'PENDING_NO_UPDATE',
  /** **There is no tracking info available because the carrier is wrong (Pending_004)** */
  PendingWrongCarrier = 'PENDING_WRONG_CARRIER',
}

export type UploadInfo = {
  __typename?: 'UploadInfo';
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  accountProvider?: Maybe<Scalars['String']['output']>;
  addresses?: Maybe<Scalars['Json']['output']>;
  appVersion?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  credits: Array<Credit>;
  currentStore?: Maybe<StoreRegion>;
  device?: Maybe<Scalars['Json']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fcmToken?: Maybe<Scalars['String']['output']>;
  has_latest_version?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  language?: Maybe<Scalars['String']['output']>;
  lastTime?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  referral?: Maybe<Referral>;
  referralCode?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  shippingAddressId?: Maybe<Scalars['String']['output']>;
  support_note?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userKey: Scalars['String']['output'];
};

/** **User cashback** */
export type UserCashback = {
  __typename?: 'UserCashback';
  /** **Amount** */
  amount: Scalars['Int']['output'];
  /** **Date of last amount update (null if amount has never been updated)** */
  amountUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Currency** */
  currency: StoreCurrency;
  /** **Transformation into credit date (can be past value depending on status)** */
  dueDate: Scalars['DateTime']['output'];
  /** **Status** */
  status: CashbackStatus;
};

export type UserElasticSearchHit = {
  __typename?: 'UserElasticSearchHit';
  _id?: Maybe<Scalars['String']['output']>;
  _score?: Maybe<Scalars['Float']['output']>;
  _source?: Maybe<Scalars['Json']['output']>;
  highlight?: Maybe<Scalars['Json']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type UsersElasticSearch = {
  __typename?: 'UsersElasticSearch';
  hits?: Maybe<Array<Maybe<UserElasticSearchHit>>>;
  max_score?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type InboxV2QueryVariables = Exact<{
  filter?: InputMaybe<MessageFilter>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortType>;
}>;

export type InboxV2Query = {
  __typename?: 'Query';
  inboxV2?: {
    __typename?: 'InboxDataV2';
    messages: Array<{
      __typename?: 'Message';
      userKey?: string | null;
      name?: string | null;
      body?: string | null;
      createdAt?: string | null;
      total_orders?: number | null;
      store: StoreRegion;
      assignTo?: string | null;
      aiSentiment?: string | null;
      aiNeedResponse?: boolean | null;
      aiPriorityScore?: number | null;
    }>;
    emails: Array<{
      __typename?: 'Email';
      email?: string | null;
      createdAt?: string | null;
      body?: string | null;
      fromChoose?: boolean | null;
    }>;
  } | null;
};

export type FindUserQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;

export type FindUserQuery = {
  __typename?: 'Query';
  findUser: {
    __typename?: 'UsersElasticSearch';
    total?: number | null;
    hits?: Array<{
      __typename?: 'UserElasticSearchHit';
      _score?: number | null;
      highlight?: any | null;
      _source?: any | null;
      _id?: string | null;
    } | null> | null;
  };
};

export type CancelOrderIntentQueryVariables = Exact<{
  orderId: Scalars['String']['input'];
}>;

export type CancelOrderIntentQuery = {
  __typename?: 'Query';
  cancelOrderIntent?: {
    __typename?: 'CashbackUpdate';
    currentAmount: number;
    postActionAmount: number;
  } | null;
};

export type CreateClaimIntentQueryVariables = Exact<{
  orderId: Scalars['String']['input'];
  deltaAmount: Scalars['Int']['input'];
}>;

export type CreateClaimIntentQuery = {
  __typename?: 'Query';
  createClaimIntent?: {
    __typename?: 'CashbackUpdate';
    currentAmount: number;
    postActionAmount: number;
  } | null;
};

export type GetConversationEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type GetConversationEmailQuery = {
  __typename?: 'Query';
  emails?: Array<{
    __typename?: 'ConversationEmail';
    body?: string | null;
    fromChoose?: boolean | null;
    email?: string | null;
    createdAt?: string | null;
    isFixed?: boolean | null;
    isPinned?: boolean | null;
  } | null> | null;
};

export type GetConversationV2QueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;

export type GetConversationV2Query = {
  __typename?: 'Query';
  conversationV2?: {
    __typename?: 'ConversationV2';
    messages: Array<
      | {
          __typename: 'ConversationMessage';
          id: number;
          userKey: string;
          chooseReply?: boolean | null;
          body: string;
          createdAt: string;
          readAt?: string | null;
          operator?: string | null;
          file?: string | null;
          topic?: number | null;
          version?: string | null;
          versionCode?: string | null;
          fromPage?: string | null;
          fromEmail?: string | null;
          emailSubject?: string | null;
          versionCodePush?: any | null;
          score?: number | null;
          network?: any | null;
          photo?: string | null;
          category?: string | null;
          isPinned?: boolean | null;
          products?: any | null;
          isFixed?: boolean | null;
          orderId?: string | null;
          isBot?: boolean | null;
          extraData?: {
            __typename?: 'ExtraData';
            image?: string | null;
            name?: string | null;
            brand_name?: string | null;
            id?: string | null;
          } | null;
          orderInfos?: {
            __typename?: 'OrderInfos';
            key?: string | null;
            brand_name?: string | null;
            products?: any | null;
          } | null;
        }
      | {
          __typename: 'ConversationMessageGroup';
          id: number;
          userKey: string;
          body: string;
          reason: string;
          isFromChoose: boolean;
          createdAt: string;
          readAt?: string | null;
          operator?: string | null;
          files: Array<string>;
          photos: Array<string>;
          fromPage?: string | null;
          fromEmail?: string | null;
          order: {
            __typename?: 'ConversationMessageGroupOrder';
            id: string;
            brandName: string;
            willBeOpenedByBotAt?: string | null;
            itemsConcern?: Array<{
              __typename?: 'ConversationMessageGroupItemConcern';
              id: string;
              productId: string;
              variantId: string;
              description: string;
              image?: string | null;
              options: Array<string>;
            }> | null;
          };
        }
    >;
    user?: {
      __typename?: 'User';
      id: number;
      userKey: string;
      name?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      timezone?: string | null;
      currentStore?: StoreRegion | null;
      language?: string | null;
      support_note?: string | null;
      profilePicture?: string | null;
      addresses?: any | null;
      referralCode?: string | null;
      device?: any | null;
      appVersion?: string | null;
      has_latest_version?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      lastTime?: string | null;
      credits: Array<{
        __typename?: 'Credit';
        value: number;
        currency: StoreCurrency;
      }>;
      referral?: {
        __typename?: 'Referral';
        created_at?: string | null;
        name?: string | null;
      } | null;
    } | null;
    status: {
      __typename?: 'ConversationStatus';
      assignTo?: string | null;
      isFixed?: boolean | null;
      isPinned?: boolean | null;
      category?: ConversationCategory | null;
    };
  } | null;
};

export type StatsQueryVariables = Exact<{ [key: string]: never }>;

export type StatsQuery = {
  __typename?: 'Query';
  stats?: {
    __typename?: 'Stats';
    unhandled?: number | null;
    pinned?: number | null;
    unassigned?: number | null;
    usa?: number | null;
    categoryBotProcessing?: number | null;
    categoryDelivery?: number | null;
    categoryOrder?: number | null;
    categoryPostOperation?: number | null;
    categoryPreSales?: number | null;
    assignOperatorRepartition?: Array<{
      __typename?: 'OperatorStats';
      operatorId: string;
      nbConversationAssigned: number;
    }> | null;
  } | null;
};

export type TemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type TemplatesQuery = {
  __typename?: 'Query';
  templates?: {
    __typename?: 'TemplatesData';
    categories?: Array<{
      __typename?: 'CategoryData';
      id?: number | null;
      name?: string | null;
    } | null> | null;
    templates?: Array<{
      __typename?: 'TemplateData';
      key?: string | null;
      category?: number | null;
      title?: string | null;
      message?: string | null;
      category_name?: string | null;
    } | null> | null;
  } | null;
};

export type ListOperatorsQueryVariables = Exact<{ [key: string]: never }>;

export type ListOperatorsQuery = {
  __typename?: 'Query';
  listOperators: Array<{
    __typename?: 'OperatorUser';
    operatorId: string;
    displayName?: string | null;
    email?: string | null;
    image?: string | null;
  }>;
};

export type GetReasonsToSendMessageToSegmentQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetReasonsToSendMessageToSegmentQuery = {
  __typename?: 'Query';
  getReasonsToSendMessageToSegment: Array<{
    __typename?: 'ReasonToSendMessageToSegment';
    id: string;
    reason: string;
  } | null>;
};

export type GetSegmentsInfosQueryVariables = Exact<{
  saleId: Scalars['String']['input'];
}>;

export type GetSegmentsInfosQuery = {
  __typename?: 'Query';
  getSegmentsInfos?: Array<{
    __typename?: 'Segment';
    id: SegmentType;
    label: string;
    group?: string | null;
    statsBySale?: {
      __typename?: 'SegmentStatsForSale';
      nbCustomers: number;
    } | null;
  } | null> | null;
};

export type SearchSalesEsQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type SearchSalesEsQuery = {
  __typename?: 'Query';
  searchSalesES?: {
    __typename?: 'SalesElasticSearch';
    total?: number | null;
    max_score?: number | null;
    hits?: Array<{
      __typename?: 'SalesElasticSearchHit';
      highlight?: any | null;
    } | null> | null;
  } | null;
};

export type GetCreditHistoryQueryVariables = Exact<{
  userKey: Scalars['String']['input'];
}>;

export type GetCreditHistoryQuery = {
  __typename?: 'Query';
  getCreditHistory: Array<{
    __typename?: 'CreditHistory';
    userKey: string;
    operator?: string | null;
    createdAt?: string | null;
    description?: string | null;
    credit: { __typename?: 'Credit'; value: number; currency: StoreCurrency };
  }>;
};

export type OrderFieldsFragment = {
  __typename?: 'OrderV2';
  id: string;
  billingId: string;
  sellerId: string;
  saleId: string;
  initialOrderId?: string | null;
  reshipOrderId?: string | null;
  operationType: OperationType;
  hasBeenExported: boolean;
  isCancelled: boolean;
  isFullDigital: boolean;
  createdAt: string;
  billingSellerNames?: Array<string> | null;
  logisticsManager: string;
  splitActionErrorReasons: Array<OrderSplitActionErrorReason>;
  buyer: {
    __typename?: 'Buyer';
    fullName: string;
    firstName: string;
    lastName: string;
    address: {
      __typename?: 'AddressV2';
      street: string;
      streetAdditional?: string | null;
      zipCode: string;
      city: string;
      county?: string | null;
      country: string;
      countryCode: string;
    };
    previous?: {
      __typename?: 'Buyer';
      fullName: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  recipient: {
    __typename?: 'Recipient';
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address: {
      __typename?: 'AddressV2';
      street: string;
      streetAdditional?: string | null;
      zipCode: string;
      city: string;
      county?: string | null;
      country: string;
      countryCode: string;
    };
  };
  shipping: {
    __typename?: 'OrderShipping';
    fees: number;
    feesSold: number;
    policyId?: string | null;
    deliveryDateRangeHistory: Array<{
      __typename?: 'DeliveryDateRange';
      start: string;
      end: string;
    }>;
    refundSummary: {
      __typename?: 'OrderLineRefundSummary';
      isRefundable: boolean;
      isTotallyRefunded: boolean;
      refundedAmountInCents: number;
      refundedAmountInPercentage: number;
      refundedAmountInPercentageCoveredByChoose: number;
      refundedAmountInPercentageCoveredBySupplier: number;
      remainingToRefundAmountInCents: number;
      remainingToRefundAmountInPercentage: number;
    };
    pickupPoint?: {
      __typename?: 'PickupPoint';
      id: string;
      countryCode: string;
      network: PickupPointNetwork;
      address?: {
        __typename?: 'PickupAddress';
        name: string;
        street: string;
        streetAdditional?: string | null;
        zipCode: string;
        city: string;
        countryCode: string;
      } | null;
      openingDays: {
        __typename?: 'PickupOpeningDays';
        monday?: Array<{
          __typename?: 'PickupOpeningHours';
          open: string;
          close: string;
        }> | null;
        tuesday?: Array<{
          __typename?: 'PickupOpeningHours';
          open: string;
          close: string;
        }> | null;
        wednesday?: Array<{
          __typename?: 'PickupOpeningHours';
          open: string;
          close: string;
        }> | null;
        thursday?: Array<{
          __typename?: 'PickupOpeningHours';
          open: string;
          close: string;
        }> | null;
        friday?: Array<{
          __typename?: 'PickupOpeningHours';
          open: string;
          close: string;
        }> | null;
        saturday?: Array<{
          __typename?: 'PickupOpeningHours';
          open: string;
          close: string;
        }> | null;
        sunday?: Array<{
          __typename?: 'PickupOpeningHours';
          open: string;
          close: string;
        }> | null;
      };
    } | null;
  };
  payment: {
    __typename?: 'OrderPayment';
    processor: PaymentProcessor;
    subProcessor?: PaymentSubProcessor | null;
    processorTransactionUrl: string;
    transactionPlan: {
      __typename?: 'OrderPaymentPlan';
      pendingTransactions: Array<{
        __typename?: 'PendingPaymentTransaction';
        dueDate: string;
        amount: number;
        currency: CurrencyIso;
      }>;
    };
  };
  totalPriceSold: {
    __typename?: 'Price';
    valueWithVat: number;
    valueWithoutVat: number;
    currency: CurrencyIso;
  };
  items: Array<{
    __typename?: 'Item';
    id: string;
    productId: string;
    productVariantId?: string | null;
    name: string;
    imageUrl: string;
    size?: string | null;
    sku?: string | null;
    gtin?: string | null;
    isDigital: boolean;
    digitalCouponCode?: string | null;
    newDigitalCouponCode?: string | null;
    isReturnable: boolean;
    isReturnGenerated: boolean;
    isOffered: boolean;
    isCancellable: boolean;
    isCancelled: boolean;
    priceSold: {
      __typename?: 'Price';
      valueWithVat: number;
      valueWithoutVat: number;
      currency: CurrencyIso;
    };
    priceBoughtByChoose: {
      __typename?: 'Price';
      valueWithVat: number;
      valueWithoutVat: number;
      currency: CurrencyIso;
    };
    refundSummary: {
      __typename?: 'OrderLineRefundSummary';
      isRefundable: boolean;
      isTotallyRefunded: boolean;
      refundedAmountInCents: number;
      refundedAmountInPercentage: number;
      refundedAmountInPercentageCoveredByChoose: number;
      refundedAmountInPercentageCoveredBySupplier: number;
      remainingToRefundAmountInCents: number;
      remainingToRefundAmountInPercentage: number;
    };
  }>;
  parcels: Array<{
    __typename?: 'Parcel';
    hasTrackingStucked: boolean;
    id: string;
    trackingCarrierSlug: string;
    trackingNumber: string;
    trackingStatus: TrackingStatus;
    trackingSubStatus?: TrackingSubStatus | null;
    trackingUrl: string;
    trackingUpdatedAt?: string | null;
  }>;
  returnSlips: Array<{
    __typename?: 'ReturnSlip';
    id: string;
    trackingCarrierSlug: string;
    trackingNumber: string;
    trackingUrl: string;
    items: Array<{
      __typename?: 'Item';
      id: string;
      productId: string;
      productVariantId?: string | null;
      name: string;
      imageUrl: string;
      size?: string | null;
      isDigital: boolean;
      digitalCouponCode?: string | null;
      newDigitalCouponCode?: string | null;
      priceSold: {
        __typename?: 'Price';
        currency: CurrencyIso;
        valueWithVat: number;
        valueWithoutVat: number;
      };
    }>;
  }>;
  returnParcels: Array<{
    __typename?: 'ReturnParcel';
    id: string;
    trackingCarrierSlug: string;
    trackingNumber: string;
    trackingStatus: TrackingStatus;
    trackingUrl: string;
    trackingUpdatedAt: string;
    items: Array<{
      __typename?: 'Item';
      id: string;
      productId: string;
      productVariantId?: string | null;
      name: string;
      imageUrl: string;
      size?: string | null;
      isDigital: boolean;
      digitalCouponCode?: string | null;
      newDigitalCouponCode?: string | null;
      priceSold: {
        __typename?: 'Price';
        currency: CurrencyIso;
        valueWithVat: number;
        valueWithoutVat: number;
      };
    }>;
  }>;
  claims: Array<{
    __typename?: 'Claim';
    id: string;
    reason: ClaimReason;
    otherReasonLabel?: string | null;
    messageFromSupport?: string | null;
    actionFromSeller?: ClaimSellerAction | null;
    proofUrls: Array<string>;
    isAccepted: boolean;
    acceptedMessage?: string | null;
    isDeclined: boolean;
    declinedMessage?: string | null;
    createdAt: string;
    updatedAt: string;
    isOpenedByBot: boolean;
  }>;
  tags: {
    __typename?: 'OrderTags';
    claim: Array<OrderTagClaim>;
    fulfillment: OrderTagFulfillment;
    refund: OrderTagRefund;
    reship: OrderTagReship;
    return: Array<OrderTagReturn>;
    shipment: Array<OrderTagShipment>;
  };
  seller?: {
    __typename?: 'Seller';
    id: string;
    name?: string | null;
    hubspotId?: string | null;
    website?: string | null;
    isFrench?: boolean | null;
    contactEmail?: string | null;
    contactPhone?: string | null;
    contactName?: string | null;
    returnAddress?: {
      __typename?: 'SellerAddress';
      name: string;
      street: string;
      streetAdditional?: string | null;
      zipCode: string;
      city: string;
      county?: string | null;
      country: string;
      countryCode: string;
    } | null;
  } | null;
  cashback?: {
    __typename?: 'UserCashback';
    amount: number;
    amountUpdatedAt?: string | null;
    dueDate: string;
    status: CashbackStatus;
    currency: StoreCurrency;
  } | null;
};

export type OrderQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type OrderQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'OrderV2';
    id: string;
    billingId: string;
    sellerId: string;
    saleId: string;
    initialOrderId?: string | null;
    reshipOrderId?: string | null;
    operationType: OperationType;
    hasBeenExported: boolean;
    isCancelled: boolean;
    isFullDigital: boolean;
    createdAt: string;
    billingSellerNames?: Array<string> | null;
    logisticsManager: string;
    splitActionErrorReasons: Array<OrderSplitActionErrorReason>;
    buyer: {
      __typename?: 'Buyer';
      fullName: string;
      firstName: string;
      lastName: string;
      address: {
        __typename?: 'AddressV2';
        street: string;
        streetAdditional?: string | null;
        zipCode: string;
        city: string;
        county?: string | null;
        country: string;
        countryCode: string;
      };
      previous?: {
        __typename?: 'Buyer';
        fullName: string;
        firstName: string;
        lastName: string;
      } | null;
    };
    recipient: {
      __typename?: 'Recipient';
      fullName: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
      address: {
        __typename?: 'AddressV2';
        street: string;
        streetAdditional?: string | null;
        zipCode: string;
        city: string;
        county?: string | null;
        country: string;
        countryCode: string;
      };
    };
    shipping: {
      __typename?: 'OrderShipping';
      fees: number;
      feesSold: number;
      policyId?: string | null;
      deliveryDateRangeHistory: Array<{
        __typename?: 'DeliveryDateRange';
        start: string;
        end: string;
      }>;
      refundSummary: {
        __typename?: 'OrderLineRefundSummary';
        isRefundable: boolean;
        isTotallyRefunded: boolean;
        refundedAmountInCents: number;
        refundedAmountInPercentage: number;
        refundedAmountInPercentageCoveredByChoose: number;
        refundedAmountInPercentageCoveredBySupplier: number;
        remainingToRefundAmountInCents: number;
        remainingToRefundAmountInPercentage: number;
      };
      pickupPoint?: {
        __typename?: 'PickupPoint';
        id: string;
        countryCode: string;
        network: PickupPointNetwork;
        address?: {
          __typename?: 'PickupAddress';
          name: string;
          street: string;
          streetAdditional?: string | null;
          zipCode: string;
          city: string;
          countryCode: string;
        } | null;
        openingDays: {
          __typename?: 'PickupOpeningDays';
          monday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
          tuesday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
          wednesday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
          thursday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
          friday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
          saturday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
          sunday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
        };
      } | null;
    };
    payment: {
      __typename?: 'OrderPayment';
      processor: PaymentProcessor;
      subProcessor?: PaymentSubProcessor | null;
      processorTransactionUrl: string;
      transactionPlan: {
        __typename?: 'OrderPaymentPlan';
        pendingTransactions: Array<{
          __typename?: 'PendingPaymentTransaction';
          dueDate: string;
          amount: number;
          currency: CurrencyIso;
        }>;
      };
    };
    totalPriceSold: {
      __typename?: 'Price';
      valueWithVat: number;
      valueWithoutVat: number;
      currency: CurrencyIso;
    };
    items: Array<{
      __typename?: 'Item';
      id: string;
      productId: string;
      productVariantId?: string | null;
      name: string;
      imageUrl: string;
      size?: string | null;
      sku?: string | null;
      gtin?: string | null;
      isDigital: boolean;
      digitalCouponCode?: string | null;
      newDigitalCouponCode?: string | null;
      isReturnable: boolean;
      isReturnGenerated: boolean;
      isOffered: boolean;
      isCancellable: boolean;
      isCancelled: boolean;
      priceSold: {
        __typename?: 'Price';
        valueWithVat: number;
        valueWithoutVat: number;
        currency: CurrencyIso;
      };
      priceBoughtByChoose: {
        __typename?: 'Price';
        valueWithVat: number;
        valueWithoutVat: number;
        currency: CurrencyIso;
      };
      refundSummary: {
        __typename?: 'OrderLineRefundSummary';
        isRefundable: boolean;
        isTotallyRefunded: boolean;
        refundedAmountInCents: number;
        refundedAmountInPercentage: number;
        refundedAmountInPercentageCoveredByChoose: number;
        refundedAmountInPercentageCoveredBySupplier: number;
        remainingToRefundAmountInCents: number;
        remainingToRefundAmountInPercentage: number;
      };
    }>;
    parcels: Array<{
      __typename?: 'Parcel';
      hasTrackingStucked: boolean;
      id: string;
      trackingCarrierSlug: string;
      trackingNumber: string;
      trackingStatus: TrackingStatus;
      trackingSubStatus?: TrackingSubStatus | null;
      trackingUrl: string;
      trackingUpdatedAt?: string | null;
    }>;
    returnSlips: Array<{
      __typename?: 'ReturnSlip';
      id: string;
      trackingCarrierSlug: string;
      trackingNumber: string;
      trackingUrl: string;
      items: Array<{
        __typename?: 'Item';
        id: string;
        productId: string;
        productVariantId?: string | null;
        name: string;
        imageUrl: string;
        size?: string | null;
        isDigital: boolean;
        digitalCouponCode?: string | null;
        newDigitalCouponCode?: string | null;
        priceSold: {
          __typename?: 'Price';
          currency: CurrencyIso;
          valueWithVat: number;
          valueWithoutVat: number;
        };
      }>;
    }>;
    returnParcels: Array<{
      __typename?: 'ReturnParcel';
      id: string;
      trackingCarrierSlug: string;
      trackingNumber: string;
      trackingStatus: TrackingStatus;
      trackingUrl: string;
      trackingUpdatedAt: string;
      items: Array<{
        __typename?: 'Item';
        id: string;
        productId: string;
        productVariantId?: string | null;
        name: string;
        imageUrl: string;
        size?: string | null;
        isDigital: boolean;
        digitalCouponCode?: string | null;
        newDigitalCouponCode?: string | null;
        priceSold: {
          __typename?: 'Price';
          currency: CurrencyIso;
          valueWithVat: number;
          valueWithoutVat: number;
        };
      }>;
    }>;
    claims: Array<{
      __typename?: 'Claim';
      id: string;
      reason: ClaimReason;
      otherReasonLabel?: string | null;
      messageFromSupport?: string | null;
      actionFromSeller?: ClaimSellerAction | null;
      proofUrls: Array<string>;
      isAccepted: boolean;
      acceptedMessage?: string | null;
      isDeclined: boolean;
      declinedMessage?: string | null;
      createdAt: string;
      updatedAt: string;
      isOpenedByBot: boolean;
    }>;
    tags: {
      __typename?: 'OrderTags';
      claim: Array<OrderTagClaim>;
      fulfillment: OrderTagFulfillment;
      refund: OrderTagRefund;
      reship: OrderTagReship;
      return: Array<OrderTagReturn>;
      shipment: Array<OrderTagShipment>;
    };
    seller?: {
      __typename?: 'Seller';
      id: string;
      name?: string | null;
      hubspotId?: string | null;
      website?: string | null;
      isFrench?: boolean | null;
      contactEmail?: string | null;
      contactPhone?: string | null;
      contactName?: string | null;
      returnAddress?: {
        __typename?: 'SellerAddress';
        name: string;
        street: string;
        streetAdditional?: string | null;
        zipCode: string;
        city: string;
        county?: string | null;
        country: string;
        countryCode: string;
      } | null;
    } | null;
    cashback?: {
      __typename?: 'UserCashback';
      amount: number;
      amountUpdatedAt?: string | null;
      dueDate: string;
      status: CashbackStatus;
      currency: StoreCurrency;
    } | null;
  };
};

export type CustomerOrdersQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;

export type CustomerOrdersQuery = {
  __typename?: 'Query';
  customerOrders: Array<{
    __typename?: 'OrderV2';
    id: string;
    billingId: string;
    sellerId: string;
    saleId: string;
    initialOrderId?: string | null;
    reshipOrderId?: string | null;
    operationType: OperationType;
    hasBeenExported: boolean;
    isCancelled: boolean;
    isFullDigital: boolean;
    createdAt: string;
    billingSellerNames?: Array<string> | null;
    logisticsManager: string;
    splitActionErrorReasons: Array<OrderSplitActionErrorReason>;
    buyer: {
      __typename?: 'Buyer';
      fullName: string;
      firstName: string;
      lastName: string;
      address: {
        __typename?: 'AddressV2';
        street: string;
        streetAdditional?: string | null;
        zipCode: string;
        city: string;
        county?: string | null;
        country: string;
        countryCode: string;
      };
      previous?: {
        __typename?: 'Buyer';
        fullName: string;
        firstName: string;
        lastName: string;
      } | null;
    };
    recipient: {
      __typename?: 'Recipient';
      fullName: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
      address: {
        __typename?: 'AddressV2';
        street: string;
        streetAdditional?: string | null;
        zipCode: string;
        city: string;
        county?: string | null;
        country: string;
        countryCode: string;
      };
    };
    shipping: {
      __typename?: 'OrderShipping';
      fees: number;
      feesSold: number;
      policyId?: string | null;
      deliveryDateRangeHistory: Array<{
        __typename?: 'DeliveryDateRange';
        start: string;
        end: string;
      }>;
      refundSummary: {
        __typename?: 'OrderLineRefundSummary';
        isRefundable: boolean;
        isTotallyRefunded: boolean;
        refundedAmountInCents: number;
        refundedAmountInPercentage: number;
        refundedAmountInPercentageCoveredByChoose: number;
        refundedAmountInPercentageCoveredBySupplier: number;
        remainingToRefundAmountInCents: number;
        remainingToRefundAmountInPercentage: number;
      };
      pickupPoint?: {
        __typename?: 'PickupPoint';
        id: string;
        countryCode: string;
        network: PickupPointNetwork;
        address?: {
          __typename?: 'PickupAddress';
          name: string;
          street: string;
          streetAdditional?: string | null;
          zipCode: string;
          city: string;
          countryCode: string;
        } | null;
        openingDays: {
          __typename?: 'PickupOpeningDays';
          monday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
          tuesday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
          wednesday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
          thursday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
          friday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
          saturday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
          sunday?: Array<{
            __typename?: 'PickupOpeningHours';
            open: string;
            close: string;
          }> | null;
        };
      } | null;
    };
    payment: {
      __typename?: 'OrderPayment';
      processor: PaymentProcessor;
      subProcessor?: PaymentSubProcessor | null;
      processorTransactionUrl: string;
      transactionPlan: {
        __typename?: 'OrderPaymentPlan';
        pendingTransactions: Array<{
          __typename?: 'PendingPaymentTransaction';
          dueDate: string;
          amount: number;
          currency: CurrencyIso;
        }>;
      };
    };
    totalPriceSold: {
      __typename?: 'Price';
      valueWithVat: number;
      valueWithoutVat: number;
      currency: CurrencyIso;
    };
    items: Array<{
      __typename?: 'Item';
      id: string;
      productId: string;
      productVariantId?: string | null;
      name: string;
      imageUrl: string;
      size?: string | null;
      sku?: string | null;
      gtin?: string | null;
      isDigital: boolean;
      digitalCouponCode?: string | null;
      newDigitalCouponCode?: string | null;
      isReturnable: boolean;
      isReturnGenerated: boolean;
      isOffered: boolean;
      isCancellable: boolean;
      isCancelled: boolean;
      priceSold: {
        __typename?: 'Price';
        valueWithVat: number;
        valueWithoutVat: number;
        currency: CurrencyIso;
      };
      priceBoughtByChoose: {
        __typename?: 'Price';
        valueWithVat: number;
        valueWithoutVat: number;
        currency: CurrencyIso;
      };
      refundSummary: {
        __typename?: 'OrderLineRefundSummary';
        isRefundable: boolean;
        isTotallyRefunded: boolean;
        refundedAmountInCents: number;
        refundedAmountInPercentage: number;
        refundedAmountInPercentageCoveredByChoose: number;
        refundedAmountInPercentageCoveredBySupplier: number;
        remainingToRefundAmountInCents: number;
        remainingToRefundAmountInPercentage: number;
      };
    }>;
    parcels: Array<{
      __typename?: 'Parcel';
      hasTrackingStucked: boolean;
      id: string;
      trackingCarrierSlug: string;
      trackingNumber: string;
      trackingStatus: TrackingStatus;
      trackingSubStatus?: TrackingSubStatus | null;
      trackingUrl: string;
      trackingUpdatedAt?: string | null;
    }>;
    returnSlips: Array<{
      __typename?: 'ReturnSlip';
      id: string;
      trackingCarrierSlug: string;
      trackingNumber: string;
      trackingUrl: string;
      items: Array<{
        __typename?: 'Item';
        id: string;
        productId: string;
        productVariantId?: string | null;
        name: string;
        imageUrl: string;
        size?: string | null;
        isDigital: boolean;
        digitalCouponCode?: string | null;
        newDigitalCouponCode?: string | null;
        priceSold: {
          __typename?: 'Price';
          currency: CurrencyIso;
          valueWithVat: number;
          valueWithoutVat: number;
        };
      }>;
    }>;
    returnParcels: Array<{
      __typename?: 'ReturnParcel';
      id: string;
      trackingCarrierSlug: string;
      trackingNumber: string;
      trackingStatus: TrackingStatus;
      trackingUrl: string;
      trackingUpdatedAt: string;
      items: Array<{
        __typename?: 'Item';
        id: string;
        productId: string;
        productVariantId?: string | null;
        name: string;
        imageUrl: string;
        size?: string | null;
        isDigital: boolean;
        digitalCouponCode?: string | null;
        newDigitalCouponCode?: string | null;
        priceSold: {
          __typename?: 'Price';
          currency: CurrencyIso;
          valueWithVat: number;
          valueWithoutVat: number;
        };
      }>;
    }>;
    claims: Array<{
      __typename?: 'Claim';
      id: string;
      reason: ClaimReason;
      otherReasonLabel?: string | null;
      messageFromSupport?: string | null;
      actionFromSeller?: ClaimSellerAction | null;
      proofUrls: Array<string>;
      isAccepted: boolean;
      acceptedMessage?: string | null;
      isDeclined: boolean;
      declinedMessage?: string | null;
      createdAt: string;
      updatedAt: string;
      isOpenedByBot: boolean;
    }>;
    tags: {
      __typename?: 'OrderTags';
      claim: Array<OrderTagClaim>;
      fulfillment: OrderTagFulfillment;
      refund: OrderTagRefund;
      reship: OrderTagReship;
      return: Array<OrderTagReturn>;
      shipment: Array<OrderTagShipment>;
    };
    seller?: {
      __typename?: 'Seller';
      id: string;
      name?: string | null;
      hubspotId?: string | null;
      website?: string | null;
      isFrench?: boolean | null;
      contactEmail?: string | null;
      contactPhone?: string | null;
      contactName?: string | null;
      returnAddress?: {
        __typename?: 'SellerAddress';
        name: string;
        street: string;
        streetAdditional?: string | null;
        zipCode: string;
        city: string;
        county?: string | null;
        country: string;
        countryCode: string;
      } | null;
    } | null;
    cashback?: {
      __typename?: 'UserCashback';
      amount: number;
      amountUpdatedAt?: string | null;
      dueDate: string;
      status: CashbackStatus;
      currency: StoreCurrency;
    } | null;
  }>;
};

export type GetProductInfoQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetProductInfoQuery = {
  __typename?: 'Query';
  getProductInfo?: {
    __typename?: 'ProductInfo';
    name?: string | null;
    description?: string | null;
    return_policy?: string | null;
    image?: string | null;
    not_returnable?: boolean | null;
    return_address?: any | null;
    is_coupon: boolean;
    coupon_expire_at?: string | null;
    coupon_cgu?: Array<string> | null;
    coupon_faq_before_order: Array<string | null>;
    coupon_faq: Array<string | null>;
    coupon_faq_after_order: Array<string | null>;
  } | null;
};

export type GetParrainDataQueryVariables = Exact<{
  data: Scalars['Json']['input'];
}>;

export type GetParrainDataQuery = {
  __typename?: 'Query';
  getParrainData?: any | null;
};

export type CreateInvoiceQueryVariables = Exact<{
  billingId: Scalars['String']['input'];
  userKey: Scalars['String']['input'];
}>;

export type CreateInvoiceQuery = {
  __typename?: 'Query';
  createInvoice?: string | null;
};

export type GetPostPurchaseReferralPlanQueryVariables = Exact<{
  data: PostPurchaseReferralInput;
}>;

export type GetPostPurchaseReferralPlanQuery = {
  __typename?: 'Query';
  getPostPurchaseReferralPlan:
    | {
        __typename?: 'PostPurchaseReferralInputException';
        exceptionCode: PostPurchaseReferralInputExceptionCode;
      }
    | {
        __typename?: 'PostPurchaseReferralPlan';
        code: string;
        friend: {
          __typename?: 'ReferralCredit';
          name?: string | null;
          credit: {
            __typename?: 'Credit';
            value: number;
            currency: StoreCurrency;
          };
        };
        me: {
          __typename?: 'ReferralCredit';
          name?: string | null;
          credit: {
            __typename?: 'Credit';
            value: number;
            currency: StoreCurrency;
          };
        };
      };
};

export type GetMessageGroupStatsQueryVariables = Exact<{
  saleId: Scalars['ID']['input'];
  orderIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetMessageGroupStatsQuery = {
  __typename?: 'Query';
  getMessageGroupStats: {
    __typename?: 'MessageGroupStats';
    orderIds: Array<string>;
    customerCount: number;
  };
};

export type GetUploadReturnSlipLabelUrlQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type GetUploadReturnSlipLabelUrlQuery = {
  __typename?: 'Query';
  getUploadReturnSlipLabelUrl: {
    __typename?: 'UploadInfo';
    key: string;
    url: string;
  };
};

export type JoinReturnSlipMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  input: JoinReturnSlipInput;
}>;

export type JoinReturnSlipMutation = {
  __typename?: 'Mutation';
  joinReturnSlip?: void | null;
};

export type RefundOrderItemsPartiallyMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  input: RefundOrderItemsPartiallyInput;
}>;

export type RefundOrderItemsPartiallyMutation = {
  __typename?: 'Mutation';
  refundOrderItemsPartially: {
    __typename?: 'RefundOrderSummary';
    cashAmountInCents: number;
    creditAmountInCents: number;
  };
};

export type RefundOrderShippingPartiallyMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  input: RefundOrderShippingPartiallyInput;
}>;

export type RefundOrderShippingPartiallyMutation = {
  __typename?: 'Mutation';
  refundOrderShippingPartially: {
    __typename?: 'RefundOrderSummary';
    cashAmountInCents: number;
    creditAmountInCents: number;
  };
};

export type SendMessageGroupMutationVariables = Exact<{
  canal: MessageGroupCanal;
  content: Scalars['String']['input'];
  reasonId: Scalars['String']['input'];
  saleId: Scalars['String']['input'];
  segmentId: SegmentType;
  options?: InputMaybe<MessageGroupOptions>;
  photos?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type SendMessageGroupMutation = {
  __typename?: 'Mutation';
  sendMessageGroup?: string | null;
};

export type PushMessageMutationVariables = Exact<{
  data?: InputMaybe<MessageInput>;
  type?: InputMaybe<ConversationType>;
}>;

export type PushMessageMutation = {
  __typename?: 'Mutation';
  pushMessage?: string | null;
};

export type OperatorReplyingMutationVariables = Exact<{
  data: OperatorReplyingUpdate;
}>;

export type OperatorReplyingMutation = {
  __typename?: 'Mutation';
  operatorReplying?: string | null;
};

export type SetConversationAsReadMutationVariables = Exact<{
  data?: InputMaybe<ConversationUpdate>;
  type?: InputMaybe<ConversationType>;
}>;

export type SetConversationAsReadMutation = {
  __typename?: 'Mutation';
  setConversationAsRead?: string | null;
};

export type AssignConversationMutationVariables = Exact<{
  conversationId: Scalars['String']['input'];
  operatorId: Scalars['String']['input'];
}>;

export type AssignConversationMutation = {
  __typename?: 'Mutation';
  assignConversation?: string | null;
};

export type SetConversationCategoryMutationVariables = Exact<{
  conversationId: Scalars['String']['input'];
  category?: InputMaybe<ConversationCategory>;
}>;

export type SetConversationCategoryMutation = {
  __typename?: 'Mutation';
  setConversationCategory?: string | null;
};

export type SetUserNoteMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  message: Scalars['String']['input'];
}>;

export type SetUserNoteMutation = {
  __typename?: 'Mutation';
  setUserNote?: boolean | null;
};

export type CreateCreditMutationVariables = Exact<{
  data: CreditUpdate;
}>;

export type CreateCreditMutation = {
  __typename?: 'Mutation';
  createCredit?: boolean | null;
};

export type CreatePostPurchaseReferralCreditMutationVariables = Exact<{
  data: PostPurchaseReferralInput;
}>;

export type CreatePostPurchaseReferralCreditMutation = {
  __typename?: 'Mutation';
  createPostPurchaseReferralCredit?: boolean | null;
};

export type ChangeReferralMutationVariables = Exact<{
  userKey: Scalars['String']['input'];
  referral: Scalars['String']['input'];
}>;

export type ChangeReferralMutation = {
  __typename?: 'Mutation';
  changeReferral?: any | null;
};

export type CreateTemplateCategoryMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type CreateTemplateCategoryMutation = {
  __typename?: 'Mutation';
  createTemplateCategory?: string | null;
};

export type UpdateTemplateMessageMutationVariables = Exact<{
  data: TemplateInput;
}>;

export type UpdateTemplateMessageMutation = {
  __typename?: 'Mutation';
  updateTemplateMessage?: string | null;
};

export type OpenClaimMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  input: OpenClaimInput;
}>;

export type OpenClaimMutation = { __typename?: 'Mutation'; openClaim: string };

export type CancelOrderMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  input: CancelOrderInput;
}>;

export type CancelOrderMutation = {
  __typename?: 'Mutation';
  cancelOrder?: void | null;
};

export type CancelClaimMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  claimId: Scalars['ID']['input'];
}>;

export type CancelClaimMutation = {
  __typename?: 'Mutation';
  cancelClaim?: void | null;
};

export type CreateReturnMutationVariables = Exact<{
  userKey: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  deliveryToChoose?: InputMaybe<Scalars['Boolean']['input']>;
  products?: InputMaybe<
    | Array<InputMaybe<Scalars['Json']['input']>>
    | InputMaybe<Scalars['Json']['input']>
  >;
}>;

export type CreateReturnMutation = {
  __typename?: 'Mutation';
  createReturn?: string | null;
};

export type UpdateBillingOrdersBillingAddressMutationVariables = Exact<{
  billingId: Scalars['ID']['input'];
  input: BillingAddressInput;
}>;

export type UpdateBillingOrdersBillingAddressMutation = {
  __typename?: 'Mutation';
  updateBillingOrdersBillingAddress?: void | null;
};

export type UpdateBillingOrdersShippingAddressMutationVariables = Exact<{
  billingId: Scalars['ID']['input'];
  input: ShippingAddressInput;
}>;

export type UpdateBillingOrdersShippingAddressMutation = {
  __typename?: 'Mutation';
  updateBillingOrdersShippingAddress?: void | null;
};

export type UpdateOrderShippingAddressMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  input: ShippingAddressInput;
}>;

export type UpdateOrderShippingAddressMutation = {
  __typename?: 'Mutation';
  updateOrderShippingAddress?: void | null;
};

export type ValidateOrderItemsReturnedMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type ValidateOrderItemsReturnedMutation = {
  __typename?: 'Mutation';
  validateOrderItemsReturned?: void | null;
};

export type CancelReturnMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type CancelReturnMutation = {
  __typename?: 'Mutation';
  cancelReturn?: void | null;
};

export type SplitOrderMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  newOrdersItemIds:
    | Array<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
    | Array<Scalars['ID']['input']>
    | Scalars['ID']['input'];
}>;

export type SplitOrderMutation = {
  __typename?: 'Mutation';
  splitOrder?: void | null;
};

export type CancelOrderItemsMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  itemIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  input: CancelOrderItemsInput;
}>;

export type CancelOrderItemsMutation = {
  __typename?: 'Mutation';
  cancelOrderItems?: void | null;
};

export type MarkOrderAsDeliveredMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type MarkOrderAsDeliveredMutation = {
  __typename?: 'Mutation';
  markOrderAsDelivered?: void | null;
};

export type RefreshStatsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type RefreshStatsSubscription = {
  __typename?: 'Subscription';
  refreshStats?: {
    __typename?: 'Stats';
    unhandled?: number | null;
    pinned?: number | null;
    unassigned?: number | null;
    usa?: number | null;
    categoryBotProcessing?: number | null;
    categoryDelivery?: number | null;
    categoryOrder?: number | null;
    categoryPostOperation?: number | null;
    categoryPreSales?: number | null;
    assignOperatorRepartition?: Array<{
      __typename?: 'OperatorStats';
      operatorId: string;
      nbConversationAssigned: number;
    }> | null;
  } | null;
};

export type NewMessageSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewMessageSubscription = {
  __typename?: 'Subscription';
  newMessage?: {
    __typename?: 'MessageAdded';
    userKey?: string | null;
    body?: string | null;
    chooseReply?: boolean | null;
    createdAt?: string | null;
    name?: string | null;
    total_orders?: number | null;
    isAssign?: boolean | null;
    category?: ConversationCategory | null;
    assignToOperatorId?: string | null;
    userStore?: StoreRegion | null;
  } | null;
};

export type MessageChangedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type MessageChangedSubscription = {
  __typename?: 'Subscription';
  messageChanged?: {
    __typename?: 'MessageChanged';
    userKey?: string | null;
    isFixed?: boolean | null;
    isPinned?: boolean | null;
    isAssign?: boolean | null;
    assignToOperatorId?: string | null;
    userStore?: StoreRegion | null;
    category?: ConversationCategory | null;
  } | null;
};

export type NewOperatorReplyingSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type NewOperatorReplyingSubscription = {
  __typename?: 'Subscription';
  newOperatorReplying: Array<{
    __typename?: 'OperatorActive';
    operatorId: string;
    userKey: string;
    operator: string;
    message?: string | null;
  } | null>;
};

export type OrderUpdatedSubscriptionVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;

export type OrderUpdatedSubscription = {
  __typename?: 'Subscription';
  orderUpdated: { __typename?: 'OrderUpdated'; id: string };
};

export const OrderFieldsFragmentDoc = gql`
  fragment orderFields on OrderV2 {
    id
    billingId
    sellerId
    saleId
    initialOrderId
    reshipOrderId
    buyer {
      fullName
      firstName
      lastName
      address {
        street
        streetAdditional
        zipCode
        city
        county
        country
        countryCode
      }
      previous {
        fullName
        firstName
        lastName
      }
    }
    recipient {
      fullName
      firstName
      lastName
      email
      phoneNumber
      address {
        street
        streetAdditional
        zipCode
        city
        county
        country
        countryCode
      }
    }
    shipping {
      fees
      feesSold
      deliveryDateRangeHistory {
        start
        end
      }
      policyId
      refundSummary {
        isRefundable
        isTotallyRefunded
        refundedAmountInCents
        refundedAmountInPercentage
        refundedAmountInPercentageCoveredByChoose
        refundedAmountInPercentageCoveredBySupplier
        remainingToRefundAmountInCents
        remainingToRefundAmountInPercentage
      }
      pickupPoint {
        id
        countryCode
        network
        address {
          name
          street
          streetAdditional
          zipCode
          city
          countryCode
        }
        openingDays {
          monday {
            open
            close
          }
          tuesday {
            open
            close
          }
          wednesday {
            open
            close
          }
          thursday {
            open
            close
          }
          friday {
            open
            close
          }
          saturday {
            open
            close
          }
          sunday {
            open
            close
          }
        }
      }
    }
    payment {
      transactionPlan {
        pendingTransactions {
          dueDate
          amount
          currency
        }
      }
      processor
      subProcessor
      processorTransactionUrl
    }
    totalPriceSold {
      valueWithVat
      valueWithoutVat
      currency
    }
    items {
      id
      productId
      productVariantId
      name
      imageUrl
      size
      sku
      gtin
      priceSold {
        valueWithVat
        valueWithoutVat
        currency
      }
      priceBoughtByChoose {
        valueWithVat
        valueWithoutVat
        currency
      }
      isDigital
      digitalCouponCode
      newDigitalCouponCode
      isReturnable
      isReturnGenerated
      isOffered
      isCancellable
      isCancelled
      refundSummary {
        isRefundable
        isTotallyRefunded
        refundedAmountInCents
        refundedAmountInPercentage
        refundedAmountInPercentageCoveredByChoose
        refundedAmountInPercentageCoveredBySupplier
        remainingToRefundAmountInCents
        remainingToRefundAmountInPercentage
      }
    }
    parcels {
      hasTrackingStucked
      id
      trackingCarrierSlug
      trackingNumber
      trackingStatus
      trackingSubStatus
      trackingUrl
      trackingUpdatedAt
    }
    returnSlips {
      id
      trackingCarrierSlug
      trackingNumber
      trackingUrl
      items {
        id
        productId
        productVariantId
        name
        imageUrl
        size
        priceSold {
          currency
          valueWithVat
          valueWithoutVat
        }
        isDigital
        digitalCouponCode
        newDigitalCouponCode
      }
    }
    returnParcels {
      id
      trackingCarrierSlug
      trackingNumber
      trackingStatus
      trackingUrl
      trackingUpdatedAt
      items {
        id
        productId
        productVariantId
        name
        imageUrl
        size
        priceSold {
          currency
          valueWithVat
          valueWithoutVat
        }
        isDigital
        digitalCouponCode
        newDigitalCouponCode
      }
    }
    claims {
      id
      reason
      otherReasonLabel
      messageFromSupport
      actionFromSeller
      proofUrls
      isAccepted
      acceptedMessage
      isDeclined
      declinedMessage
      createdAt
      updatedAt
      isOpenedByBot
    }
    tags {
      claim
      fulfillment
      refund
      reship
      return
      shipment
    }
    operationType
    hasBeenExported
    isCancelled
    isFullDigital
    createdAt
    seller {
      id
      name
      hubspotId
      website
      isFrench
      contactEmail
      contactPhone
      contactName
      returnAddress {
        name
        street
        streetAdditional
        zipCode
        city
        county
        country
        countryCode
      }
    }
    billingSellerNames
    cashback {
      amount
      amountUpdatedAt
      dueDate
      status
      currency
    }
    logisticsManager
    splitActionErrorReasons
  }
`;
export const InboxV2Document = gql`
  query inboxV2(
    $filter: MessageFilter
    $offset: Int
    $limit: Int
    $sort: SortType
  ) {
    inboxV2(filter: $filter, offset: $offset, limit: $limit, sort: $sort) {
      messages {
        userKey
        name
        body
        createdAt
        total_orders
        store
        assignTo
        aiSentiment
        aiNeedResponse
        aiPriorityScore
      }
      emails {
        email
        createdAt
        body
        fromChoose
      }
    }
  }
`;

/**
 * __useInboxV2Query__
 *
 * To run a query within a React component, call `useInboxV2Query` and pass it any options that fit your needs.
 * When your component renders, `useInboxV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxV2Query({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useInboxV2Query(
  baseOptions?: Apollo.QueryHookOptions<InboxV2Query, InboxV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InboxV2Query, InboxV2QueryVariables>(
    InboxV2Document,
    options
  );
}
export function useInboxV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InboxV2Query, InboxV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InboxV2Query, InboxV2QueryVariables>(
    InboxV2Document,
    options
  );
}
export function useInboxV2SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InboxV2Query,
    InboxV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InboxV2Query, InboxV2QueryVariables>(
    InboxV2Document,
    options
  );
}
export type InboxV2QueryHookResult = ReturnType<typeof useInboxV2Query>;
export type InboxV2LazyQueryHookResult = ReturnType<typeof useInboxV2LazyQuery>;
export type InboxV2SuspenseQueryHookResult = ReturnType<
  typeof useInboxV2SuspenseQuery
>;
export type InboxV2QueryResult = Apollo.QueryResult<
  InboxV2Query,
  InboxV2QueryVariables
>;
export const FindUserDocument = gql`
  query findUser($search: String!) {
    findUser(search: $search) {
      total
      hits {
        _score
        highlight
        _source
        _id
      }
    }
  }
`;

/**
 * __useFindUserQuery__
 *
 * To run a query within a React component, call `useFindUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFindUserQuery(
  baseOptions: Apollo.QueryHookOptions<FindUserQuery, FindUserQueryVariables> &
    ({ variables: FindUserQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindUserQuery, FindUserQueryVariables>(
    FindUserDocument,
    options
  );
}
export function useFindUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserQuery,
    FindUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindUserQuery, FindUserQueryVariables>(
    FindUserDocument,
    options
  );
}
export function useFindUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FindUserQuery,
    FindUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindUserQuery, FindUserQueryVariables>(
    FindUserDocument,
    options
  );
}
export type FindUserQueryHookResult = ReturnType<typeof useFindUserQuery>;
export type FindUserLazyQueryHookResult = ReturnType<
  typeof useFindUserLazyQuery
>;
export type FindUserSuspenseQueryHookResult = ReturnType<
  typeof useFindUserSuspenseQuery
>;
export type FindUserQueryResult = Apollo.QueryResult<
  FindUserQuery,
  FindUserQueryVariables
>;
export const CancelOrderIntentDocument = gql`
  query CancelOrderIntent($orderId: String!) {
    cancelOrderIntent(orderId: $orderId) {
      currentAmount
      postActionAmount
    }
  }
`;

/**
 * __useCancelOrderIntentQuery__
 *
 * To run a query within a React component, call `useCancelOrderIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelOrderIntentQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelOrderIntentQuery(
  baseOptions: Apollo.QueryHookOptions<
    CancelOrderIntentQuery,
    CancelOrderIntentQueryVariables
  > &
    (
      | { variables: CancelOrderIntentQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CancelOrderIntentQuery,
    CancelOrderIntentQueryVariables
  >(CancelOrderIntentDocument, options);
}
export function useCancelOrderIntentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CancelOrderIntentQuery,
    CancelOrderIntentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CancelOrderIntentQuery,
    CancelOrderIntentQueryVariables
  >(CancelOrderIntentDocument, options);
}
export function useCancelOrderIntentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CancelOrderIntentQuery,
    CancelOrderIntentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CancelOrderIntentQuery,
    CancelOrderIntentQueryVariables
  >(CancelOrderIntentDocument, options);
}
export type CancelOrderIntentQueryHookResult = ReturnType<
  typeof useCancelOrderIntentQuery
>;
export type CancelOrderIntentLazyQueryHookResult = ReturnType<
  typeof useCancelOrderIntentLazyQuery
>;
export type CancelOrderIntentSuspenseQueryHookResult = ReturnType<
  typeof useCancelOrderIntentSuspenseQuery
>;
export type CancelOrderIntentQueryResult = Apollo.QueryResult<
  CancelOrderIntentQuery,
  CancelOrderIntentQueryVariables
>;
export const CreateClaimIntentDocument = gql`
  query CreateClaimIntent($orderId: String!, $deltaAmount: Int!) {
    createClaimIntent(orderId: $orderId, deltaAmount: $deltaAmount) {
      currentAmount
      postActionAmount
    }
  }
`;

/**
 * __useCreateClaimIntentQuery__
 *
 * To run a query within a React component, call `useCreateClaimIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateClaimIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateClaimIntentQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      deltaAmount: // value for 'deltaAmount'
 *   },
 * });
 */
export function useCreateClaimIntentQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreateClaimIntentQuery,
    CreateClaimIntentQueryVariables
  > &
    (
      | { variables: CreateClaimIntentQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CreateClaimIntentQuery,
    CreateClaimIntentQueryVariables
  >(CreateClaimIntentDocument, options);
}
export function useCreateClaimIntentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateClaimIntentQuery,
    CreateClaimIntentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreateClaimIntentQuery,
    CreateClaimIntentQueryVariables
  >(CreateClaimIntentDocument, options);
}
export function useCreateClaimIntentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CreateClaimIntentQuery,
    CreateClaimIntentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CreateClaimIntentQuery,
    CreateClaimIntentQueryVariables
  >(CreateClaimIntentDocument, options);
}
export type CreateClaimIntentQueryHookResult = ReturnType<
  typeof useCreateClaimIntentQuery
>;
export type CreateClaimIntentLazyQueryHookResult = ReturnType<
  typeof useCreateClaimIntentLazyQuery
>;
export type CreateClaimIntentSuspenseQueryHookResult = ReturnType<
  typeof useCreateClaimIntentSuspenseQuery
>;
export type CreateClaimIntentQueryResult = Apollo.QueryResult<
  CreateClaimIntentQuery,
  CreateClaimIntentQueryVariables
>;
export const GetConversationEmailDocument = gql`
  query GetConversationEmail($email: String!) {
    emails(email: $email) {
      body
      fromChoose
      email
      createdAt
      isFixed
      isPinned
    }
  }
`;

/**
 * __useGetConversationEmailQuery__
 *
 * To run a query within a React component, call `useGetConversationEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetConversationEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConversationEmailQuery,
    GetConversationEmailQueryVariables
  > &
    (
      | { variables: GetConversationEmailQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConversationEmailQuery,
    GetConversationEmailQueryVariables
  >(GetConversationEmailDocument, options);
}
export function useGetConversationEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationEmailQuery,
    GetConversationEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationEmailQuery,
    GetConversationEmailQueryVariables
  >(GetConversationEmailDocument, options);
}
export function useGetConversationEmailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetConversationEmailQuery,
    GetConversationEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetConversationEmailQuery,
    GetConversationEmailQueryVariables
  >(GetConversationEmailDocument, options);
}
export type GetConversationEmailQueryHookResult = ReturnType<
  typeof useGetConversationEmailQuery
>;
export type GetConversationEmailLazyQueryHookResult = ReturnType<
  typeof useGetConversationEmailLazyQuery
>;
export type GetConversationEmailSuspenseQueryHookResult = ReturnType<
  typeof useGetConversationEmailSuspenseQuery
>;
export type GetConversationEmailQueryResult = Apollo.QueryResult<
  GetConversationEmailQuery,
  GetConversationEmailQueryVariables
>;
export const GetConversationV2Document = gql`
  query GetConversationV2($key: String!) {
    conversationV2(key: $key) {
      messages {
        __typename
        ... on ConversationMessageGroup {
          id
          userKey
          body
          reason
          isFromChoose
          createdAt
          readAt
          operator
          files
          photos
          fromPage
          fromEmail
          order {
            id
            itemsConcern {
              id
              productId
              variantId
              description
              image
              options
            }
            brandName
            willBeOpenedByBotAt
          }
        }
        ... on ConversationMessage {
          id
          userKey
          chooseReply
          body
          createdAt
          readAt
          operator
          file
          topic
          version
          versionCode
          fromPage
          fromEmail
          emailSubject
          versionCodePush
          score
          network
          photo
          extraData {
            image
            name
            brand_name
            id
          }
          category
          isPinned
          products
          isFixed
          orderId
          orderInfos {
            key
            brand_name
            products
          }
          isBot
        }
      }
      user {
        id
        userKey
        name
        email
        phoneNumber
        timezone
        currentStore
        language
        support_note
        profilePicture
        addresses
        referralCode
        credits {
          value
          currency
        }
        device
        appVersion
        has_latest_version
        createdAt
        updatedAt
        referral {
          created_at
          name
        }
        lastTime
      }
      status {
        assignTo
        isFixed
        isPinned
        category
      }
    }
  }
`;

/**
 * __useGetConversationV2Query__
 *
 * To run a query within a React component, call `useGetConversationV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationV2Query({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetConversationV2Query(
  baseOptions: Apollo.QueryHookOptions<
    GetConversationV2Query,
    GetConversationV2QueryVariables
  > &
    (
      | { variables: GetConversationV2QueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConversationV2Query,
    GetConversationV2QueryVariables
  >(GetConversationV2Document, options);
}
export function useGetConversationV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationV2Query,
    GetConversationV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationV2Query,
    GetConversationV2QueryVariables
  >(GetConversationV2Document, options);
}
export function useGetConversationV2SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetConversationV2Query,
    GetConversationV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetConversationV2Query,
    GetConversationV2QueryVariables
  >(GetConversationV2Document, options);
}
export type GetConversationV2QueryHookResult = ReturnType<
  typeof useGetConversationV2Query
>;
export type GetConversationV2LazyQueryHookResult = ReturnType<
  typeof useGetConversationV2LazyQuery
>;
export type GetConversationV2SuspenseQueryHookResult = ReturnType<
  typeof useGetConversationV2SuspenseQuery
>;
export type GetConversationV2QueryResult = Apollo.QueryResult<
  GetConversationV2Query,
  GetConversationV2QueryVariables
>;
export const StatsDocument = gql`
  query stats {
    stats {
      unhandled
      pinned
      unassigned
      usa
      categoryBotProcessing
      categoryDelivery
      categoryOrder
      categoryPostOperation
      categoryPreSales
      assignOperatorRepartition {
        operatorId
        nbConversationAssigned
      }
    }
  }
`;

/**
 * __useStatsQuery__
 *
 * To run a query within a React component, call `useStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<StatsQuery, StatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StatsQuery, StatsQueryVariables>(
    StatsDocument,
    options
  );
}
export function useStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StatsQuery, StatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StatsQuery, StatsQueryVariables>(
    StatsDocument,
    options
  );
}
export function useStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<StatsQuery, StatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StatsQuery, StatsQueryVariables>(
    StatsDocument,
    options
  );
}
export type StatsQueryHookResult = ReturnType<typeof useStatsQuery>;
export type StatsLazyQueryHookResult = ReturnType<typeof useStatsLazyQuery>;
export type StatsSuspenseQueryHookResult = ReturnType<
  typeof useStatsSuspenseQuery
>;
export type StatsQueryResult = Apollo.QueryResult<
  StatsQuery,
  StatsQueryVariables
>;
export const TemplatesDocument = gql`
  query templates {
    templates {
      categories {
        id
        name
      }
      templates {
        key
        category
        title
        message
        category_name
      }
    }
  }
`;

/**
 * __useTemplatesQuery__
 *
 * To run a query within a React component, call `useTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<TemplatesQuery, TemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TemplatesQuery, TemplatesQueryVariables>(
    TemplatesDocument,
    options
  );
}
export function useTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TemplatesQuery,
    TemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TemplatesQuery, TemplatesQueryVariables>(
    TemplatesDocument,
    options
  );
}
export function useTemplatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TemplatesQuery,
    TemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TemplatesQuery, TemplatesQueryVariables>(
    TemplatesDocument,
    options
  );
}
export type TemplatesQueryHookResult = ReturnType<typeof useTemplatesQuery>;
export type TemplatesLazyQueryHookResult = ReturnType<
  typeof useTemplatesLazyQuery
>;
export type TemplatesSuspenseQueryHookResult = ReturnType<
  typeof useTemplatesSuspenseQuery
>;
export type TemplatesQueryResult = Apollo.QueryResult<
  TemplatesQuery,
  TemplatesQueryVariables
>;
export const ListOperatorsDocument = gql`
  query listOperators {
    listOperators {
      operatorId
      displayName
      email
      image
    }
  }
`;

/**
 * __useListOperatorsQuery__
 *
 * To run a query within a React component, call `useListOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOperatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListOperatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListOperatorsQuery,
    ListOperatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListOperatorsQuery, ListOperatorsQueryVariables>(
    ListOperatorsDocument,
    options
  );
}
export function useListOperatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListOperatorsQuery,
    ListOperatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListOperatorsQuery, ListOperatorsQueryVariables>(
    ListOperatorsDocument,
    options
  );
}
export function useListOperatorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListOperatorsQuery,
    ListOperatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListOperatorsQuery,
    ListOperatorsQueryVariables
  >(ListOperatorsDocument, options);
}
export type ListOperatorsQueryHookResult = ReturnType<
  typeof useListOperatorsQuery
>;
export type ListOperatorsLazyQueryHookResult = ReturnType<
  typeof useListOperatorsLazyQuery
>;
export type ListOperatorsSuspenseQueryHookResult = ReturnType<
  typeof useListOperatorsSuspenseQuery
>;
export type ListOperatorsQueryResult = Apollo.QueryResult<
  ListOperatorsQuery,
  ListOperatorsQueryVariables
>;
export const GetReasonsToSendMessageToSegmentDocument = gql`
  query getReasonsToSendMessageToSegment {
    getReasonsToSendMessageToSegment {
      id
      reason
    }
  }
`;

/**
 * __useGetReasonsToSendMessageToSegmentQuery__
 *
 * To run a query within a React component, call `useGetReasonsToSendMessageToSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReasonsToSendMessageToSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReasonsToSendMessageToSegmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReasonsToSendMessageToSegmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetReasonsToSendMessageToSegmentQuery,
    GetReasonsToSendMessageToSegmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetReasonsToSendMessageToSegmentQuery,
    GetReasonsToSendMessageToSegmentQueryVariables
  >(GetReasonsToSendMessageToSegmentDocument, options);
}
export function useGetReasonsToSendMessageToSegmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReasonsToSendMessageToSegmentQuery,
    GetReasonsToSendMessageToSegmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReasonsToSendMessageToSegmentQuery,
    GetReasonsToSendMessageToSegmentQueryVariables
  >(GetReasonsToSendMessageToSegmentDocument, options);
}
export function useGetReasonsToSendMessageToSegmentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetReasonsToSendMessageToSegmentQuery,
    GetReasonsToSendMessageToSegmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetReasonsToSendMessageToSegmentQuery,
    GetReasonsToSendMessageToSegmentQueryVariables
  >(GetReasonsToSendMessageToSegmentDocument, options);
}
export type GetReasonsToSendMessageToSegmentQueryHookResult = ReturnType<
  typeof useGetReasonsToSendMessageToSegmentQuery
>;
export type GetReasonsToSendMessageToSegmentLazyQueryHookResult = ReturnType<
  typeof useGetReasonsToSendMessageToSegmentLazyQuery
>;
export type GetReasonsToSendMessageToSegmentSuspenseQueryHookResult =
  ReturnType<typeof useGetReasonsToSendMessageToSegmentSuspenseQuery>;
export type GetReasonsToSendMessageToSegmentQueryResult = Apollo.QueryResult<
  GetReasonsToSendMessageToSegmentQuery,
  GetReasonsToSendMessageToSegmentQueryVariables
>;
export const GetSegmentsInfosDocument = gql`
  query getSegmentsInfos($saleId: String!) {
    getSegmentsInfos {
      id
      label
      group
      statsBySale(saleId: $saleId) {
        nbCustomers
      }
    }
  }
`;

/**
 * __useGetSegmentsInfosQuery__
 *
 * To run a query within a React component, call `useGetSegmentsInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentsInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentsInfosQuery({
 *   variables: {
 *      saleId: // value for 'saleId'
 *   },
 * });
 */
export function useGetSegmentsInfosQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSegmentsInfosQuery,
    GetSegmentsInfosQueryVariables
  > &
    (
      | { variables: GetSegmentsInfosQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSegmentsInfosQuery, GetSegmentsInfosQueryVariables>(
    GetSegmentsInfosDocument,
    options
  );
}
export function useGetSegmentsInfosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSegmentsInfosQuery,
    GetSegmentsInfosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSegmentsInfosQuery,
    GetSegmentsInfosQueryVariables
  >(GetSegmentsInfosDocument, options);
}
export function useGetSegmentsInfosSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSegmentsInfosQuery,
    GetSegmentsInfosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSegmentsInfosQuery,
    GetSegmentsInfosQueryVariables
  >(GetSegmentsInfosDocument, options);
}
export type GetSegmentsInfosQueryHookResult = ReturnType<
  typeof useGetSegmentsInfosQuery
>;
export type GetSegmentsInfosLazyQueryHookResult = ReturnType<
  typeof useGetSegmentsInfosLazyQuery
>;
export type GetSegmentsInfosSuspenseQueryHookResult = ReturnType<
  typeof useGetSegmentsInfosSuspenseQuery
>;
export type GetSegmentsInfosQueryResult = Apollo.QueryResult<
  GetSegmentsInfosQuery,
  GetSegmentsInfosQueryVariables
>;
export const SearchSalesEsDocument = gql`
  query searchSalesES($name: String!) {
    searchSalesES(name: $name) {
      total
      max_score
      hits {
        highlight
      }
    }
  }
`;

/**
 * __useSearchSalesEsQuery__
 *
 * To run a query within a React component, call `useSearchSalesEsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSalesEsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSalesEsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSearchSalesEsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchSalesEsQuery,
    SearchSalesEsQueryVariables
  > &
    (
      | { variables: SearchSalesEsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchSalesEsQuery, SearchSalesEsQueryVariables>(
    SearchSalesEsDocument,
    options
  );
}
export function useSearchSalesEsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchSalesEsQuery,
    SearchSalesEsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchSalesEsQuery, SearchSalesEsQueryVariables>(
    SearchSalesEsDocument,
    options
  );
}
export function useSearchSalesEsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SearchSalesEsQuery,
    SearchSalesEsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SearchSalesEsQuery,
    SearchSalesEsQueryVariables
  >(SearchSalesEsDocument, options);
}
export type SearchSalesEsQueryHookResult = ReturnType<
  typeof useSearchSalesEsQuery
>;
export type SearchSalesEsLazyQueryHookResult = ReturnType<
  typeof useSearchSalesEsLazyQuery
>;
export type SearchSalesEsSuspenseQueryHookResult = ReturnType<
  typeof useSearchSalesEsSuspenseQuery
>;
export type SearchSalesEsQueryResult = Apollo.QueryResult<
  SearchSalesEsQuery,
  SearchSalesEsQueryVariables
>;
export const GetCreditHistoryDocument = gql`
  query getCreditHistory($userKey: String!) {
    getCreditHistory(userKey: $userKey) {
      userKey
      credit {
        value
        currency
      }
      operator
      createdAt
      description
    }
  }
`;

/**
 * __useGetCreditHistoryQuery__
 *
 * To run a query within a React component, call `useGetCreditHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditHistoryQuery({
 *   variables: {
 *      userKey: // value for 'userKey'
 *   },
 * });
 */
export function useGetCreditHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreditHistoryQuery,
    GetCreditHistoryQueryVariables
  > &
    (
      | { variables: GetCreditHistoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCreditHistoryQuery, GetCreditHistoryQueryVariables>(
    GetCreditHistoryDocument,
    options
  );
}
export function useGetCreditHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreditHistoryQuery,
    GetCreditHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCreditHistoryQuery,
    GetCreditHistoryQueryVariables
  >(GetCreditHistoryDocument, options);
}
export function useGetCreditHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCreditHistoryQuery,
    GetCreditHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCreditHistoryQuery,
    GetCreditHistoryQueryVariables
  >(GetCreditHistoryDocument, options);
}
export type GetCreditHistoryQueryHookResult = ReturnType<
  typeof useGetCreditHistoryQuery
>;
export type GetCreditHistoryLazyQueryHookResult = ReturnType<
  typeof useGetCreditHistoryLazyQuery
>;
export type GetCreditHistorySuspenseQueryHookResult = ReturnType<
  typeof useGetCreditHistorySuspenseQuery
>;
export type GetCreditHistoryQueryResult = Apollo.QueryResult<
  GetCreditHistoryQuery,
  GetCreditHistoryQueryVariables
>;
export const OrderDocument = gql`
  query Order($orderId: ID!) {
    order(orderId: $orderId) {
      ...orderFields
    }
  }
  ${OrderFieldsFragmentDoc}
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables> &
    ({ variables: OrderQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options
  );
}
export function useOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options
  );
}
export function useOrderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OrderQuery, OrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options
  );
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderSuspenseQueryHookResult = ReturnType<
  typeof useOrderSuspenseQuery
>;
export type OrderQueryResult = Apollo.QueryResult<
  OrderQuery,
  OrderQueryVariables
>;
export const CustomerOrdersDocument = gql`
  query CustomerOrders($customerId: ID!) {
    customerOrders(customerId: $customerId) {
      ...orderFields
    }
  }
  ${OrderFieldsFragmentDoc}
`;

/**
 * __useCustomerOrdersQuery__
 *
 * To run a query within a React component, call `useCustomerOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerOrdersQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerOrdersQuery,
    CustomerOrdersQueryVariables
  > &
    (
      | { variables: CustomerOrdersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerOrdersQuery, CustomerOrdersQueryVariables>(
    CustomerOrdersDocument,
    options
  );
}
export function useCustomerOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerOrdersQuery,
    CustomerOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerOrdersQuery, CustomerOrdersQueryVariables>(
    CustomerOrdersDocument,
    options
  );
}
export function useCustomerOrdersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CustomerOrdersQuery,
    CustomerOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CustomerOrdersQuery,
    CustomerOrdersQueryVariables
  >(CustomerOrdersDocument, options);
}
export type CustomerOrdersQueryHookResult = ReturnType<
  typeof useCustomerOrdersQuery
>;
export type CustomerOrdersLazyQueryHookResult = ReturnType<
  typeof useCustomerOrdersLazyQuery
>;
export type CustomerOrdersSuspenseQueryHookResult = ReturnType<
  typeof useCustomerOrdersSuspenseQuery
>;
export type CustomerOrdersQueryResult = Apollo.QueryResult<
  CustomerOrdersQuery,
  CustomerOrdersQueryVariables
>;
export const GetProductInfoDocument = gql`
  query getProductInfo($id: String!) {
    getProductInfo(id: $id) {
      name
      description
      return_policy
      image
      not_returnable
      return_address
      is_coupon
      coupon_expire_at
      coupon_cgu
      coupon_faq_before_order
      coupon_faq
      coupon_faq_after_order
    }
  }
`;

/**
 * __useGetProductInfoQuery__
 *
 * To run a query within a React component, call `useGetProductInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductInfoQuery,
    GetProductInfoQueryVariables
  > &
    (
      | { variables: GetProductInfoQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductInfoQuery, GetProductInfoQueryVariables>(
    GetProductInfoDocument,
    options
  );
}
export function useGetProductInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductInfoQuery,
    GetProductInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductInfoQuery, GetProductInfoQueryVariables>(
    GetProductInfoDocument,
    options
  );
}
export function useGetProductInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProductInfoQuery,
    GetProductInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProductInfoQuery,
    GetProductInfoQueryVariables
  >(GetProductInfoDocument, options);
}
export type GetProductInfoQueryHookResult = ReturnType<
  typeof useGetProductInfoQuery
>;
export type GetProductInfoLazyQueryHookResult = ReturnType<
  typeof useGetProductInfoLazyQuery
>;
export type GetProductInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetProductInfoSuspenseQuery
>;
export type GetProductInfoQueryResult = Apollo.QueryResult<
  GetProductInfoQuery,
  GetProductInfoQueryVariables
>;
export const GetParrainDataDocument = gql`
  query getParrainData($data: Json!) {
    getParrainData(data: $data)
  }
`;

/**
 * __useGetParrainDataQuery__
 *
 * To run a query within a React component, call `useGetParrainDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParrainDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParrainDataQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetParrainDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParrainDataQuery,
    GetParrainDataQueryVariables
  > &
    (
      | { variables: GetParrainDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetParrainDataQuery, GetParrainDataQueryVariables>(
    GetParrainDataDocument,
    options
  );
}
export function useGetParrainDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParrainDataQuery,
    GetParrainDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetParrainDataQuery, GetParrainDataQueryVariables>(
    GetParrainDataDocument,
    options
  );
}
export function useGetParrainDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetParrainDataQuery,
    GetParrainDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetParrainDataQuery,
    GetParrainDataQueryVariables
  >(GetParrainDataDocument, options);
}
export type GetParrainDataQueryHookResult = ReturnType<
  typeof useGetParrainDataQuery
>;
export type GetParrainDataLazyQueryHookResult = ReturnType<
  typeof useGetParrainDataLazyQuery
>;
export type GetParrainDataSuspenseQueryHookResult = ReturnType<
  typeof useGetParrainDataSuspenseQuery
>;
export type GetParrainDataQueryResult = Apollo.QueryResult<
  GetParrainDataQuery,
  GetParrainDataQueryVariables
>;
export const CreateInvoiceDocument = gql`
  query createInvoice($billingId: String!, $userKey: String!) {
    createInvoice(billingId: $billingId, userKey: $userKey)
  }
`;

/**
 * __useCreateInvoiceQuery__
 *
 * To run a query within a React component, call `useCreateInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateInvoiceQuery({
 *   variables: {
 *      billingId: // value for 'billingId'
 *      userKey: // value for 'userKey'
 *   },
 * });
 */
export function useCreateInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreateInvoiceQuery,
    CreateInvoiceQueryVariables
  > &
    (
      | { variables: CreateInvoiceQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateInvoiceQuery, CreateInvoiceQueryVariables>(
    CreateInvoiceDocument,
    options
  );
}
export function useCreateInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateInvoiceQuery,
    CreateInvoiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreateInvoiceQuery, CreateInvoiceQueryVariables>(
    CreateInvoiceDocument,
    options
  );
}
export function useCreateInvoiceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CreateInvoiceQuery,
    CreateInvoiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CreateInvoiceQuery,
    CreateInvoiceQueryVariables
  >(CreateInvoiceDocument, options);
}
export type CreateInvoiceQueryHookResult = ReturnType<
  typeof useCreateInvoiceQuery
>;
export type CreateInvoiceLazyQueryHookResult = ReturnType<
  typeof useCreateInvoiceLazyQuery
>;
export type CreateInvoiceSuspenseQueryHookResult = ReturnType<
  typeof useCreateInvoiceSuspenseQuery
>;
export type CreateInvoiceQueryResult = Apollo.QueryResult<
  CreateInvoiceQuery,
  CreateInvoiceQueryVariables
>;
export const GetPostPurchaseReferralPlanDocument = gql`
  query getPostPurchaseReferralPlan($data: PostPurchaseReferralInput!) {
    getPostPurchaseReferralPlan(data: $data) {
      ... on PostPurchaseReferralPlan {
        code
        friend {
          name
          credit {
            value
            currency
          }
        }
        me {
          name
          credit {
            value
            currency
          }
        }
      }
      ... on PostPurchaseReferralInputException {
        exceptionCode
      }
    }
  }
`;

/**
 * __useGetPostPurchaseReferralPlanQuery__
 *
 * To run a query within a React component, call `useGetPostPurchaseReferralPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostPurchaseReferralPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostPurchaseReferralPlanQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetPostPurchaseReferralPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPostPurchaseReferralPlanQuery,
    GetPostPurchaseReferralPlanQueryVariables
  > &
    (
      | { variables: GetPostPurchaseReferralPlanQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPostPurchaseReferralPlanQuery,
    GetPostPurchaseReferralPlanQueryVariables
  >(GetPostPurchaseReferralPlanDocument, options);
}
export function useGetPostPurchaseReferralPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPostPurchaseReferralPlanQuery,
    GetPostPurchaseReferralPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPostPurchaseReferralPlanQuery,
    GetPostPurchaseReferralPlanQueryVariables
  >(GetPostPurchaseReferralPlanDocument, options);
}
export function useGetPostPurchaseReferralPlanSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPostPurchaseReferralPlanQuery,
    GetPostPurchaseReferralPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPostPurchaseReferralPlanQuery,
    GetPostPurchaseReferralPlanQueryVariables
  >(GetPostPurchaseReferralPlanDocument, options);
}
export type GetPostPurchaseReferralPlanQueryHookResult = ReturnType<
  typeof useGetPostPurchaseReferralPlanQuery
>;
export type GetPostPurchaseReferralPlanLazyQueryHookResult = ReturnType<
  typeof useGetPostPurchaseReferralPlanLazyQuery
>;
export type GetPostPurchaseReferralPlanSuspenseQueryHookResult = ReturnType<
  typeof useGetPostPurchaseReferralPlanSuspenseQuery
>;
export type GetPostPurchaseReferralPlanQueryResult = Apollo.QueryResult<
  GetPostPurchaseReferralPlanQuery,
  GetPostPurchaseReferralPlanQueryVariables
>;
export const GetMessageGroupStatsDocument = gql`
  query getMessageGroupStats($saleId: ID!, $orderIds: [ID!]!) {
    getMessageGroupStats(saleId: $saleId, orderIds: $orderIds) {
      orderIds
      customerCount
    }
  }
`;

/**
 * __useGetMessageGroupStatsQuery__
 *
 * To run a query within a React component, call `useGetMessageGroupStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageGroupStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageGroupStatsQuery({
 *   variables: {
 *      saleId: // value for 'saleId'
 *      orderIds: // value for 'orderIds'
 *   },
 * });
 */
export function useGetMessageGroupStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMessageGroupStatsQuery,
    GetMessageGroupStatsQueryVariables
  > &
    (
      | { variables: GetMessageGroupStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMessageGroupStatsQuery,
    GetMessageGroupStatsQueryVariables
  >(GetMessageGroupStatsDocument, options);
}
export function useGetMessageGroupStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessageGroupStatsQuery,
    GetMessageGroupStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMessageGroupStatsQuery,
    GetMessageGroupStatsQueryVariables
  >(GetMessageGroupStatsDocument, options);
}
export function useGetMessageGroupStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMessageGroupStatsQuery,
    GetMessageGroupStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMessageGroupStatsQuery,
    GetMessageGroupStatsQueryVariables
  >(GetMessageGroupStatsDocument, options);
}
export type GetMessageGroupStatsQueryHookResult = ReturnType<
  typeof useGetMessageGroupStatsQuery
>;
export type GetMessageGroupStatsLazyQueryHookResult = ReturnType<
  typeof useGetMessageGroupStatsLazyQuery
>;
export type GetMessageGroupStatsSuspenseQueryHookResult = ReturnType<
  typeof useGetMessageGroupStatsSuspenseQuery
>;
export type GetMessageGroupStatsQueryResult = Apollo.QueryResult<
  GetMessageGroupStatsQuery,
  GetMessageGroupStatsQueryVariables
>;
export const GetUploadReturnSlipLabelUrlDocument = gql`
  query getUploadReturnSlipLabelUrl($orderId: ID!) {
    getUploadReturnSlipLabelUrl(orderId: $orderId) {
      key
      url
    }
  }
`;

/**
 * __useGetUploadReturnSlipLabelUrlQuery__
 *
 * To run a query within a React component, call `useGetUploadReturnSlipLabelUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadReturnSlipLabelUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadReturnSlipLabelUrlQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetUploadReturnSlipLabelUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUploadReturnSlipLabelUrlQuery,
    GetUploadReturnSlipLabelUrlQueryVariables
  > &
    (
      | { variables: GetUploadReturnSlipLabelUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUploadReturnSlipLabelUrlQuery,
    GetUploadReturnSlipLabelUrlQueryVariables
  >(GetUploadReturnSlipLabelUrlDocument, options);
}
export function useGetUploadReturnSlipLabelUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUploadReturnSlipLabelUrlQuery,
    GetUploadReturnSlipLabelUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUploadReturnSlipLabelUrlQuery,
    GetUploadReturnSlipLabelUrlQueryVariables
  >(GetUploadReturnSlipLabelUrlDocument, options);
}
export function useGetUploadReturnSlipLabelUrlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUploadReturnSlipLabelUrlQuery,
    GetUploadReturnSlipLabelUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUploadReturnSlipLabelUrlQuery,
    GetUploadReturnSlipLabelUrlQueryVariables
  >(GetUploadReturnSlipLabelUrlDocument, options);
}
export type GetUploadReturnSlipLabelUrlQueryHookResult = ReturnType<
  typeof useGetUploadReturnSlipLabelUrlQuery
>;
export type GetUploadReturnSlipLabelUrlLazyQueryHookResult = ReturnType<
  typeof useGetUploadReturnSlipLabelUrlLazyQuery
>;
export type GetUploadReturnSlipLabelUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetUploadReturnSlipLabelUrlSuspenseQuery
>;
export type GetUploadReturnSlipLabelUrlQueryResult = Apollo.QueryResult<
  GetUploadReturnSlipLabelUrlQuery,
  GetUploadReturnSlipLabelUrlQueryVariables
>;
export const JoinReturnSlipDocument = gql`
  mutation joinReturnSlip($orderId: ID!, $input: JoinReturnSlipInput!) {
    joinReturnSlip(orderId: $orderId, input: $input)
  }
`;
export type JoinReturnSlipMutationFn = Apollo.MutationFunction<
  JoinReturnSlipMutation,
  JoinReturnSlipMutationVariables
>;

/**
 * __useJoinReturnSlipMutation__
 *
 * To run a mutation, you first call `useJoinReturnSlipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinReturnSlipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinReturnSlipMutation, { data, loading, error }] = useJoinReturnSlipMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinReturnSlipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinReturnSlipMutation,
    JoinReturnSlipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinReturnSlipMutation,
    JoinReturnSlipMutationVariables
  >(JoinReturnSlipDocument, options);
}
export type JoinReturnSlipMutationHookResult = ReturnType<
  typeof useJoinReturnSlipMutation
>;
export type JoinReturnSlipMutationResult =
  Apollo.MutationResult<JoinReturnSlipMutation>;
export type JoinReturnSlipMutationOptions = Apollo.BaseMutationOptions<
  JoinReturnSlipMutation,
  JoinReturnSlipMutationVariables
>;
export const RefundOrderItemsPartiallyDocument = gql`
  mutation RefundOrderItemsPartially(
    $orderId: ID!
    $input: RefundOrderItemsPartiallyInput!
  ) {
    refundOrderItemsPartially(orderId: $orderId, input: $input) {
      cashAmountInCents
      creditAmountInCents
    }
  }
`;
export type RefundOrderItemsPartiallyMutationFn = Apollo.MutationFunction<
  RefundOrderItemsPartiallyMutation,
  RefundOrderItemsPartiallyMutationVariables
>;

/**
 * __useRefundOrderItemsPartiallyMutation__
 *
 * To run a mutation, you first call `useRefundOrderItemsPartiallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundOrderItemsPartiallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundOrderItemsPartiallyMutation, { data, loading, error }] = useRefundOrderItemsPartiallyMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefundOrderItemsPartiallyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefundOrderItemsPartiallyMutation,
    RefundOrderItemsPartiallyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefundOrderItemsPartiallyMutation,
    RefundOrderItemsPartiallyMutationVariables
  >(RefundOrderItemsPartiallyDocument, options);
}
export type RefundOrderItemsPartiallyMutationHookResult = ReturnType<
  typeof useRefundOrderItemsPartiallyMutation
>;
export type RefundOrderItemsPartiallyMutationResult =
  Apollo.MutationResult<RefundOrderItemsPartiallyMutation>;
export type RefundOrderItemsPartiallyMutationOptions =
  Apollo.BaseMutationOptions<
    RefundOrderItemsPartiallyMutation,
    RefundOrderItemsPartiallyMutationVariables
  >;
export const RefundOrderShippingPartiallyDocument = gql`
  mutation RefundOrderShippingPartially(
    $orderId: ID!
    $input: RefundOrderShippingPartiallyInput!
  ) {
    refundOrderShippingPartially(orderId: $orderId, input: $input) {
      cashAmountInCents
      creditAmountInCents
    }
  }
`;
export type RefundOrderShippingPartiallyMutationFn = Apollo.MutationFunction<
  RefundOrderShippingPartiallyMutation,
  RefundOrderShippingPartiallyMutationVariables
>;

/**
 * __useRefundOrderShippingPartiallyMutation__
 *
 * To run a mutation, you first call `useRefundOrderShippingPartiallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundOrderShippingPartiallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundOrderShippingPartiallyMutation, { data, loading, error }] = useRefundOrderShippingPartiallyMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefundOrderShippingPartiallyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefundOrderShippingPartiallyMutation,
    RefundOrderShippingPartiallyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefundOrderShippingPartiallyMutation,
    RefundOrderShippingPartiallyMutationVariables
  >(RefundOrderShippingPartiallyDocument, options);
}
export type RefundOrderShippingPartiallyMutationHookResult = ReturnType<
  typeof useRefundOrderShippingPartiallyMutation
>;
export type RefundOrderShippingPartiallyMutationResult =
  Apollo.MutationResult<RefundOrderShippingPartiallyMutation>;
export type RefundOrderShippingPartiallyMutationOptions =
  Apollo.BaseMutationOptions<
    RefundOrderShippingPartiallyMutation,
    RefundOrderShippingPartiallyMutationVariables
  >;
export const SendMessageGroupDocument = gql`
  mutation SendMessageGroup(
    $canal: MessageGroupCanal!
    $content: String!
    $reasonId: String!
    $saleId: String!
    $segmentId: SegmentType!
    $options: MessageGroupOptions
    $photos: [String!]
  ) {
    sendMessageGroup(
      canal: $canal
      content: $content
      reasonId: $reasonId
      saleId: $saleId
      segmentId: $segmentId
      options: $options
      photos: $photos
    )
  }
`;
export type SendMessageGroupMutationFn = Apollo.MutationFunction<
  SendMessageGroupMutation,
  SendMessageGroupMutationVariables
>;

/**
 * __useSendMessageGroupMutation__
 *
 * To run a mutation, you first call `useSendMessageGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageGroupMutation, { data, loading, error }] = useSendMessageGroupMutation({
 *   variables: {
 *      canal: // value for 'canal'
 *      content: // value for 'content'
 *      reasonId: // value for 'reasonId'
 *      saleId: // value for 'saleId'
 *      segmentId: // value for 'segmentId'
 *      options: // value for 'options'
 *      photos: // value for 'photos'
 *   },
 * });
 */
export function useSendMessageGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMessageGroupMutation,
    SendMessageGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendMessageGroupMutation,
    SendMessageGroupMutationVariables
  >(SendMessageGroupDocument, options);
}
export type SendMessageGroupMutationHookResult = ReturnType<
  typeof useSendMessageGroupMutation
>;
export type SendMessageGroupMutationResult =
  Apollo.MutationResult<SendMessageGroupMutation>;
export type SendMessageGroupMutationOptions = Apollo.BaseMutationOptions<
  SendMessageGroupMutation,
  SendMessageGroupMutationVariables
>;
export const PushMessageDocument = gql`
  mutation pushMessage($data: MessageInput, $type: ConversationType) {
    pushMessage(message: $data, type: $type)
  }
`;
export type PushMessageMutationFn = Apollo.MutationFunction<
  PushMessageMutation,
  PushMessageMutationVariables
>;

/**
 * __usePushMessageMutation__
 *
 * To run a mutation, you first call `usePushMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushMessageMutation, { data, loading, error }] = usePushMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *      type: // value for 'type'
 *   },
 * });
 */
export function usePushMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PushMessageMutation,
    PushMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PushMessageMutation, PushMessageMutationVariables>(
    PushMessageDocument,
    options
  );
}
export type PushMessageMutationHookResult = ReturnType<
  typeof usePushMessageMutation
>;
export type PushMessageMutationResult =
  Apollo.MutationResult<PushMessageMutation>;
export type PushMessageMutationOptions = Apollo.BaseMutationOptions<
  PushMessageMutation,
  PushMessageMutationVariables
>;
export const OperatorReplyingDocument = gql`
  mutation operatorReplying($data: OperatorReplyingUpdate!) {
    operatorReplying(data: $data)
  }
`;
export type OperatorReplyingMutationFn = Apollo.MutationFunction<
  OperatorReplyingMutation,
  OperatorReplyingMutationVariables
>;

/**
 * __useOperatorReplyingMutation__
 *
 * To run a mutation, you first call `useOperatorReplyingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOperatorReplyingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [operatorReplyingMutation, { data, loading, error }] = useOperatorReplyingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOperatorReplyingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OperatorReplyingMutation,
    OperatorReplyingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OperatorReplyingMutation,
    OperatorReplyingMutationVariables
  >(OperatorReplyingDocument, options);
}
export type OperatorReplyingMutationHookResult = ReturnType<
  typeof useOperatorReplyingMutation
>;
export type OperatorReplyingMutationResult =
  Apollo.MutationResult<OperatorReplyingMutation>;
export type OperatorReplyingMutationOptions = Apollo.BaseMutationOptions<
  OperatorReplyingMutation,
  OperatorReplyingMutationVariables
>;
export const SetConversationAsReadDocument = gql`
  mutation setConversationAsRead(
    $data: ConversationUpdate
    $type: ConversationType
  ) {
    setConversationAsRead(data: $data, type: $type)
  }
`;
export type SetConversationAsReadMutationFn = Apollo.MutationFunction<
  SetConversationAsReadMutation,
  SetConversationAsReadMutationVariables
>;

/**
 * __useSetConversationAsReadMutation__
 *
 * To run a mutation, you first call `useSetConversationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConversationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConversationAsReadMutation, { data, loading, error }] = useSetConversationAsReadMutation({
 *   variables: {
 *      data: // value for 'data'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSetConversationAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetConversationAsReadMutation,
    SetConversationAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetConversationAsReadMutation,
    SetConversationAsReadMutationVariables
  >(SetConversationAsReadDocument, options);
}
export type SetConversationAsReadMutationHookResult = ReturnType<
  typeof useSetConversationAsReadMutation
>;
export type SetConversationAsReadMutationResult =
  Apollo.MutationResult<SetConversationAsReadMutation>;
export type SetConversationAsReadMutationOptions = Apollo.BaseMutationOptions<
  SetConversationAsReadMutation,
  SetConversationAsReadMutationVariables
>;
export const AssignConversationDocument = gql`
  mutation assignConversation($conversationId: String!, $operatorId: String!) {
    assignConversation(conversationId: $conversationId, operatorId: $operatorId)
  }
`;
export type AssignConversationMutationFn = Apollo.MutationFunction<
  AssignConversationMutation,
  AssignConversationMutationVariables
>;

/**
 * __useAssignConversationMutation__
 *
 * To run a mutation, you first call `useAssignConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignConversationMutation, { data, loading, error }] = useAssignConversationMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      operatorId: // value for 'operatorId'
 *   },
 * });
 */
export function useAssignConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignConversationMutation,
    AssignConversationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignConversationMutation,
    AssignConversationMutationVariables
  >(AssignConversationDocument, options);
}
export type AssignConversationMutationHookResult = ReturnType<
  typeof useAssignConversationMutation
>;
export type AssignConversationMutationResult =
  Apollo.MutationResult<AssignConversationMutation>;
export type AssignConversationMutationOptions = Apollo.BaseMutationOptions<
  AssignConversationMutation,
  AssignConversationMutationVariables
>;
export const SetConversationCategoryDocument = gql`
  mutation setConversationCategory(
    $conversationId: String!
    $category: ConversationCategory
  ) {
    setConversationCategory(
      conversationId: $conversationId
      category: $category
    )
  }
`;
export type SetConversationCategoryMutationFn = Apollo.MutationFunction<
  SetConversationCategoryMutation,
  SetConversationCategoryMutationVariables
>;

/**
 * __useSetConversationCategoryMutation__
 *
 * To run a mutation, you first call `useSetConversationCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConversationCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConversationCategoryMutation, { data, loading, error }] = useSetConversationCategoryMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useSetConversationCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetConversationCategoryMutation,
    SetConversationCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetConversationCategoryMutation,
    SetConversationCategoryMutationVariables
  >(SetConversationCategoryDocument, options);
}
export type SetConversationCategoryMutationHookResult = ReturnType<
  typeof useSetConversationCategoryMutation
>;
export type SetConversationCategoryMutationResult =
  Apollo.MutationResult<SetConversationCategoryMutation>;
export type SetConversationCategoryMutationOptions = Apollo.BaseMutationOptions<
  SetConversationCategoryMutation,
  SetConversationCategoryMutationVariables
>;
export const SetUserNoteDocument = gql`
  mutation setUserNote($userId: String!, $message: String!) {
    setUserNote(userId: $userId, message: $message)
  }
`;
export type SetUserNoteMutationFn = Apollo.MutationFunction<
  SetUserNoteMutation,
  SetUserNoteMutationVariables
>;

/**
 * __useSetUserNoteMutation__
 *
 * To run a mutation, you first call `useSetUserNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserNoteMutation, { data, loading, error }] = useSetUserNoteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSetUserNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserNoteMutation,
    SetUserNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetUserNoteMutation, SetUserNoteMutationVariables>(
    SetUserNoteDocument,
    options
  );
}
export type SetUserNoteMutationHookResult = ReturnType<
  typeof useSetUserNoteMutation
>;
export type SetUserNoteMutationResult =
  Apollo.MutationResult<SetUserNoteMutation>;
export type SetUserNoteMutationOptions = Apollo.BaseMutationOptions<
  SetUserNoteMutation,
  SetUserNoteMutationVariables
>;
export const CreateCreditDocument = gql`
  mutation createCredit($data: CreditUpdate!) {
    createCredit(data: $data)
  }
`;
export type CreateCreditMutationFn = Apollo.MutationFunction<
  CreateCreditMutation,
  CreateCreditMutationVariables
>;

/**
 * __useCreateCreditMutation__
 *
 * To run a mutation, you first call `useCreateCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditMutation, { data, loading, error }] = useCreateCreditMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCreditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCreditMutation,
    CreateCreditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCreditMutation,
    CreateCreditMutationVariables
  >(CreateCreditDocument, options);
}
export type CreateCreditMutationHookResult = ReturnType<
  typeof useCreateCreditMutation
>;
export type CreateCreditMutationResult =
  Apollo.MutationResult<CreateCreditMutation>;
export type CreateCreditMutationOptions = Apollo.BaseMutationOptions<
  CreateCreditMutation,
  CreateCreditMutationVariables
>;
export const CreatePostPurchaseReferralCreditDocument = gql`
  mutation createPostPurchaseReferralCredit($data: PostPurchaseReferralInput!) {
    createPostPurchaseReferralCredit(data: $data)
  }
`;
export type CreatePostPurchaseReferralCreditMutationFn =
  Apollo.MutationFunction<
    CreatePostPurchaseReferralCreditMutation,
    CreatePostPurchaseReferralCreditMutationVariables
  >;

/**
 * __useCreatePostPurchaseReferralCreditMutation__
 *
 * To run a mutation, you first call `useCreatePostPurchaseReferralCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostPurchaseReferralCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostPurchaseReferralCreditMutation, { data, loading, error }] = useCreatePostPurchaseReferralCreditMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePostPurchaseReferralCreditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePostPurchaseReferralCreditMutation,
    CreatePostPurchaseReferralCreditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePostPurchaseReferralCreditMutation,
    CreatePostPurchaseReferralCreditMutationVariables
  >(CreatePostPurchaseReferralCreditDocument, options);
}
export type CreatePostPurchaseReferralCreditMutationHookResult = ReturnType<
  typeof useCreatePostPurchaseReferralCreditMutation
>;
export type CreatePostPurchaseReferralCreditMutationResult =
  Apollo.MutationResult<CreatePostPurchaseReferralCreditMutation>;
export type CreatePostPurchaseReferralCreditMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePostPurchaseReferralCreditMutation,
    CreatePostPurchaseReferralCreditMutationVariables
  >;
export const ChangeReferralDocument = gql`
  mutation changeReferral($userKey: String!, $referral: String!) {
    changeReferral(userKey: $userKey, referral: $referral)
  }
`;
export type ChangeReferralMutationFn = Apollo.MutationFunction<
  ChangeReferralMutation,
  ChangeReferralMutationVariables
>;

/**
 * __useChangeReferralMutation__
 *
 * To run a mutation, you first call `useChangeReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeReferralMutation, { data, loading, error }] = useChangeReferralMutation({
 *   variables: {
 *      userKey: // value for 'userKey'
 *      referral: // value for 'referral'
 *   },
 * });
 */
export function useChangeReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeReferralMutation,
    ChangeReferralMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeReferralMutation,
    ChangeReferralMutationVariables
  >(ChangeReferralDocument, options);
}
export type ChangeReferralMutationHookResult = ReturnType<
  typeof useChangeReferralMutation
>;
export type ChangeReferralMutationResult =
  Apollo.MutationResult<ChangeReferralMutation>;
export type ChangeReferralMutationOptions = Apollo.BaseMutationOptions<
  ChangeReferralMutation,
  ChangeReferralMutationVariables
>;
export const CreateTemplateCategoryDocument = gql`
  mutation createTemplateCategory($name: String!) {
    createTemplateCategory(name: $name)
  }
`;
export type CreateTemplateCategoryMutationFn = Apollo.MutationFunction<
  CreateTemplateCategoryMutation,
  CreateTemplateCategoryMutationVariables
>;

/**
 * __useCreateTemplateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateTemplateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateCategoryMutation, { data, loading, error }] = useCreateTemplateCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateTemplateCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTemplateCategoryMutation,
    CreateTemplateCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTemplateCategoryMutation,
    CreateTemplateCategoryMutationVariables
  >(CreateTemplateCategoryDocument, options);
}
export type CreateTemplateCategoryMutationHookResult = ReturnType<
  typeof useCreateTemplateCategoryMutation
>;
export type CreateTemplateCategoryMutationResult =
  Apollo.MutationResult<CreateTemplateCategoryMutation>;
export type CreateTemplateCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateTemplateCategoryMutation,
  CreateTemplateCategoryMutationVariables
>;
export const UpdateTemplateMessageDocument = gql`
  mutation updateTemplateMessage($data: TemplateInput!) {
    updateTemplateMessage(data: $data)
  }
`;
export type UpdateTemplateMessageMutationFn = Apollo.MutationFunction<
  UpdateTemplateMessageMutation,
  UpdateTemplateMessageMutationVariables
>;

/**
 * __useUpdateTemplateMessageMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMessageMutation, { data, loading, error }] = useUpdateTemplateMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTemplateMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTemplateMessageMutation,
    UpdateTemplateMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTemplateMessageMutation,
    UpdateTemplateMessageMutationVariables
  >(UpdateTemplateMessageDocument, options);
}
export type UpdateTemplateMessageMutationHookResult = ReturnType<
  typeof useUpdateTemplateMessageMutation
>;
export type UpdateTemplateMessageMutationResult =
  Apollo.MutationResult<UpdateTemplateMessageMutation>;
export type UpdateTemplateMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateTemplateMessageMutation,
  UpdateTemplateMessageMutationVariables
>;
export const OpenClaimDocument = gql`
  mutation openClaim($orderId: ID!, $input: OpenClaimInput!) {
    openClaim(orderId: $orderId, input: $input)
  }
`;
export type OpenClaimMutationFn = Apollo.MutationFunction<
  OpenClaimMutation,
  OpenClaimMutationVariables
>;

/**
 * __useOpenClaimMutation__
 *
 * To run a mutation, you first call `useOpenClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openClaimMutation, { data, loading, error }] = useOpenClaimMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OpenClaimMutation,
    OpenClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OpenClaimMutation, OpenClaimMutationVariables>(
    OpenClaimDocument,
    options
  );
}
export type OpenClaimMutationHookResult = ReturnType<
  typeof useOpenClaimMutation
>;
export type OpenClaimMutationResult = Apollo.MutationResult<OpenClaimMutation>;
export type OpenClaimMutationOptions = Apollo.BaseMutationOptions<
  OpenClaimMutation,
  OpenClaimMutationVariables
>;
export const CancelOrderDocument = gql`
  mutation cancelOrder($orderId: ID!, $input: CancelOrderInput!) {
    cancelOrder(orderId: $orderId, input: $input)
  }
`;
export type CancelOrderMutationFn = Apollo.MutationFunction<
  CancelOrderMutation,
  CancelOrderMutationVariables
>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelOrderMutation,
    CancelOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(
    CancelOrderDocument,
    options
  );
}
export type CancelOrderMutationHookResult = ReturnType<
  typeof useCancelOrderMutation
>;
export type CancelOrderMutationResult =
  Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelOrderMutation,
  CancelOrderMutationVariables
>;
export const CancelClaimDocument = gql`
  mutation cancelClaim($orderId: ID!, $claimId: ID!) {
    cancelClaim(orderId: $orderId, claimId: $claimId)
  }
`;
export type CancelClaimMutationFn = Apollo.MutationFunction<
  CancelClaimMutation,
  CancelClaimMutationVariables
>;

/**
 * __useCancelClaimMutation__
 *
 * To run a mutation, you first call `useCancelClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelClaimMutation, { data, loading, error }] = useCancelClaimMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      claimId: // value for 'claimId'
 *   },
 * });
 */
export function useCancelClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelClaimMutation,
    CancelClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelClaimMutation, CancelClaimMutationVariables>(
    CancelClaimDocument,
    options
  );
}
export type CancelClaimMutationHookResult = ReturnType<
  typeof useCancelClaimMutation
>;
export type CancelClaimMutationResult =
  Apollo.MutationResult<CancelClaimMutation>;
export type CancelClaimMutationOptions = Apollo.BaseMutationOptions<
  CancelClaimMutation,
  CancelClaimMutationVariables
>;
export const CreateReturnDocument = gql`
  mutation createReturn(
    $userKey: String!
    $orderId: String!
    $deliveryToChoose: Boolean
    $products: [Json]
  ) {
    createReturn(
      userKey: $userKey
      orderId: $orderId
      products: $products
      deliveryToChoose: $deliveryToChoose
    )
  }
`;
export type CreateReturnMutationFn = Apollo.MutationFunction<
  CreateReturnMutation,
  CreateReturnMutationVariables
>;

/**
 * __useCreateReturnMutation__
 *
 * To run a mutation, you first call `useCreateReturnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReturnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReturnMutation, { data, loading, error }] = useCreateReturnMutation({
 *   variables: {
 *      userKey: // value for 'userKey'
 *      orderId: // value for 'orderId'
 *      deliveryToChoose: // value for 'deliveryToChoose'
 *      products: // value for 'products'
 *   },
 * });
 */
export function useCreateReturnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReturnMutation,
    CreateReturnMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateReturnMutation,
    CreateReturnMutationVariables
  >(CreateReturnDocument, options);
}
export type CreateReturnMutationHookResult = ReturnType<
  typeof useCreateReturnMutation
>;
export type CreateReturnMutationResult =
  Apollo.MutationResult<CreateReturnMutation>;
export type CreateReturnMutationOptions = Apollo.BaseMutationOptions<
  CreateReturnMutation,
  CreateReturnMutationVariables
>;
export const UpdateBillingOrdersBillingAddressDocument = gql`
  mutation updateBillingOrdersBillingAddress(
    $billingId: ID!
    $input: BillingAddressInput!
  ) {
    updateBillingOrdersBillingAddress(billingId: $billingId, input: $input)
  }
`;
export type UpdateBillingOrdersBillingAddressMutationFn =
  Apollo.MutationFunction<
    UpdateBillingOrdersBillingAddressMutation,
    UpdateBillingOrdersBillingAddressMutationVariables
  >;

/**
 * __useUpdateBillingOrdersBillingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateBillingOrdersBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingOrdersBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingOrdersBillingAddressMutation, { data, loading, error }] = useUpdateBillingOrdersBillingAddressMutation({
 *   variables: {
 *      billingId: // value for 'billingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillingOrdersBillingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBillingOrdersBillingAddressMutation,
    UpdateBillingOrdersBillingAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBillingOrdersBillingAddressMutation,
    UpdateBillingOrdersBillingAddressMutationVariables
  >(UpdateBillingOrdersBillingAddressDocument, options);
}
export type UpdateBillingOrdersBillingAddressMutationHookResult = ReturnType<
  typeof useUpdateBillingOrdersBillingAddressMutation
>;
export type UpdateBillingOrdersBillingAddressMutationResult =
  Apollo.MutationResult<UpdateBillingOrdersBillingAddressMutation>;
export type UpdateBillingOrdersBillingAddressMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateBillingOrdersBillingAddressMutation,
    UpdateBillingOrdersBillingAddressMutationVariables
  >;
export const UpdateBillingOrdersShippingAddressDocument = gql`
  mutation updateBillingOrdersShippingAddress(
    $billingId: ID!
    $input: ShippingAddressInput!
  ) {
    updateBillingOrdersShippingAddress(billingId: $billingId, input: $input)
  }
`;
export type UpdateBillingOrdersShippingAddressMutationFn =
  Apollo.MutationFunction<
    UpdateBillingOrdersShippingAddressMutation,
    UpdateBillingOrdersShippingAddressMutationVariables
  >;

/**
 * __useUpdateBillingOrdersShippingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateBillingOrdersShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingOrdersShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingOrdersShippingAddressMutation, { data, loading, error }] = useUpdateBillingOrdersShippingAddressMutation({
 *   variables: {
 *      billingId: // value for 'billingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillingOrdersShippingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBillingOrdersShippingAddressMutation,
    UpdateBillingOrdersShippingAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBillingOrdersShippingAddressMutation,
    UpdateBillingOrdersShippingAddressMutationVariables
  >(UpdateBillingOrdersShippingAddressDocument, options);
}
export type UpdateBillingOrdersShippingAddressMutationHookResult = ReturnType<
  typeof useUpdateBillingOrdersShippingAddressMutation
>;
export type UpdateBillingOrdersShippingAddressMutationResult =
  Apollo.MutationResult<UpdateBillingOrdersShippingAddressMutation>;
export type UpdateBillingOrdersShippingAddressMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateBillingOrdersShippingAddressMutation,
    UpdateBillingOrdersShippingAddressMutationVariables
  >;
export const UpdateOrderShippingAddressDocument = gql`
  mutation updateOrderShippingAddress(
    $orderId: ID!
    $input: ShippingAddressInput!
  ) {
    updateOrderShippingAddress(orderId: $orderId, input: $input)
  }
`;
export type UpdateOrderShippingAddressMutationFn = Apollo.MutationFunction<
  UpdateOrderShippingAddressMutation,
  UpdateOrderShippingAddressMutationVariables
>;

/**
 * __useUpdateOrderShippingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateOrderShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderShippingAddressMutation, { data, loading, error }] = useUpdateOrderShippingAddressMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderShippingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderShippingAddressMutation,
    UpdateOrderShippingAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrderShippingAddressMutation,
    UpdateOrderShippingAddressMutationVariables
  >(UpdateOrderShippingAddressDocument, options);
}
export type UpdateOrderShippingAddressMutationHookResult = ReturnType<
  typeof useUpdateOrderShippingAddressMutation
>;
export type UpdateOrderShippingAddressMutationResult =
  Apollo.MutationResult<UpdateOrderShippingAddressMutation>;
export type UpdateOrderShippingAddressMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrderShippingAddressMutation,
    UpdateOrderShippingAddressMutationVariables
  >;
export const ValidateOrderItemsReturnedDocument = gql`
  mutation validateOrderItemsReturned($orderId: ID!) {
    validateOrderItemsReturned(orderId: $orderId)
  }
`;
export type ValidateOrderItemsReturnedMutationFn = Apollo.MutationFunction<
  ValidateOrderItemsReturnedMutation,
  ValidateOrderItemsReturnedMutationVariables
>;

/**
 * __useValidateOrderItemsReturnedMutation__
 *
 * To run a mutation, you first call `useValidateOrderItemsReturnedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateOrderItemsReturnedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateOrderItemsReturnedMutation, { data, loading, error }] = useValidateOrderItemsReturnedMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useValidateOrderItemsReturnedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateOrderItemsReturnedMutation,
    ValidateOrderItemsReturnedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateOrderItemsReturnedMutation,
    ValidateOrderItemsReturnedMutationVariables
  >(ValidateOrderItemsReturnedDocument, options);
}
export type ValidateOrderItemsReturnedMutationHookResult = ReturnType<
  typeof useValidateOrderItemsReturnedMutation
>;
export type ValidateOrderItemsReturnedMutationResult =
  Apollo.MutationResult<ValidateOrderItemsReturnedMutation>;
export type ValidateOrderItemsReturnedMutationOptions =
  Apollo.BaseMutationOptions<
    ValidateOrderItemsReturnedMutation,
    ValidateOrderItemsReturnedMutationVariables
  >;
export const CancelReturnDocument = gql`
  mutation cancelReturn($orderId: ID!) {
    cancelReturn(orderId: $orderId)
  }
`;
export type CancelReturnMutationFn = Apollo.MutationFunction<
  CancelReturnMutation,
  CancelReturnMutationVariables
>;

/**
 * __useCancelReturnMutation__
 *
 * To run a mutation, you first call `useCancelReturnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReturnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReturnMutation, { data, loading, error }] = useCancelReturnMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelReturnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelReturnMutation,
    CancelReturnMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelReturnMutation,
    CancelReturnMutationVariables
  >(CancelReturnDocument, options);
}
export type CancelReturnMutationHookResult = ReturnType<
  typeof useCancelReturnMutation
>;
export type CancelReturnMutationResult =
  Apollo.MutationResult<CancelReturnMutation>;
export type CancelReturnMutationOptions = Apollo.BaseMutationOptions<
  CancelReturnMutation,
  CancelReturnMutationVariables
>;
export const SplitOrderDocument = gql`
  mutation splitOrder($orderId: ID!, $newOrdersItemIds: [[ID!]!]!) {
    splitOrder(orderId: $orderId, newOrdersItemIds: $newOrdersItemIds)
  }
`;
export type SplitOrderMutationFn = Apollo.MutationFunction<
  SplitOrderMutation,
  SplitOrderMutationVariables
>;

/**
 * __useSplitOrderMutation__
 *
 * To run a mutation, you first call `useSplitOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSplitOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [splitOrderMutation, { data, loading, error }] = useSplitOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      newOrdersItemIds: // value for 'newOrdersItemIds'
 *   },
 * });
 */
export function useSplitOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SplitOrderMutation,
    SplitOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SplitOrderMutation, SplitOrderMutationVariables>(
    SplitOrderDocument,
    options
  );
}
export type SplitOrderMutationHookResult = ReturnType<
  typeof useSplitOrderMutation
>;
export type SplitOrderMutationResult =
  Apollo.MutationResult<SplitOrderMutation>;
export type SplitOrderMutationOptions = Apollo.BaseMutationOptions<
  SplitOrderMutation,
  SplitOrderMutationVariables
>;
export const CancelOrderItemsDocument = gql`
  mutation cancelOrderItems(
    $orderId: ID!
    $itemIds: [ID!]!
    $input: CancelOrderItemsInput!
  ) {
    cancelOrderItems(orderId: $orderId, itemIds: $itemIds, input: $input)
  }
`;
export type CancelOrderItemsMutationFn = Apollo.MutationFunction<
  CancelOrderItemsMutation,
  CancelOrderItemsMutationVariables
>;

/**
 * __useCancelOrderItemsMutation__
 *
 * To run a mutation, you first call `useCancelOrderItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderItemsMutation, { data, loading, error }] = useCancelOrderItemsMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      itemIds: // value for 'itemIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelOrderItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelOrderItemsMutation,
    CancelOrderItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelOrderItemsMutation,
    CancelOrderItemsMutationVariables
  >(CancelOrderItemsDocument, options);
}
export type CancelOrderItemsMutationHookResult = ReturnType<
  typeof useCancelOrderItemsMutation
>;
export type CancelOrderItemsMutationResult =
  Apollo.MutationResult<CancelOrderItemsMutation>;
export type CancelOrderItemsMutationOptions = Apollo.BaseMutationOptions<
  CancelOrderItemsMutation,
  CancelOrderItemsMutationVariables
>;
export const MarkOrderAsDeliveredDocument = gql`
  mutation markOrderAsDelivered($orderId: ID!) {
    markOrderAsDelivered(orderId: $orderId)
  }
`;
export type MarkOrderAsDeliveredMutationFn = Apollo.MutationFunction<
  MarkOrderAsDeliveredMutation,
  MarkOrderAsDeliveredMutationVariables
>;

/**
 * __useMarkOrderAsDeliveredMutation__
 *
 * To run a mutation, you first call `useMarkOrderAsDeliveredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOrderAsDeliveredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOrderAsDeliveredMutation, { data, loading, error }] = useMarkOrderAsDeliveredMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useMarkOrderAsDeliveredMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkOrderAsDeliveredMutation,
    MarkOrderAsDeliveredMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkOrderAsDeliveredMutation,
    MarkOrderAsDeliveredMutationVariables
  >(MarkOrderAsDeliveredDocument, options);
}
export type MarkOrderAsDeliveredMutationHookResult = ReturnType<
  typeof useMarkOrderAsDeliveredMutation
>;
export type MarkOrderAsDeliveredMutationResult =
  Apollo.MutationResult<MarkOrderAsDeliveredMutation>;
export type MarkOrderAsDeliveredMutationOptions = Apollo.BaseMutationOptions<
  MarkOrderAsDeliveredMutation,
  MarkOrderAsDeliveredMutationVariables
>;
export const RefreshStatsDocument = gql`
  subscription refreshStats {
    refreshStats {
      unhandled
      pinned
      unassigned
      usa
      categoryBotProcessing
      categoryDelivery
      categoryOrder
      categoryPostOperation
      categoryPreSales
      assignOperatorRepartition {
        operatorId
        nbConversationAssigned
      }
    }
  }
`;

/**
 * __useRefreshStatsSubscription__
 *
 * To run a query within a React component, call `useRefreshStatsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRefreshStatsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshStatsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRefreshStatsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    RefreshStatsSubscription,
    RefreshStatsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    RefreshStatsSubscription,
    RefreshStatsSubscriptionVariables
  >(RefreshStatsDocument, options);
}
export type RefreshStatsSubscriptionHookResult = ReturnType<
  typeof useRefreshStatsSubscription
>;
export type RefreshStatsSubscriptionResult =
  Apollo.SubscriptionResult<RefreshStatsSubscription>;
export const NewMessageDocument = gql`
  subscription newMessage {
    newMessage {
      userKey
      body
      chooseReply
      createdAt
      name
      total_orders
      isAssign
      category
      assignToOperatorId
      userStore
    }
  }
`;

/**
 * __useNewMessageSubscription__
 *
 * To run a query within a React component, call `useNewMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMessageSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewMessageSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewMessageSubscription,
    NewMessageSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewMessageSubscription,
    NewMessageSubscriptionVariables
  >(NewMessageDocument, options);
}
export type NewMessageSubscriptionHookResult = ReturnType<
  typeof useNewMessageSubscription
>;
export type NewMessageSubscriptionResult =
  Apollo.SubscriptionResult<NewMessageSubscription>;
export const MessageChangedDocument = gql`
  subscription messageChanged {
    messageChanged {
      userKey
      isFixed
      isPinned
      isAssign
      assignToOperatorId
      userStore
      category
    }
  }
`;

/**
 * __useMessageChangedSubscription__
 *
 * To run a query within a React component, call `useMessageChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMessageChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    MessageChangedSubscription,
    MessageChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MessageChangedSubscription,
    MessageChangedSubscriptionVariables
  >(MessageChangedDocument, options);
}
export type MessageChangedSubscriptionHookResult = ReturnType<
  typeof useMessageChangedSubscription
>;
export type MessageChangedSubscriptionResult =
  Apollo.SubscriptionResult<MessageChangedSubscription>;
export const NewOperatorReplyingDocument = gql`
  subscription newOperatorReplying {
    newOperatorReplying {
      operatorId
      userKey
      operator
      message
    }
  }
`;

/**
 * __useNewOperatorReplyingSubscription__
 *
 * To run a query within a React component, call `useNewOperatorReplyingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewOperatorReplyingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewOperatorReplyingSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewOperatorReplyingSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewOperatorReplyingSubscription,
    NewOperatorReplyingSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewOperatorReplyingSubscription,
    NewOperatorReplyingSubscriptionVariables
  >(NewOperatorReplyingDocument, options);
}
export type NewOperatorReplyingSubscriptionHookResult = ReturnType<
  typeof useNewOperatorReplyingSubscription
>;
export type NewOperatorReplyingSubscriptionResult =
  Apollo.SubscriptionResult<NewOperatorReplyingSubscription>;
export const OrderUpdatedDocument = gql`
  subscription orderUpdated($customerId: ID!) {
    orderUpdated(customerId: $customerId) {
      id
    }
  }
`;

/**
 * __useOrderUpdatedSubscription__
 *
 * To run a query within a React component, call `useOrderUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderUpdatedSubscription({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useOrderUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OrderUpdatedSubscription,
    OrderUpdatedSubscriptionVariables
  > &
    (
      | { variables: OrderUpdatedSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OrderUpdatedSubscription,
    OrderUpdatedSubscriptionVariables
  >(OrderUpdatedDocument, options);
}
export type OrderUpdatedSubscriptionHookResult = ReturnType<
  typeof useOrderUpdatedSubscription
>;
export type OrderUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OrderUpdatedSubscription>;
